import { Injectable } from '@angular/core';
import { WikiCardsService } from './wiki-cards.service';
import { Wiki } from '@local/client-contracts';
import moment from 'moment';
import { AvatarListService } from '../avatar-list.service';
import { isIcon } from '@shared/utils';
import { observable } from '@local/common';
import { Observable } from 'rxjs';

@Injectable()
export class WikiCardVersionsService {
  constructor(
    private wikiCardsService: WikiCardsService,
    private avatarListService: AvatarListService
  ) {}

  @observable
  getVersionsByCardId$(cardId: string): Observable<Wiki.CardArchive[]> {
    return this.wikiCardsService.listArchiveByCardId$({ id: cardId });
  }

  async setVersionOrderCard(cardVersion: Wiki.CardArchive): Promise<{ modifiedBy: string; updatedTime: string; icon: any }> {
    const avatar = await this.avatarListService.getOwnerAvatar(cardVersion.modifiedBy);
    return {
      modifiedBy: avatar.name,
      updatedTime: this.formatTime(cardVersion.modifiedTime),
      icon: { lightUrl: isIcon(avatar.imgUrl) ? avatar.imgUrl.lightUrl : avatar.imgUrl, rounded: true },
    };
  }

  formatTime(updatedTime: number): string {
    const date = new Date(updatedTime);
    const isToday = date.toDateString() === new Date().toDateString();
    return isToday ? `Today, ${moment(date).format('HH:mm')}` : moment(date).format('MMMM DD, YYYY, HH:mm');
  }
}
