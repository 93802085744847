import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { Verifications, Wiki } from '@local/client-contracts';
import { PopupRef } from '@local/ui-infra';
import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { WikiCardVersionsService } from 'src/app/bar/services/wikis/wiki-card-versions.service';
import { WikiPopupsService } from '../../../services/wiki-popups.service';
import { take } from 'rxjs';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import { FrameEditorRenderHtmlService } from 'src/app/bar/views/frame-editor/services/frame-editor-render-html.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FrameEditorDiffHtmlService } from 'src/app/bar/views/frame-editor/services/frame-editor-diff-html.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { WikiCardSaveFlowService } from '../../../services/wiki-card-save-flow.service';
import { windowSizeObserver } from '@shared/utils';

export interface WikiCardVersionsModel {
  card: Wiki.Card;
  verification: Verifications.Verification;
}

export interface CardVersionViewModel {
  version: Wiki.CardArchive;
  versionNumber: number;
  updatedTime: string;
  versionChangeLog?: string;
  content: string;
  diffContent: string;
  isCurrent: boolean;
  isLatestVersion: boolean;
  isFirstVersion: boolean;
}
@UntilDestroy()
@Component({
  selector: 'wiki-card-versions-popup',
  templateUrl: './wiki-card-versions-popup.component.html',
  styleUrls: ['./wiki-card-versions-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WikiCardVersionsPopupComponent {
  private readonly MEDIUM_SCREEN = 1090;
  private readonly SMALL_SCREEN = 700;
  private logger: Logger;
  private dicHtmlVersions = new Map<string, string>();
  private card: Wiki.Card;

  selectedIndex = signal<number>(0);
  smallWidth = signal<boolean>(false);
  verification: Verifications.Verification;
  loading = signal<boolean>(true);
  versions = signal<Wiki.CardArchive[]>([]);
  hideVersionList = signal<boolean>(false);
  displayPrevVersion = signal<boolean>(false);

  currentVersion = signal<CardVersionViewModel>(null);
  prevVersion = signal<CardVersionViewModel>(null);

  constructor(
    private ref: PopupRef<WikiCardVersionsPopupComponent, WikiCardVersionsModel>,
    private cardVersionsService: WikiCardVersionsService,
    private wikiPopupsService: WikiPopupsService,
    private showToasterService: ShowToasterService,
    private logService: LogService,
    private frameEditorRenderHtmlService: FrameEditorRenderHtmlService,
    private frameEditorDiffHtmlService: FrameEditorDiffHtmlService,
    private wikiCardsService: WikiCardsService,
    private wikiCardSaveFlowService: WikiCardSaveFlowService
  ) {
    this.logger = this.logService.scope('WikiCardVersionsPopupComponent');
  }

  ngOnInit(): void {
    const { verification, card } = this.ref.data;
    this.verification = verification;
    this.card = card;
    this.initVersions();
    this.getWindowSize();
  }

  private getWindowSize() {
    windowSizeObserver()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.handleScreenSizeChange(value.width);
      });
  }

  private handleScreenSizeChange(width: number) {
    const flag = (width < this.MEDIUM_SCREEN && this.displayPrevVersion()) || width < this.SMALL_SCREEN;
    this.smallWidth.set(flag);
  }

  private async initVersions() {
    try {
      if (!this.card.content) {
        this.card = await this.wikiCardsService.getCard(this.card.id, false);
      }
      this.cardVersionsService
        .getVersionsByCardId$(this.card.id)
        .pipe(untilDestroyed(this))
        .subscribe((versions) => {
          this.setVersions(versions || []);
        });
    } catch (error) {
      this.logger.error('Failed to get versions', error);
      this.setVersions();
      this.loading.set(false);
    }
  }

  private async setVersions(versions: Wiki.CardArchive[] = []) {
    const initialVersions = [...(versions || [])];
    if (!this.versions()?.length) {
      initialVersions.unshift({ ...this.card, id: this.card.id });
      this.currentVersion.set(await this.buildVersionViewModel(initialVersions[0], 0, null, null, true, !versions.length));
      this.loading.set(false);
    }
    this.versions.set([...this.versions(), ...initialVersions]);
  }

  private buildHtmlView(title: string, html: string): string {
    return `<h1>${title}</h1>${html}`;
  }

  private async renderHtmlToEditor(versionId: string, html: string): Promise<string> {
    if (!this.dicHtmlVersions.has(versionId)) {
      const renderedHtml = await this.frameEditorRenderHtmlService.renderHtmlToEditor(html);
      this.dicHtmlVersions.set(versionId, renderedHtml);
    }
    return this.dicHtmlVersions.get(versionId);
  }

  closePopup() {
    this.ref.destroy();
  }

  private getVersionId(versionNumber: number, added = false, current = false): string {
    const tag = current ? 'current' : added ? 'diff_added' : 'diff_removed';
    return `${versionNumber}_${tag}`;
  }

  private async buildVersionViewModel(
    version: Wiki.CardArchive,
    index: number,
    diffContent?: string,
    added = false,
    isCurrent = false,
    isFirstVersion = false
  ): Promise<CardVersionViewModel> {
    const content = await this.renderHtmlToEditor(
      this.getVersionId(version.version, false, true),
      this.buildHtmlView(version.title, version.content)
    );
    const diff = diffContent ? await this.renderHtmlToEditor(this.getVersionId(version.version, added), diffContent) : null;

    return {
      version,
      versionNumber: version.version,
      updatedTime: this.cardVersionsService.formatTime(version.modifiedTime),
      versionChangeLog: version.versionChangeLog,
      content,
      diffContent: diff,
      isCurrent,
      isFirstVersion: isFirstVersion || index === this.versions().length - 1,
      isLatestVersion: index === 0,
    };
  }

  async selectedVersion(index: number, forceSelected = false): Promise<void> {
    if (index === this.selectedIndex() && !forceSelected) {
      return;
    }
    this.selectedIndex.set(index);

    if (index === this.versions().length - 1) {
      const version = this.versions()[index];
      const diffContent = `<div class="u-revisionhistory-added">${this.buildHtmlView(version.title, version.content)}</div>`;
      const firstVersion = await this.buildVersionViewModel(version, index, diffContent, true, true);
      this.prevVersion.set(null);
      this.currentVersion.set(firstVersion);
      return;
    }

    const prevIndex = index + 1;
    const prevVersion = this.versions()[prevIndex];
    const prevVersionId = this.getVersionId(prevVersion.version);
    const currentVersion = this.versions()[index];
    const currentVersionId = this.getVersionId(currentVersion.version, true);

    let diffPrevHtml = this.dicHtmlVersions.get(prevVersionId);
    let diffCurrentHtml = this.dicHtmlVersions.get(currentVersionId);

    if (!diffPrevHtml || !diffCurrentHtml) {
      const { diffOldHtml, diffNewHtml } = this.frameEditorDiffHtmlService.renderDiff(
        this.buildHtmlView(prevVersion.title, prevVersion.content),
        this.buildHtmlView(currentVersion.title, currentVersion.content)
      );
      diffPrevHtml = diffOldHtml;
      diffCurrentHtml = diffNewHtml;
    }

    this.prevVersion.set(await this.buildVersionViewModel(prevVersion, prevIndex, diffPrevHtml, false, false));
    this.currentVersion.set(await this.buildVersionViewModel(currentVersion, index, diffCurrentHtml, true, true));
  }

  onToggleButtonChange(event) {
    if (event.checked && !this.currentVersion().diffContent) {
      //For the first time, that still didn't init history version
      this.selectedVersion(0, true);
    }
    this.displayPrevVersion.set(event.checked);
    this.handleScreenSizeChange(window.innerWidth);
  }

  restoreVersion() {
    if (this.currentVersion().isLatestVersion) {
      return;
    }
    const versionNumber = this.currentVersion().versionNumber;
    const isVerifier = this.verification?.policy?.isVerifier;
    const restoreVersionText = `${versionNumber === 0 ? 'Original revision' : `Revision #${versionNumber}`}`;

    const restorePopup = this.wikiPopupsService.openRestoreVersionPopup(restoreVersionText, isVerifier);
    let verifyCard = true;

    restorePopup.compInstance.checkBoxChanged.pipe(untilDestroyed(this)).subscribe((value) => {
      verifyCard = value;
    });

    restorePopup.compInstance.primaryButton.pipe(take(1), untilDestroyed(this)).subscribe(() => {
      const publishedTime = Date.now();
      const version = this.currentVersion().version;
      const draft: Wiki.Draft = { ...version, contributors: [], modifiedTime: publishedTime };
      const req: Wiki.PublishDraftRequest = {
        cardId: this.card.id,
        collectionId: this.card.collectionId,
        draft,
        publishedTime,
        versionNumber: this.card.version || 0,
        versionChangeLog: `Restored from ${restoreVersionText}`,
      };

      if (isVerifier && verifyCard) {
        this.wikiCardsService.updateVerificationStatus('Verified', [this.card.id], false, publishedTime);
      }

      this.wikiCardSaveFlowService.publishCard(req, false).then((updatedCard) => {
        this.finallyRestoreVersion(restoreVersionText, updatedCard);
        restorePopup.destroy();
      });
    });
  }

  private finallyRestoreVersion(restoreVersionText: string, updatedCard: Wiki.Card) {
    this.closePopup();
    this.wikiPopupsService.onRestoreVersion = updatedCard;
    this.showRestoreToaster(restoreVersionText);
  }

  showRestoreToaster(restoreVersionText: string) {
    this.showToasterService.showToaster({
      id: 'restore-version',
      content: `The ${restoreVersionText} was successfully restored`,
      icon: { type: 'font', value: 'icon-check-circle' },
      intent: 'primary',
    });
  }

  onHideVersionList() {
    this.hideVersionList.update((hide) => !hide);
  }
}
