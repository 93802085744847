import { Injectable } from '@angular/core';
import { Commands, Results, Stats, Verifications, Wiki } from '@local/client-contracts';
import { extension as getExtensionByMimeType } from '@local/common-web';
import { generateTitleUrl, getIconByExtension } from '@local/ts-infra';
import { DateFormat } from '@shared/consts';
import { LogService } from '@shared/services';
import { generateFullPrefixedURL, isIcon } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import moment from 'moment';
import { Action, RESULT_ACTION_SETTING } from '../../views/results/models/view-filters';
import { AvatarListService } from '../avatar-list.service';
import { BlobsService } from '../blob.service';
import { CollectionsUtilService } from '../collections-util.service';
import { CollectionsService } from '../collections.service';
import { HubService, TimePhrases } from '../hub.service';
import { WikiCardCollectionResultItem } from '../search/client/wiki-collection-items';
import { StatsService } from '../stats.service';

@Injectable()
export class WikiCardBuilderService {
  private readonly MAX_TAG_AMOUNT: number = 3;
  private logger: Logger;

  constructor(
    private collectionsService: CollectionsService,
    private statsService: StatsService,
    private collectionsHelperService: CollectionsUtilService,
    private avatarListService: AvatarListService,
    private blobService: BlobsService,
    private logService: LogService,
    private hubService: HubService
  ) {
    this.logger = this.logService.scope('wiki-collection-item-builder');
  }

  ///@@ Result item logic
  async buildCardResultView(
    card: Wiki.Card,
    wiki: Wiki.WikiCollection,
    stat: Stats.StatVal,
    withIcon = true,
    searchView?: boolean,
    stateView?: boolean
  ): Promise<WikiCardCollectionResultItem> {
    try {
      const canEdit = this.collectionsHelperService.canEdit(wiki);
      const isPrivate = !wiki?.shareOptions;

      const createByBullet: Results.BulletPart = await this.buildCreateByBullet({
        modifiedTime: card.publishedTime,
        modifiedBy: card.publishedBy,
        createdBy: card.createdBy,
        createdTime: card.createdTime,
      });

      const dynamicCommands = this.buildDynamicCommands(canEdit, stateView, card.verification);
      const path = card['path'] as Wiki.Path[];
      const subtitlePath = this.buildPathView(path, withIcon);

      const resultItem: WikiCardCollectionResultItem = this.buildResultView(
        card,
        path,
        withIcon,
        subtitlePath,
        createByBullet,
        dynamicCommands
      );

      if (searchView) {
        this.buildBulletView(isPrivate, resultItem);
      }

      if (stat) {
        this.buildStatView(stat, resultItem);
      }

      if (card.verification?.status) {
        this.buildBadgeView(card, resultItem);
      }

      if (card.tags?.length) {
        this.buildTagsView(card, resultItem);
      }

      await this.buildAttachmentsView(resultItem, card?.attachments);

      return resultItem;
    } catch (error) {
      this.logger.error('error to build card result view, cardId:', card.id, error);
    }
  }

  async buildDraftResultView(draft: Wiki.DraftStructure, withIcon = false, withBadge = false): Promise<WikiCardCollectionResultItem> {
    const createByBullet: Results.BulletPart = await this.buildCreateByBullet(draft, true);
    const dynamicCommands = this.buildDraftDynamicCommands();
    const path = draft.path;
    const subtitlePath = this.buildPathView(path, false);

    const resultItem: WikiCardCollectionResultItem = this.buildResultView(
      draft,
      path,
      withIcon,
      subtitlePath,
      createByBullet,
      dynamicCommands,
      false
    );

    if (withBadge) {
      this.buildDraftBadgeView(resultItem);
    }

    await this.buildAttachmentsView(resultItem, draft?.attachments);

    return resultItem;
  }

  private buildResultView(
    result: Wiki.Card | Wiki.DraftStructure,
    path: Wiki.Path[],
    withIcon: boolean,
    subtitlePath: Results.Subtitle,
    createByBullet: Results.BulletPart,
    dynamicCommands: string[],
    showSummary = true
  ): WikiCardCollectionResultItem {
    const card = result as Wiki.Card;
    const draft = result as Wiki.DraftStructure;
    const onClickCommand: Commands.OpenUrl = {
      type: 'open-url',
      url: generateFullPrefixedURL('/' + generateTitleUrl('a', result.title, card?.id || draft?.cardId), 'path'),
    };

    const action: Action = {
      type: 'wiki card',
      click: { ...RESULT_ACTION_SETTING['wiki-card']?.action.click },
    };

    if (showSummary) {
      action.click.actions = [{ type: 'summary' }];
    }

    return {
      type: 'result',
      id: card?.id || draft?.cardId,
      resourceId: card?.resourceId,
      isFavorite: false,
      updatedTime: card?.publishedTime || card?.modifiedTime,
      path,
      view: {
        icon: withIcon ? { name: 'icon-card' } : null,
        title: {
          text: result.title,
          onClick: result.title ? onClickCommand : null,
        },
        subtitle: subtitlePath,
        bullets: [{ parts: [createByBullet] }],
      },
      showResultSections: {
        enableCommandBar: false,
        hasResource: false,
        showBadgeGalleryView: true,
        showSubtitleInEndLine: true,
      },
      dynamicCommands,
      collectionId: card?.collectionId,
      filterType: 'wiki-local',
      verification: card?.verification,
      action,
    };
  }

  private buildPathView(path: Wiki.Path[], excludeWikiName?: boolean): Results.Subtitle {
    if (!path?.length) {
      return;
    }
    const pathsToProcess = excludeWikiName ? path.slice(1) : path;
    const textPath = pathsToProcess?.map((path) => path?.name).join(' / ');
    return {
      text: textPath,
    };
  }

  private async buildAttachmentsView(resultItem: WikiCardCollectionResultItem, attach: Wiki.CardAttachment[]) {
    const fileAttachments = attach?.filter((a) => a.type === 'File' || !a.type);

    if (!fileAttachments?.length) {
      return;
    }

    const attachments: Results.Attachment[] = [];
    resultItem.view.tagGroups = resultItem.view.tagGroups?.length ? resultItem.view.tagGroups : [];

    for (const attach of fileAttachments) {
      const blobUrl = await this.blobService.getBlobFullUrl(attach.blobId);
      const extension = getExtensionByMimeType(attach.mimeType);
      const attachment = {
        text: attach.name,
        tooltip: attach.name,
        type: 'attachment',
        extension,
        icon: { lightUrl: getIconByExtension(extension as string) },
        onClick: {
          type: 'open-file',
          value: location.origin + '/' + generateTitleUrl('b', attach.name, attach.blobId),
        } as Commands.DynamicCommand,
        onDrag: { type: 'download-url', url: blobUrl } as Commands.DownloadUrl,
      } as Results.Attachment;
      attachments.push(attachment);
    }
    resultItem.view.tagGroups.push({ tags: attachments });
  }

  private buildTagsView(card: Wiki.Card, resultItem: WikiCardCollectionResultItem) {
    if (!card.tags?.length) {
      return;
    }
    resultItem.view.tagGroups = [];
    const tags = card.tags.slice(0, this.MAX_TAG_AMOUNT).map(
      (t) =>
        ({
          text: t,
          tooltip: t,
          type: 'tag',
        }) as Results.Tag
    );
    if (card.tags.length > this.MAX_TAG_AMOUNT) {
      tags.push({
        text: '+' + (card.tags.length - this.MAX_TAG_AMOUNT),
        type: 'tag',
      } as Results.Tag);
    }
    resultItem.view.tagGroups.push({ tags });
  }

  private buildBadgeView(card: Wiki.Card, resultItem: WikiCardCollectionResultItem) {
    const verification = card.verification;
    const isVerified: boolean = verification.status === 'Verified';
    const formatDate = (input: number): string => {
      return moment(input).format(DateFormat.FULL_DATE);
    };
    resultItem.view.badge = {
      text: '',
      icon: { lightUrl: isVerified ? './assets/verification/verified-light.svg' : './assets/verification/unverified-light.svg' },
      tooltip: isVerified
        ? `Verified until ${formatDate(verification.verifiedUntil)}`
        : `Unverified since ${formatDate(verification.statusChangedAt)}`,
    };
  }

  private buildDraftBadgeView(resultItem: WikiCardCollectionResultItem) {
    resultItem.view.badge = {
      text: 'draft',
      textColor: { lightTheme: 'var(--light-blue-500)' },
      border: { lightTheme: 'var(--light-blue-500)' },
    };
  }

  private buildBulletView(isPrivate: boolean, resultItem: WikiCardCollectionResultItem) {
    resultItem.view.bullets = resultItem.view.bullets?.length ? resultItem.view.bullets : [];

    const permissionsBullet: Results.BulletPart = {
      text: isPrivate ? 'Private' : 'Shared',
      icon: { name: isPrivate ? 'icon-lock' : 'icon-department' },
      tooltip: null,
    };
    resultItem.view.bullets.push({ parts: [permissionsBullet] });
  }

  private buildDynamicCommands(canEdit: boolean, stateView: boolean, verification: Verifications.Verification) {
    const dynamicCommands = ['make_a_copy'];
    if (canEdit) {
      dynamicCommands.push('move-to-wiki');
    }
    if (verification) {
      dynamicCommands.push('verification_details', 'request_verification');
      const isVerifier: boolean = verification.policy?.isVerifier;
      if (isVerifier) {
        if (verification.status === 'Verified') {
          dynamicCommands.push('unverify');
        } else {
          dynamicCommands.push('verify');
        }
      }
    }
    if (canEdit && !stateView) {
      dynamicCommands.push('delete');
    }
    return dynamicCommands;
  }

  private buildDraftDynamicCommands() {
    return ['open', 'copy-url', 'delete-draft'];
  }

  private async buildCreateByBullet(time: TimePhrases, isDraft = false): Promise<Results.BulletPart> {
    const accountId =
      (time.createdTime === time.modifiedTime ? time.createdBy : time.modifiedBy) || this.collectionsService.workspace?.accountId;

    const timePhrase = await this.hubService.getTimePhrases({ ...time, modifiedBy: accountId });
    const avatar = await this.avatarListService.getOwnerAvatar(accountId);
    const displayStartPhrase = time.createdTime === time.modifiedTime ? 'Created by' : `Last ${isDraft ? 'updated' : 'published'} by`;

    return {
      text: `${displayStartPhrase} ${timePhrase.name}  ${timePhrase.timeText}`,
      icon: { lightUrl: isIcon(avatar.imgUrl) ? avatar.imgUrl.lightUrl : avatar.imgUrl, rounded: true },
      tooltip: timePhrase.fullDetailsTime,
    };
  }

  private buildStatView(stat: Stats.StatVal, resultItem: WikiCardCollectionResultItem) {
    resultItem.view.bullets = resultItem.view.bullets?.length ? resultItem.view.bullets : [];

    const bullet: Results.BulletPart = {
      text: `${stat.views} ${stat.views > 1 ? 'Views' : 'View'}`,
      tooltip: null,
      icon: null,
    };
    resultItem.view.bullets.push({ parts: [bullet] });
  }

  async createStatDictionary(items: any): Promise<{ [id: string]: Stats.StatVal }> {
    const cardsStatDictionary: { [id: string]: Stats.StatVal } = {};

    const cardsStat = await this.statsService.getViewsById(new Set(items.map((card) => card.id)));

    cardsStat.forEach((stat) => {
      cardsStatDictionary[stat.id] = stat;
    });

    return cardsStatDictionary || {};
  }
}
