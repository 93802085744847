import { Assistants, Experiences, Commands, Filters, Search } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { AssistantsService } from '@shared/services/assistants.service';
import { SessionService } from '@shared/services/session.service';
import { Logger } from '@unleash-tech/js-logger';
import { Subscription, firstValueFrom } from 'rxjs';
import { buildResourceLookupFromJson } from 'src/app/bar/utils/formatting-utils';
import {
  AnswerGenerateState,
  AnswerSearchItem,
  AnswerSearchReady,
  SearchResults,
  StaticSearchItem,
  TelemetryTrigger,
} from 'src/app/bar/views/results/models/results-types';
import { AnswerResourcesService } from '../../../answer-resources.service';
import { AnswerSearchService } from '../../../answers-search.service';
import { ChatAssistantsService } from '../../../chat-assistants.service';
import { ExperiencesService } from '../../../experiences.service';
import { FiltersService } from '../../../filters.service';
import { ResultMarkdownService } from '../../../result-markdown.service';
import { ResultsService } from '../../../results.service';
import { WikiCardsService } from '../../../wikis/wiki-cards.service';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';
import { AnswersSourceSettings } from './answers-source-settings';
import { isWikiCard, isWikiCardFile } from 'src/app/bar/views/results/utils/results.util';
import { isGeneralAssistant } from '@local/common-web';

export class AnswersSearchClient implements SearchClient<AnswersSourceSettings> {
  private readonly SEPARATOR = /\s+/;
  private readonly INTERVAL_ANSWERS = 15;
  private logger: Logger;

  private instances: { [sessionName: string]: Subscription } = {};

  constructor(
    logService: LogService,
    private resultsService: ResultsService,
    private resultMarkdownService: ResultMarkdownService,
    private sessionService: SessionService,
    private wikiCardService: WikiCardsService,
    private assistantService: AssistantsService,
    private resourcesService: AnswerResourcesService,
    private answerSearchService: AnswerSearchService,
    private chatAssistantsService: ChatAssistantsService,
    private filtersService: FiltersService,
    private experiencesService: ExperiencesService
  ) {
    this.logger = logService.scope('answers');
  }

  supportsSort(_sort: Search.Sort): boolean {
    return true;
  }

  supportsFilters(_filters: Filters.Values): boolean {
    return true;
  }

  search(request: SearchRequest<AnswersSourceSettings>, response: SearchResponse): SearchResponseType {
    const subscription = this.instances[request.sessionName];
    if (subscription) {
      subscription.unsubscribe();
      this.instances[request.sessionName] = null;
    }
    return this.innerSearch(request, response);
  }

  nextPage(_request: SearchRequest<AnswersSourceSettings>, _response: SearchResponse, _trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  destroy(id: number, sessionName: string): void {
    const subscription = this.instances[sessionName];
    if (subscription) {
      subscription?.unsubscribe();
      delete this.instances[sessionName];
    }
    this.resourcesService.clearResources();
  }

  private isQueryTooShort(query: string, sourceSettings: AnswersSourceSettings) {
    return (
      !query ||
      query.length < sourceSettings.minQueryLength ||
      query.split(this.SEPARATOR).filter((word) => word.length >= sourceSettings.minWordLength).length < sourceSettings.minWords
    );
  }

  private async buildSearchAnswerRequest(request: SearchRequest<AnswersSourceSettings>, query: string) {
    let knowledgeType = Experiences.KnowledgeType.Internal;
    if (request.sourceSettings.assistantId) {
      const experience = await this.experiencesService.getExperience(request.sourceSettings.assistantId, 'only');
      knowledgeType = (isGeneralAssistant(experience) && experience?.settings?.general?.knowledgeType) || knowledgeType;
    }
    if (knowledgeType == Experiences.KnowledgeType.External) {
      return this.answerSearchService.buildExternalRequest(request, query);
    }

    const mergeFilters = this.filtersService.allFilters;
    if (request.sourceSettings.collectionId) {
      mergeFilters['collectionId'] = [request.sourceSettings.collectionId];
    }
    return this.answerSearchService.buildInternalRequest(request, query, mergeFilters);
  }

  private async innerSearch(request: SearchRequest<AnswersSourceSettings>, response: SearchResponse) {
    const sourceSettings = request.sourceSettings;

    const query: string = request.query?.trim();
    if (this.isQueryTooShort(query, sourceSettings)) {
      response.complete(true);
      return;
    }

    const req = await this.buildSearchAnswerRequest(request, query);
    if (response.cancelled) {
      return;
    }

    let renderResultsInterval: string | number | NodeJS.Timeout;
    const tasks: (() => Promise<void>)[] = [];
    let searchDone = false;
    this.instances[request.sessionName] = this.assistantService.answers$(req).subscribe({
      next: (res) => {
        if (response.cancelled) {
          clearInterval(renderResultsInterval);
          return;
        }
        const task = () => this.handleResults(res, request, response, query, req.knowledgeType);
        if (!AnswerGenerateState.includes(res.status)) {
          task();
          return;
        }
        tasks.push(task);
        if (!renderResultsInterval) {
          // set a fixed pace of interval even if backend returns a bulk of events at once
          renderResultsInterval = setInterval(() => {
            if (response.cancelled) {
              clearInterval(renderResultsInterval);
              return;
            }
            const currentTask = tasks.shift();
            if (!currentTask) {
              if (searchDone) {
                clearInterval(renderResultsInterval);
              }
              return;
            }
            currentTask();
          }, this.INTERVAL_ANSWERS);
        }
      },
      error: () => {
        searchDone = true;
      },
      complete: () => {
        searchDone = true;
      },
    });
  }

  private async handleResults(
    res: Assistants.AnswersSearchResponse,
    request: SearchRequest<AnswersSourceSettings>,
    response: SearchResponse,
    query: string,
    knowledgeType: Experiences.KnowledgeType
  ) {
    let items = [];
    switch (res.status) {
      case 'Skipped':
        break;
      case 'IsQuestion': {
        items = await this.handleIsQuestion(request, items);
        break;
      }
      case 'NoResults':
      case 'Loading':
      case 'RephraseRequired': {
        const loadingItem = this.answerSearchService.buildLoadingItem(query, res);
        items = [loadingItem];
        break;
      }
      case 'Generating':
      case 'GeneratingDone':
      case 'Full':
        items =
          knowledgeType === Experiences.KnowledgeType.External
            ? await this.onExternalAnswerReady(request, res, query)
            : await this.onInternalAnswerReady(request, response, res, query);
        break;
    }
    if (request.sourceSettings.displayOpenChatResult && AnswerSearchReady.includes(res.status)) {
      const openChatItem: StaticSearchItem = this.answerSearchService.buildFollowUpItem(res);
      const currentSession = await firstValueFrom(this.sessionService.current$);
      openChatItem.description = `Ask about anything in ${currentSession?.workspace?.name || 'N/A'}`;
      items.push(openChatItem);
    }
    response.items = items;
    response.complete(true);
  }

  private async handleIsQuestion(request: SearchRequest<AnswersSourceSettings>, items: any[]) {
    let defaultSearchAnswerItem: StaticSearchItem;
    if (request.sourceSettings.assistantId) {
      const globalAssistant = await this.experiencesService.getExperience(request.sourceSettings.assistantId, 'only');
      defaultSearchAnswerItem = this.answerSearchService.buildAssistantAnswerStaticSearchItem(globalAssistant, 'search-answer');
    } else {
      const currentSession = await firstValueFrom(this.sessionService.current$);
      defaultSearchAnswerItem = this.answerSearchService.buildAnswerStaticSearchItem(currentSession, request);
    }
    items = [defaultSearchAnswerItem];
    if (!request.sourceSettings.assistantId) {
      const assistantItems = await this.getQuestionAssistantsItems();
      items.push(...(assistantItems || []));
    }
    return items;
  }

  private async getQuestionAssistantsItems(): Promise<StaticSearchItem[]> {
    const assistants = await firstValueFrom(this.chatAssistantsService.assistantForSearch$);
    const items: StaticSearchItem[] = assistants.map((a) => this.answerSearchService.buildAssistantAnswerStaticSearchItem(a));
    items.sort((a, b) => {
      return a.title?.localeCompare(b.title);
    });
    return items;
  }

  private buildResourceLookupJson(resources: Search.ResultResourceItem[], query: string): string {
    const resourcesArr = resources.map((resource) => {
      const title = resource.view?.title?.text || '';
      const link = this.getResourceLink(resource) || '';
      const subtitle = resource.view?.subtitle?.text || '';
      const iconUrl = resource.view?.icon?.['lightUrl']?.replace('local://', 'https://') || '';
      return { title, link, iconUrl, subtitle };
    });

    return JSON.stringify({ answer: 'We found the following resources matching your query -  ', resources: resourcesArr });
  }

  private getResourceLink(resource: Search.ResultResourceItem) {
    if (isWikiCard(resource)) {
      return this.wikiCardService.getCardUrl(resource?.resource?.data?.title, resource.resource?.data?.id, true);
    }
    if (isWikiCardFile(resource)) {
      return this.wikiCardService.getCardUrl(resource?.resource?.data?.cardTitle, resource.resource?.data?.cardId, true);
    }
    return (<Commands.OpenUrl>resource.view?.title?.onClick)?.url;
  }

  private async onInternalAnswerReady(
    request: SearchRequest<AnswersSourceSettings>,
    response: SearchResponse,
    res: Assistants.AnswersSearchResponse,
    query: string
  ) {
    let items: SearchResults[] = (res.intent == 'ResourceLookup' ? res.results?.slice(0, 5) : res.results) || [];
    let text: string;
    let formattedAnswer: string;
    if (res.federatedAnswer) {
      text = formattedAnswer = res.federatedAnswer.answer;
      if (res.federatedAnswer.resourceIds?.length) {
        const uniqueIds = new Set();
        items = res.results
          ?.filter((r) => {
            if (res.federatedAnswer.resourceIds.includes(r.id) && !uniqueIds.has(r.id)) {
              uniqueIds.add(r.id);
              return true;
            }
            return false;
          })
          ?.slice(0, 12);
      }
    }
    for (const item of items) {
      item.action = await this.resultsService.getResultAction(item);
      if (response.cancelled) {
        return;
      }
    }
    if (res.intent === 'ResourceLookup') {
      if (!request.sourceSettings.preventFormattedAnswer) {
        formattedAnswer = buildResourceLookupFromJson(items as Search.ResultResourceItem[]);
      }
    }
    this.resourcesService.resources = items as Search.ResultResourceItem[];
    const template = { type: 'answer', query } as AnswerSearchItem;
    const searchItems: SearchResults[] = [];
    if (!request.sourceSettings.preventFormattedAnswer && res.federatedAnswer?.references) {
      formattedAnswer = this.answerSearchService.insertReferencesIntoText(res.federatedAnswer, items as Search.ResultResourceItem[]);
    }

    if (!request.sourceSettings.preventFormattedAnswer) {
      formattedAnswer = this.resultMarkdownService.render(
        formattedAnswer || '',
        res.federatedAnswer?.references?.length ? { items, sourceType: 'result-answer' } : null
      );
    }

    const answerItem = {
      ...template,
      text,
      resources: items,
      references: res.federatedAnswer.references,
      resourceIds: res.federatedAnswer.resourceIds,
      state: res.status,
      debugInfo: res.debugInfo,
      searchId: res.searchId,
      intent: res.intent,
      assistantId: request.sourceSettings.assistantId,
      formattedAnswer,
    } as AnswerSearchItem;
    searchItems.push(answerItem);
    return searchItems;
  }

  private async onExternalAnswerReady(request: SearchRequest<AnswersSourceSettings>, res: Assistants.AnswersSearchResponse, query: string) {
    let text: string;
    let formattedAnswer: string;
    if (res.federatedAnswer) {
      text = formattedAnswer = res.federatedAnswer.answer;
    }

    const template = { type: 'answer', query, resources: [], references: [], resourceIds: [] } as AnswerSearchItem;
    const searchItems: SearchResults[] = [];

    const answerItem = {
      ...template,
      text,
      state: res.status,
      debugInfo: res.debugInfo,
      searchId: res.searchId,
      formattedAnswer: request.sourceSettings.preventFormattedAnswer
        ? formattedAnswer
        : this.resultMarkdownService.render(formattedAnswer || '', null),
    } as AnswerSearchItem;
    searchItems.push(answerItem);
    return searchItems;
  }

  getTelemetryEndEvent(_response: SearchResponse): Partial<EventInfo>[] {
    return;
  }
}
