import { Injectable } from '@angular/core';
import { Experiences } from '@local/client-contracts';
import { filter, map } from 'rxjs';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { observable } from '@local/common';
import { isGeneralAssistant } from '@local/common-web';

@Injectable()
export class ChatAssistantsService {
  constructor(private experiencesService: ExperiencesService) {}

  @observable
  get assistantForChat$() {
    return this.experiencesService.all$.pipe(
      filter((all) => !!all),
      map((all) => all.filter((a) => this.isValidAssistantForChat(a)))
    );
  }

  @observable
  get assistantForSearch$() {
    return this.experiencesService.all$.pipe(
      filter((all) => !!all),
      map((all) => all.filter((a) => this.isValidAssistantForQuickSearch(a)))
    );
  }

  isValidAssistantForChat(assistant: Experiences.ExperienceItem): boolean {
    if (!isGeneralAssistant(assistant)) {
      return;
    }
    return (
      assistant?.settings?.general?.displayOnChatPage != false &&
      (assistant?.settings?.general?.knowledgeType === Experiences.KnowledgeType.External || assistant.impersonateUser)
    );
  }

  private isValidAssistantForQuickSearch(assistant: Experiences.ExperienceItem): boolean {
    if (!isGeneralAssistant(assistant)) {
      return;
    }
    return assistant?.settings?.general?.displayOnQuickSearch;
  }
}
