<div class="list-wrapper">
  <div class="list">
    <div class="item" *ngFor="let starter of textList(); let idx = index; let last = last; trackBy: trackByIndex">
      <u-input
        #inputItem
        class="item-input"
        [model]="starter"
        [inlineExtraIcon]="starter && state !== 'readonly' && clearIcon"
        [inlineExtraIconPos]="'right'"
        [inlineExtraIconStyle]="{ fontSize: '9px', color: 'var(--color-text-tertiary)' }"
        [pTooltipTextInlineExtraIcon]="'Clear'"
        (onInlineExtraIconClick)="onRemoveItem(idx)"
        (onChange)="onChangeItem($event, idx)"
        inputSize="small"
        [label]="label"
        [placeholder]="placeholder"
        [readonly]="state === 'readonly'"
        [disabled]="state === 'disabled'"
        [inputFocus]="last && focusLastInput"
        (keydown.enter)="$event.stopPropagation()"
      ></u-input>
    </div>
  </div>
  @if (state !== 'readonly') {
    <u-button
      class="add-item"
      [label]="'Add'"
      [styles]="{ border: 'none', padding: 0, background: 'none', gap: '4px', height: '28px' }"
      type="secondary-light"
      [disabled]="textList().length >= limit || disableAddBtn() || state === 'disabled'"
      fontIcon="plus"
      (onClick)="onAddItem()"
    >
    </u-button>
  }
</div>
