import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Experiences } from '@local/client-contracts';
import { RouterService } from '@shared/services/router.service';
import { firstValueFrom } from 'rxjs';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';

@Injectable({ providedIn: 'root' })
export class AssistantResolver {
  constructor(
    private assistantService: ExperiencesService,
    private routerService: RouterService
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<Experiences.ExperienceItem> {
    const id = route.params.id;
    let channel = await this.checkIfLoaded(id);
    if (channel) {
      return channel;
    }
    await this.assistantService.refresh();
    channel = await this.checkIfLoaded(id);
    if (channel) {
      return channel;
    }
    this.routerService.navigateByUrl('/not-found');
    return null;
  }

  private async checkIfLoaded(id: string) {
    const channel = await this.getChannel(id);
    return channel;
  }

  async getChannel(id: string) {
    const data = await firstValueFrom(this.assistantService.visible$);
    return data?.find((a) => a.id === id);
  }
}
