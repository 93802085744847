import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Search } from '@local/client-contracts';
import { observable } from '@local/common';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { EmbedService } from '@shared/embed.service';
import { WindowService } from '@shared/services';
import { AppService } from '@shared/services/app.service';
import { RouterService } from '@shared/services/router.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { getWidthBreakpointScreen } from '@shared/utils/break-point.utils';
import { cloneDeep, isEmpty, isEqual, isString, merge } from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {
  DisplayItemData,
  SearchResults,
  TelemetryTrigger,
  isCollectionFileStaticItem,
  isPerson,
  isResourceResult,
  isWikiDraft,
} from '../views';
import {
  getPreviewId,
  getPreviewKeyByPreviewType,
  getPreviewName,
  getPreviewTypeByPath,
  getUrlByPreviewKey,
} from '../views/preview/helpers/preview.utils';
import { PreviewMode } from '../views/preview/model/preview-mode';
import { PopUpSource as PopupSource } from '../views/preview/people-preview/services/people.service';
import { PreviewType } from '../views/results/models/view-filters';
import { LinkResourcesResultExtra, MailResultExtra } from './search/client';

export interface PreviewViewModel {
  previewState?: PreviewMode;
  previewWidth?: number;
}

interface OpenPopupData {
  data: PopupData;
  previewType: PreviewType;
}

export interface PopupData {
  item?: Search.ResultResourceItem;
  preventClearQueryParams?: boolean;
}
export interface ResultPopupData extends PopupData {
  id: string;
  index: number;
  source: PopupSource;
  trigger: TelemetryTrigger;
  searchId: string;
  isLauncher?: boolean;
}

export interface LoadPreviewParams {
  name: string;
  id?: string;
  linkId?: string;
  resourceId?: string;
}

type PreviewCacheModel = { state: PreviewMode; width: string };

export const SMALL_WIDTH_PREVIEW = 700;
export const EXTRA_SMALL_WIDTH_PREVIEW = 440;
export const DRAFT_CARD_URL_PARAM = 'isDraft';
export const NEW_CARD_URL_PARAM = 'isNewMode';

@Injectable({ providedIn: 'root' })
export class PreviewService {
  private readonly BREAKPOINTS_PREVIEW_SIZE = {
    medium: { min: 40, max: 50 },
    large: { min: 33, max: 66 },
    'extra-large': { min: 33, max: 66 },
  };
  private readonly PREVIEW_CACHE_KEY = 'preview-cache';
  private readonly AUX_WINDOW_SIZE = {
    height: { small: '600px', flex: '600px', maxHeight: 731 },
    width: { small: '600px', medium: '800px', flex: '800px', maxWidth: 940 },
  };
  private _previewStateCache: PreviewMode;
  private isLauncher: boolean;
  private previewViewModel: PreviewViewModel = {};

  private _previewModelChange$: ReplaySubject<PreviewViewModel> = new ReplaySubject(1);
  private _openPreviewPopup$: Subject<OpenPopupData> = new Subject();
  private _clearPreviewPopup$: Subject<PreviewType> = new Subject();
  private isEmbed = isEmbed();

  @observable
  get previewModelChange$(): Observable<PreviewViewModel> {
    return this._previewModelChange$.asObservable();
  }

  private set previewModelChange(value: PreviewViewModel) {
    this._previewModelChange$.next(cloneDeep(value));
  }

  @observable
  get openPreviewPopup$(): Observable<OpenPopupData> {
    return this._openPreviewPopup$.asObservable();
  }

  @observable
  get clearPreviewPopup$(): Observable<PreviewType> {
    return this._clearPreviewPopup$.asObservable();
  }

  constructor(
    private appService: AppService,
    private routerService: RouterService,
    private windowService: WindowService,
    private embedService: EmbedService,
    private titleService: TitleBarService
  ) {
    this.appService.windowStyle$.subscribe((b) => {
      this.isLauncher = b != 'standard';
    });
    this.previewModelChange = this.previewViewModel;
  }

  setPreviewWidth(val: number) {
    const cacheModel = JSON.stringify({ state: this._previewStateCache, width: val?.toString() });
    localStorage.setItem(`${this.currentLocation}-${this.PREVIEW_CACHE_KEY}`, cacheModel);
    this.previewViewModel.previewWidth = val;
    this.previewModelChange = this.previewViewModel;
  }

  setPreviewStateCache(previewMode: PreviewMode) {
    const cacheModel = JSON.stringify({ state: previewMode, width: this.previewViewModel.previewWidth });
    localStorage.setItem(`${this.currentLocation}-${this.PREVIEW_CACHE_KEY}`, cacheModel);
    this._previewStateCache = previewMode;
  }

  private get currentLocation(): string {
    const url = this.routerService.activeRoute?.snapshot?.url;
    return url ? url[0]?.path : '';
  }

  get previewStateCache(): PreviewMode {
    this._previewStateCache = (
      JSON.parse(localStorage.getItem(`${this.currentLocation}-${this.PREVIEW_CACHE_KEY}`) || '{}') as PreviewCacheModel
    )?.state;
    return this._previewStateCache;
  }

  get previewWidth(): number {
    const cachePreviewWidth = (
      JSON.parse(localStorage.getItem(`${this.currentLocation}-${this.PREVIEW_CACHE_KEY}`) || '{}') as PreviewCacheModel
    )?.width;
    return cachePreviewWidth ? parseInt(cachePreviewWidth) : this.minPreviewWidth;
  }

  get minPreviewWidth(): number {
    return this.BREAKPOINTS_PREVIEW_SIZE[getWidthBreakpointScreen()]?.min;
  }

  get maxPreviewWidth(): number {
    return this.BREAKPOINTS_PREVIEW_SIZE[getWidthBreakpointScreen()]?.max;
  }

  private setQueryParams(item: SearchResults | Search.Item, previewType: PreviewType): Params {
    if (isWikiDraft(item) && previewType === 'wiki card') {
      return { [DRAFT_CARD_URL_PARAM]: true };
    }
    return {};
  }

  setPreviewState(previewMode: PreviewMode, selectedIndex?: number, item?: SearchResults | Search.Item, additionalParams?: Params) {
    if (selectedIndex === null) return;
    let previewState = this.previewViewModel.previewState;
    const previewType = (item as DisplayItemData)?.action?.type as PreviewType;
    additionalParams = merge({}, additionalParams, this.setQueryParams(item, previewType));

    if ((this.isLauncher || ['small', 'extra-small'].includes(getWidthBreakpointScreen())) && previewMode !== 'popup') {
      this.previewViewModel.previewState = 'none';
      this.previewModelChange = this.previewViewModel;
      return;
    }

    if (!!item && previewMode === 'popup' && (isResourceResult(item) || isPerson(item) || isCollectionFileStaticItem(item))) {
      if (this.checkOpenInAuxWindow(previewType, item as Search.ResultResourceItem, additionalParams)) {
        return;
      }
      this.addPreviewParams(item as Search.ResultResourceItem, previewType, additionalParams);
    }

    switch (previewMode) {
      case 'previous':
        previewState = this.previewStateCache;
        break;
      case 'popup':
        break;
      default:
        previewState = previewMode;
        break;
    }

    this.previewViewModel.previewWidth =
      this.previewWidth > this.BREAKPOINTS_PREVIEW_SIZE[getWidthBreakpointScreen()]?.max ? this.maxPreviewWidth : this.previewWidth;
    this.previewViewModel.previewState = previewState;
    this.previewModelChange = this.previewViewModel;
  }

  getPreviewUrl(item: Search.ResultResourceItem, previewType: PreviewType, addQuery?: boolean) {
    const query = addQuery ? this.routerService.queryParams?.q : null;
    return getUrlByPreviewKey(
      getPreviewKeyByPreviewType(previewType),
      {
        id: getPreviewId(previewType, item),
        name: getPreviewName(previewType, item),
        resourceId: item?.resource?.id,
        linkId: item?.link?.id,
      },
      query?.toString()
    );
  }

  addPreviewParams(item: Search.ResultResourceItem, previewType: PreviewType, additionalParams: Params = {}) {
    if (!item) {
      return;
    }
    const url = this.getPreviewUrl(item, previewType);
    if (!url) {
      return;
    }
    this.routerService.addQueryParam({ purl: url, ...additionalParams });
    this.titleService.active = getPreviewName(previewType, item);
  }

  onDestroyPreview(item: Search.ResultResourceItem, previewType: PreviewType, preventClearQueryParams?: boolean) {
    if (preventClearQueryParams) {
      return;
    }
    const purl = this.routerService.queryParams['purl'];
    const currentUrl = this.getPreviewUrl(item, previewType);
    const prevPreviewType = getPreviewTypeByPath(this.routerService.go(-1));
    if (!purl || !currentUrl || isEqual(isString(purl) ? purl : purl[0], currentUrl)) {
      this.routerService.removeQueryParam('purl');
    }
    if (!['people', 'relevant-people'].includes(previewType) && ['people', 'relevant-people'].includes(prevPreviewType)) {
      this.routerService.back();
    }
  }

  openPreviewPopup(
    item: SearchResults,
    preventClearQueryParams?: boolean,
    searchId?: string,
    selectedIndex?: number,
    trigger?: TelemetryTrigger
  ) {
    const previewType = (item as DisplayItemData)?.action?.type as PreviewType;
    const popupData: PopupData = this.getPopupData(trigger, item, selectedIndex, searchId);
    const previewPopupValue: OpenPopupData = {
      previewType,
      data: { ...popupData, preventClearQueryParams },
    };
    this._openPreviewPopup$.next({ ...previewPopupValue });
  }

  closePreviewPopup(previewType: PreviewType) {
    this._clearPreviewPopup$.next(previewType);
  }

  checkOpenInAuxWindow(previewType: PreviewType, item: Search.ResultResourceItem, additionalParams: Params = {}): boolean {
    const allowAxu = item?.id === 'new-aux';
    if ((!allowAxu && !this.isLauncher) || (previewType === 'wiki card' && item?.id === 'new')) {
      return false;
    }
    let url = this.getPreviewUrl(item, previewType, true);
    url = `${location.origin}/${url}`;
    if (!isEmpty(additionalParams)) {
      url += '?' + new URLSearchParams(additionalParams).toString();
    }
    const height = previewType === 'wiki card' ? this.AUX_WINDOW_SIZE.height.flex : this.AUX_WINDOW_SIZE.height.small;
    let width: string;
    switch (previewType) {
      case 'mail':
        width = this.AUX_WINDOW_SIZE.width.medium;
        break;
      case 'wiki card':
        width = this.AUX_WINDOW_SIZE.width.flex;
        break;
      default:
        width = this.AUX_WINDOW_SIZE.width.small;
        break;
    }
    if (url.startsWith('http') || url.startsWith('local') || url.startsWith('chrome') || url.startsWith('edge'))
      url = new URL(url).pathname;
    if (!url.startsWith('/window/')) url = '/window' + url;
    if (isNativeWindow()) this.windowService.openAuxWindow({ url, width, height });
    else if (this.isEmbed)
      this.embedService.openAuxWindow({
        url,
        width,
        height,
        maxWidth: this.AUX_WINDOW_SIZE.width.maxWidth,
        maxHeight: this.AUX_WINDOW_SIZE.height.maxHeight,
        hideOnClickOut: true,
      });
    return true;
  }

  getPopupData(trigger: string, item: SearchResults, selectedIndex: number, searchId: string): PopupData {
    if (isResourceResult(item)) {
      return {
        id: item.resource?.externalId,
        trigger,
        index: selectedIndex,
        source: 'Results',
        isLauncher: this.isLauncher,
        searchId,
        item,
      } as ResultPopupData;
    }
    return { item } as PopupData;
  }

  manageStateOnComplete(
    selected?: SearchResults,
    resultsLength?: number,
    extra?: LinkResourcesResultExtra | MailResultExtra,
    isDefaultSearch?: boolean
  ) {
    if (resultsLength === 0) {
      this.setPreviewState('none');
      return;
    }
    if (extra && extra.lastPageInfo?.startIndex !== 0) {
      return;
    }
    if (resultsLength === 1 && selected?.action?.type === 'people' && !isDefaultSearch) {
      return;
    }
    this.setPreviewState('previous');
  }

  openFullPreview(item: SearchResults, selectedIndex?: number) {
    if (this.previewViewModel.previewState !== 'none') {
      return;
    }
    if (['people', 'mail', 'relevant-people', 'files'].includes(item.action?.type)) {
      this.setPreviewState('popup', selectedIndex, item);
    }
  }
}
