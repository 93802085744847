import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Style } from '@local/client-contracts';
import { State } from '@shared/types/account-icon-states';

@Component({
  selector: 'entity-icon',
  templateUrl: './entity-icon.component.html',
  styleUrls: ['./entity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityIconComponent {
  @Input() size: string;
  @Input() model: Style.EntityIcon<Style.EntityIconType>;
  @Input() state?: State;
  @Input() styles: { [key: string]: string };
  @Input() spriteEmojiSize: number;

  @Input()
  @HostBinding('class')
  class: string;

  @Input() mirrorClass: boolean = true;
  completedStyle: { [key: string]: string } = {};

  ngOnInit() {
    this.completedStyle = {
      fontSize: this.styles?.fontSize || this.size,
      display: 'flex',
      ...(this.styles || {}),
    };
  }
}
