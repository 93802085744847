import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';
import { UListBoxComponent } from './u-list-box.component';

@NgModule({
  declarations: [UListBoxComponent],
  imports: [CommonModule, ListboxModule, FormsModule],
  exports: [UListBoxComponent],
})
export class UListBoxModule {}
