import { ConnectedPosition, OverlayConfig } from '@angular/cdk/overlay';

export type PopUpOptions = OverlayConfig & {
  position?: PopUpPosition | ConnectedPosition[];
  backdropStyle?: BackdropStyle;
  preventUserEvents?: boolean;
  closeOnClickOut?: boolean;
  /** Appears in the middle of the screen and requires user interaction in order to continue using the app
   * Also prevents closing on clickout
   */
  fullScreenDialog?: boolean;
  preserveWidthRatio?: number;
};

export type PopUpPosition = 'right' | 'left' | 'below' | 'above' | 'center';
export type BackdropStyle = 'clear' | 'blur-5' | 'blur-2' | 'blur-1';

export interface GlobalPosition {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

export const INIT_POSITION_POPUP: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
