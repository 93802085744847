import { Injectable } from '@angular/core';
import { EmbedService } from '@shared/embed.service';
import { EventsService, InternetService, LogService, NativeServicesRpcService, ServicesRpcService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { AssistantsService } from '@shared/services/assistants.service';
import { BrowserExtensionService } from '@shared/services/browser-extension.service';
import { BrowserHistoryService } from '@shared/services/browser-history.service';
import { BrowserTabsService } from '@shared/services/browser-tabs.service';
import { DesktopService } from '@shared/services/desktop.service';
import { DownloadsService } from '@shared/services/downloads.service';
import { FlagsService } from '@shared/services/flags.service';
import { LinksService } from '@shared/services/links.service';
import { LocalActionsService } from '@shared/services/local-actions.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { NativeAppLinkService } from '@shared/services/native-app-link.service';
import { NativeAppService } from '@shared/services/native-app.service';
import { ResourcesService } from '@shared/services/resources.service';
import { SessionStorageService } from '@shared/services/session-storage.service';
import { SessionService } from '@shared/services/session.service';
import { VisitsService } from '@shared/services/visits.service';
import { WebSearchService } from '@shared/services/web-search.service';
import { Logger } from '@unleash-tech/js-logger';
import { GlobalErrorHandler } from 'src/app/global-error-handler';
import { CalculatorsService } from '../../views/calculators/calculators.service';
import { PeopleService } from '../../views/preview/people-preview/services/people.service';
import { RecentSearchesService } from '../../views/results/recent-searches.service';
import { AnswerResourcesService } from '../answer-resources.service';
import { AnswerSearchService } from '../answers-search.service';
import { AvatarListService } from '../avatar-list.service';
import { BlobsService } from '../blob.service';
import { ChatAssistantsService } from '../chat-assistants.service';
import { CollectionsUtilService } from '../collections-util.service';
import { CollectionsService } from '../collections.service';
import { ExperiencesService } from '../experiences.service';
import { FavoritesService } from '../favorites.service';
import { FiltersService } from '../filters.service';
import { GoLinksService } from '../go-links.service';
import { GoToService } from '../go-to.service';
import { HubService } from '../hub.service';
import { QuestionnaireService } from '../questionnaire.service';
import { ResultMarkdownService } from '../result-markdown.service';
import { ResultsService } from '../results.service';
import { SearchParamsService } from '../search-params.service';
import { StatsService } from '../stats.service';
import { SuggestionsSearchService } from '../suggestions-search.service';
import { UrlResolverService } from '../url-resolver.service';
import { WikiCardBuilderService } from '../wikis/wiki-cards-builder.service';
import { WikiCardsService } from '../wikis/wiki-cards.service';
import { WikiDraftsService } from '../wikis/wiki-drafts.service';
import { WikiFoldersService } from '../wikis/wiki-folders.service';
import { WikisService } from '../wikis/wikis.service';
import { WorkspacesService } from '../workspaces.service';
import {
  AnswersSearchClient,
  AssistantIncontextSearchClient,
  AssistantsSearchClient,
  CalculatorSearchClient,
  CollectionsSearchClient,
  GoLinksSearchClient,
  GoToSearchClient,
  LinkResourcesSearchClient,
  LocalActionsSearchClient,
  MailSearchClient,
  RecentSearchesSearchClient,
  SearchClient,
  SourceSettings,
  StaticItemsSearchClient,
  WebSearchClient,
} from './client';
import { BrowserBookmarksSearchClient } from './client/browser-bookmarks';
import { BrowserHistorySearchClient } from './client/browser-history';
import { BrowserTabsSearchClient } from './client/browser-tabs';
import { EmbedItemsSearchClient } from './client/embed-items';
import { FavoritesSearchClient } from './client/favorites';
import { groupResourcesSearchClient } from './client/groups';
import { PeopleSearchClient } from './client/people/people-search-client';
import { QueriesSearchClient } from './client/queries/queries-search-client';
import { QuestionnaireSearchClient } from './client/questionnaire';
import { RelevantPeopleSearchClient } from './client/relevant-people/relevant-people-search-client';
import { ResourcesFavoritesSearchClient } from './client/resources-favorites/resources-favorites-search-client';
import { SearchSourceType } from './client/search-source-type';
import { StaticCollectionItemSearchClient } from './client/static-collection-items';
import { SuggestionsSearchClient } from './client/suggestions';
import { VisitsSearchClient } from './client/visits';
import { WikiCollectionItemSearchClient } from './client/wiki-collection-items';
import { WikiDraftsSearchClient } from './client/wiki-drafts';
import { WikiTreeSearchClient } from './client/wiki-tree';
import { SearchService } from './search.service';

@Injectable()
export class SearchClientFactoryService {
  private logger: Logger;
  private searchClients: { [type in SearchSourceType]?: SearchClient<SourceSettings> } = {};

  constructor(
    private logService: LogService,
    private eventsService: EventsService,
    private recentSearches: RecentSearchesService,
    private filtersService: FiltersService,
    private errorHandler: GlobalErrorHandler,
    private applicationsService: ApplicationsService,
    private linksService: LinksService,
    private session: SessionService,
    private sessionStorage: SessionStorageService,
    private workspaceService: WorkspacesService,
    private resultsService: ResultsService,
    private calculatorsService: CalculatorsService,
    private nativeAppLinkService: NativeAppLinkService,
    private goToService: GoToService,
    private hubService: HubService,
    private collectionsService: CollectionsService,
    private blobsService: BlobsService,
    private webSearchService: WebSearchService,
    private favoritesService: FavoritesService,
    private services: ServicesRpcService,
    private goLinkService: GoLinksService,
    private desktopService: DesktopService,
    private visitsService: VisitsService,
    private browserExtService: BrowserExtensionService,
    private bhService: BrowserHistoryService,
    private downloadsService: DownloadsService,
    private nativeAppService: NativeAppService,
    private nativeServices: NativeServicesRpcService,
    private browserTabsService: BrowserTabsService,
    private resourcesService: ResourcesService,
    private internetService: InternetService,
    private embedService: EmbedService,
    private suggestionsSearchService: SuggestionsSearchService,
    private actionsService: LocalActionsService,
    private searchParamsService: SearchParamsService,
    private memorySearchService: MemorySearchService,
    private wikiCardsService: WikiCardsService,
    private statsService: StatsService,
    private flagsService: FlagsService,
    private collectionsHelperService: CollectionsUtilService,
    private experiencesService: ExperiencesService,
    private assistantsServices: AssistantsService,
    private peopleService: PeopleService,
    private avatarListService: AvatarListService,
    private questionnaireService: QuestionnaireService,
    private urlResolverService: UrlResolverService,
    private goLinksService: GoLinksService,
    private wikiCardBuilderService: WikiCardBuilderService,
    private wikisService: WikisService,
    private wikiFolders: WikiFoldersService,
    private resultMarkdownService: ResultMarkdownService,
    private wikiDraftService: WikiDraftsService,
    private answerResourcesService: AnswerResourcesService,
    private answerSearchService: AnswerSearchService,
    private chatAssistantsService: ChatAssistantsService
  ) {
    this.logger = logService.scope('SearchClientFactoryService');
  }

  getOrCreate(type: SearchSourceType, searchService: SearchService) {
    if (!this.searchClients[type]) {
      this.searchClients[type] = this.create(type, searchService);
    }
    return this.searchClients[type];
  }

  private create(type: SearchSourceType, searchService: SearchService) {
    let linkResourceSearchClient: LinkResourcesSearchClient;
    switch (type) {
      case 'visits':
        return new VisitsSearchClient(
          this.logService,
          this.memorySearchService,
          this.visitsService,
          this.resultsService,
          this.resourcesService,
          this.applicationsService,
          this.filtersService,
          this.linksService,
          this.peopleService
        );
      case 'browser-history':
        return new BrowserHistorySearchClient(this.bhService, this.logService);
      case 'browser-bookmarks':
        return new BrowserBookmarksSearchClient(
          this.logService,
          this.memorySearchService,
          this.browserExtService,
          this.nativeServices,
          this.downloadsService
        );
      case 'browser-tabs':
        return new BrowserTabsSearchClient(this.logService, this.memorySearchService, this.browserTabsService, this.downloadsService);
      case 'link-resources':
        return this.createLinkResourceSearchClient();
      case 'mail-resources':
        linkResourceSearchClient = this.createLinkResourceSearchClient();
        return new MailSearchClient(this.logService, linkResourceSearchClient, this.hubService, this.filtersService);
      case 'local-action':
        return new LocalActionsSearchClient(
          this.logService,
          this.memorySearchService,
          this.filtersService,
          this.actionsService,
          this.desktopService,
          this.goLinkService,
          this.embedService,
          this.applicationsService,
          this.linksService,
          this.peopleService,
          this.flagsService,
          this.experiencesService
        );
      case 'embed-items':
        return new EmbedItemsSearchClient(this.logService, this.memorySearchService, this.embedService);
      case 'calculator':
        return new CalculatorSearchClient(this.logService, this.eventsService, this.calculatorsService, this.hubService);
      case 'goto':
        return new GoToSearchClient(this.goToService, this.logService, this.memorySearchService, this.chatAssistantsService);
      case 'recent-search':
        return new RecentSearchesSearchClient(
          this.logService,
          this.memorySearchService,
          this.recentSearches,
          this.filtersService,
          this.flagsService
        );
      case 'web-search':
        return new WebSearchClient(this.webSearchService, this.logService);
      case 'resources-favorites':
        return this.createResourcesFavoritesSearchClient();
      case 'go-links':
        return this.createGoLinksSearchClient();
      case 'collection':
        return this.createCollectionsSearchClient(searchService);
      case 'assistants':
        return this.createAssistantsSearchClient();
      case 'favorites':
        return new FavoritesSearchClient(
          this.logService,
          this.createResourcesFavoritesSearchClient(),
          this.createGoLinksSearchClient(),
          this.createCollectionsSearchClient(searchService),
          this.createStaticCollectionItemSearchClient(),
          this.memorySearchService
        );
      case 'static-collection-items':
        return new StaticCollectionItemSearchClient(
          this.logService,
          this.collectionsService,
          this.collectionsHelperService,
          this.blobsService,
          this.memorySearchService,
          this.resultsService,
          this.favoritesService,
          this.workspaceService,
          this.avatarListService,
          this.urlResolverService
        );
      case 'wiki-collection-items':
        return new WikiCollectionItemSearchClient(
          this.logService,
          this.collectionsService,
          this.memorySearchService,
          this.wikiCardsService,
          this.statsService,
          this.wikiCardBuilderService
        );
      case 'wiki-tree':
        return new WikiTreeSearchClient(
          this.logService,
          this.memorySearchService,
          this.wikiCardsService,
          this.statsService,
          this.wikisService,
          this.wikiFolders
        );
      case 'wiki-drafts':
        return new WikiDraftsSearchClient(this.logService, this.memorySearchService, this.wikiDraftService, this.wikiCardBuilderService);
      case 'queries':
        return new QueriesSearchClient(this.logService, this.memorySearchService, this.services);
      case 'people':
        return new PeopleSearchClient(
          this.logService,
          this.memorySearchService,
          this.filtersService,
          this.applicationsService,
          this.linksService,
          this.peopleService,
          this.resultsService,
          this.experiencesService
        );
      case 'relevant-people':
        return new RelevantPeopleSearchClient(
          this.logService,
          this.peopleService,
          this.resultsService,
          this.memorySearchService,
          this.linksService,
          this.flagsService,
          this.filtersService
        );
      case 'suggestions':
        return new SuggestionsSearchClient(this.logService, this.memorySearchService, this.suggestionsSearchService);
      case 'answers':
        return new AnswersSearchClient(
          this.logService,
          this.resultsService,
          this.resultMarkdownService,
          this.session,
          this.wikiCardsService,
          this.assistantsServices,
          this.answerResourcesService,
          this.answerSearchService,
          this.chatAssistantsService,
          this.filtersService,
          this.experiencesService
        );
      case 'assistant-incontext':
        return new AssistantIncontextSearchClient(
          this.logService,
          this.assistantsServices,
          this.resultMarkdownService,
          this.resultsService,
          this.answerResourcesService,
          this.answerSearchService
        );
      case 'fill-questionnaire':
        return new QuestionnaireSearchClient(this.questionnaireService);
      case 'static-items':
        return new StaticItemsSearchClient(this.logService, this.session);
      case 'group-resources':
        linkResourceSearchClient = this.createLinkResourceSearchClient();
        return new groupResourcesSearchClient(this.logService, linkResourceSearchClient, this.experiencesService);
      default:
        this.logger.error('search client not found for SearchSourceType', { type });
        break;
    }
  }

  private createResourcesFavoritesSearchClient(): ResourcesFavoritesSearchClient {
    return new ResourcesFavoritesSearchClient(
      this.logService,
      this.favoritesService,
      this.resultsService,
      this.resourcesService,
      this.nativeAppService,
      this.filtersService,
      this.applicationsService,
      this.linksService,
      this.peopleService,
      this.memorySearchService
    );
  }

  private createGoLinksSearchClient(): GoLinksSearchClient {
    return new GoLinksSearchClient(
      this.services,
      this.logService,
      this.memorySearchService,
      this.filtersService,
      this.goLinkService,
      this.session,
      this.favoritesService,
      this.workspaceService,
      this.urlResolverService
    );
  }

  private createCollectionsSearchClient(searchService: SearchService): CollectionsSearchClient {
    return new CollectionsSearchClient(
      this.logService,
      this.memorySearchService,
      this.collectionsService,
      this.wikisService,
      searchService,
      this.session,
      this.sessionStorage
    );
  }

  private createAssistantsSearchClient(): AssistantsSearchClient {
    return new AssistantsSearchClient(this.logService, this.memorySearchService, this.experiencesService, this.session);
  }

  private createStaticCollectionItemSearchClient(): StaticCollectionItemSearchClient {
    return new StaticCollectionItemSearchClient(
      this.logService,
      this.collectionsService,
      this.collectionsHelperService,
      this.blobsService,
      this.memorySearchService,
      this.resultsService,
      this.favoritesService,
      this.workspaceService,
      this.avatarListService,
      this.urlResolverService
    );
  }

  private createLinkResourceSearchClient(): LinkResourcesSearchClient {
    return new LinkResourcesSearchClient(
      this.logService,
      this.eventsService,
      this.recentSearches,
      this.filtersService,
      this.linksService,
      this.errorHandler,
      this.applicationsService,
      this.session,
      this.resultsService,
      this.nativeAppLinkService,
      this.hubService,
      this.internetService,
      this.searchParamsService
    );
  }
}
