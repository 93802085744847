<div class="verification-options-popup-wrapper" (click)="onClosePopup()">
  <div class="verification-options-popup-container" [ngClass]="!isEditor ? 'min-content' : 'max-content'" data-cy="verification-popup" #VerificationPopup (click)="$event.stopPropagation()">
    <ng-container *ngIf="model; else loading">
      <div class="verification-options-popup-header">
        <div class="verification-options-popup-header-content">
          <div class="verification-options-title">{{ model.title }}</div>
          <u-icon
            class="close-icon"
            (click)="onClosePopup()"
            [model]="{ type: 'font', value: 'icon-Windows-close' }"
            [styles]="{ fontSize: '16px', color: 'var(--gray-250)' }"
          ></u-icon>
        </div>
        <div *ngIf="model.title" class="divider"></div>
      </div>

      <div class="scrollbar-wrapper">
        <ng-scrollbar visibility="hover">
          <div #scrollContent class="content" >
            <div [ngSwitch]="model?.type">
              <div *ngSwitchCase="'card'" class="verification-details">
                <div class="last-verifier">
                  <div class="verifier-avatar">
                    <u-icon
                      [model]="
                        initialStatus === 'Expired' || !lastVerifier
                          ? { type: 'font', value: 'icon-clock3' }
                          : { type: 'img', value: { lightUrl: lastVerifier?.picture } }
                      "
                      [fallback]="MEMBER_AVATAR_FALLBACK"
                      [styles]="{ fontSize: '30px' }"
                    ></u-icon>
                  </div>
                  <div class="verifier-text" [ngClass]="{ 'unverified-color-text': initialStatus !== 'Verified' }">
                    <ng-container *ngIf="!lastVerifier; else verifierContent">
                      <span class="text">Unverified</span>
                      <span class="sub-text">Not verified yet</span>
                    </ng-container>
                    <ng-template #verifierContent>
                      <ng-container *ngIf="initialStatus !== 'Expired'; else expired">
                        <span class="text"
                          >{{ initialStatus }} by {{ lastVerifier?.firstName || '' }} {{ lastVerifier?.lastName || '' }}
                          {{ currentUserId === lastVerifier?.userId ? '(You)' : '' }}
                        </span>
                      </ng-container>
                      <ng-template #expired>
                        <span class="text">Unverified due to interval expiration</span>
                      </ng-template>
                      <span class="sub-text"
                        >{{ initialStatus === 'Verified' ? 'Until' : 'On' }}
                        {{ initialStatus === 'Verified' ? verifiedUntil : statusChangedAt }}
                      </span>
                    </ng-template>
                  </div>
                </div>
                

                <ng-container *ngIf="policy?.isVerifier">
                  <u-button
                    elementSize="small"
                    [type]="initialStatus === 'Verified' ? 'primary-danger' : 'primary'"
                    (click)="onChangeStatus()"
                    [label]="initialStatus === 'Verified' ? 'Unverify' : 'Verify'"
                    [styles]="{ width: '96px' }"
                  ></u-button>
                </ng-container>
              </div>

              <div *ngSwitchCase="'wiki'" class="enable-verification">
                <div class="enable-verification-text" [ngClass]="{ 'viewer-mode': !isEditor }">
                  <span class="enable-text">{{ toggleBtnText }}</span>
                  <span class="enable-subtext">To ensure the information is accurate, up-to-date, and approved!</span>
                </div>
                <u-toggle-button [checked]="verificationOn" (onChange)="onToggleButtonChange($event)" [disabled]="!isEditor">
                </u-toggle-button>
              </div>
            </div>
            
            <members-selector
              [model]="model"
              [suggestions]="suggestions"
              [invitedSuggestions]="invitedSuggestions"
              [workspace]="workspace"
              [heightPopup]="heightPopup"
              (inviteChanged)="removeSuggestion($event)"
              (selectChanged)="updateSelectedSuggestion($event)"
              [heightScroll]="237"
              [disabled]="disabledState || !isEditor"
              [showShareButton]="false"
              [viewType]="'verification'"
              [isEditor]="isEditor"
            ></members-selector>

            <div class="interval-container">
              <span class="title">Verification Interval</span>
              <span class="subtitle">Select how often this information should be verified</span>
              <u-dropdown
                class="interval-options"
                panelStyleClass="verification-interval-u-dropdown-panel"
                [ngClass]="{ 'hide-trigger': disabledState || !isEditor }"
                [options]="INTERVAL_OPTIONS"
                [selectedValue]="initialInterval"
                (onChange)="onChangeInterval($event)"
                (onHide)="onIntervalHide()"
                [disabled]="disabledState || !isEditor"
                [appendTo]="'body'"
              >
              </u-dropdown>
            </div>
          </div>
        </ng-scrollbar>
      </div>

      <div class="verification-footer" *ngIf="model?.type === 'card'">
        <u-button
          id="request"
          class="secondary-button"
          type="secondary"
          [fontIcon]="requestLabel === 'Requested' ? 'check' : null"
          [disabled]="requestLabel === 'Requested'"
          [label]="requestLabel"
          [styles]="{ width: '170px', height: '32px' }"
          (click)="onRequestClick()"
        >
        </u-button>
      </div>
    </ng-container>

    <ng-template #loading>
      <loader-overlay class="popup-loading"> </loader-overlay>
    </ng-template>
  </div>
</div>
