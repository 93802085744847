import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chats, Experiences, Filters, Search } from '@local/client-contracts';
import { observable } from '@local/common';
import { generateId, getAssistantDescription, getAssistantTitle, isEmbed, isGeneralAssistant } from '@local/common-web';
import { LogService, ServicesRpcService, WindowService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isEmpty } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, filter, firstValueFrom, map } from 'rxjs';
import { AvatarItemModel } from 'src/app/bar/models/avatar-item.model';
import { WorkspacesService } from 'src/app/bar/services';
import { ChatAssistantsService } from 'src/app/bar/services/chat-assistants.service';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { FiltersService } from 'src/app/bar/services/filters.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { ChatsRpcInvoker } from 'src/app/bar/services/invokers/chats.rpc-invoker';
import { CHAT_PAGE_PATH } from 'src/app/bar/utils/constants';
import { AnswerSearchItem } from '../../results';
import { AssistantChatData, ChatErrorState, CurrentChatData, NEW_CHAT_ID } from '../model';
import { ChatResourcesService } from './chat-resources.service';

const QUESTION_DRAFT_QUERY = 'q-query';
const IS_GLOBAL_ASSISTANT = 'global';

@Injectable()
export class ChatsService {
  private readonly TEMP_CHAT_SESSION_STORAGE_KEY = 'temp_chat_session';
  private logger: Logger;
  private service: Chats.Service;
  private _all$ = new ReplaySubject<Chats.ChatSessionsByAssistant>(1);
  private _currentChat$ = new BehaviorSubject<CurrentChatData>(null);
  private isEmbed = isEmbed();
  private localTempChat: Chats.Chat;
  private _assistantsForChat$ = new ReplaySubject<{ [key: string]: AssistantChatData }>(1);
  private _chatErrorState$ = new BehaviorSubject<ChatErrorState>(null);

  @observable
  get assistantsForChat$(): Observable<{ [key: string]: AssistantChatData }> {
    return this._assistantsForChat$;
  }

  @observable
  get currentChat$(): Observable<CurrentChatData> {
    return this._currentChat$;
  }

  @observable
  get currentAssistant$(): Observable<AssistantChatData> {
    return this._currentChat$.pipe(
      filter((chat) => !!chat),
      map((chatData) => chatData.assistant)
    );
  }

  @observable
  get chatErrorState$(): Observable<ChatErrorState> {
    return this._chatErrorState$.asObservable();
  }

  set chatErrorState(state: ChatErrorState) {
    this._chatErrorState$.next(state);
  }

  private set currentChat(chat: CurrentChatData) {
    this._currentChat$.next(chat);
  }

  private get currentChat(): CurrentChatData {
    return this._currentChat$.value;
  }

  @observable
  private get all$(): Observable<Chats.ChatSessionsByAssistant> {
    return this._all$.asObservable();
  }

  constructor(
    services: ServicesRpcService,
    logger: LogService,
    private routerService: RouterService,
    private windowService: WindowService,
    private filtersService: FiltersService,
    private hubService: HubService,
    private chatResourcesService: ChatResourcesService,
    private experiencesService: ExperiencesService,
    private workspaceService: WorkspacesService,
    private chatAssistantsService: ChatAssistantsService
  ) {
    this.logger = logger.scope('ChatsService');
    this.service = services.invokeWith(ChatsRpcInvoker, 'chats');
    this.service.all$.subscribe((all) => {
      this._all$.next(all);
    });
    this.initAssistantsData();
    this.handleChatRoute();
  }

  private createAssistantChatData(item: Experiences.ExperienceItem): AssistantChatData {
    if (!isGeneralAssistant(item)) {
      return;
    }
    return {
      id: item.id,
      emoji: item.settings?.emoji,
      name: getAssistantTitle(item),
      lastModifyInfo: this.getLastModifyInfo(item),
      description: getAssistantDescription(item),
      canAccess: ['creator', 'editor', 'viewer'].includes(item?.permissionRole),
      icon: !item.settings?.emoji ? { type: 'font', value: 'icon-assistant' } : null,
      knowledgeType: item.settings?.general?.knowledgeType,
      model: item.settings?.model,
      conversationStarterMessages: item.settings?.general?.conversationStarterMessages,
    };
  }

  private initAssistantsData() {
    this.chatAssistantsService.assistantForChat$.subscribe(async (items) => {
      const assistants: { [key: string]: AssistantChatData } = {};
      items.forEach((item) => {
        assistants[item.id] = this.createAssistantChatData(item);
      });
      const defaultAssistant = await this.getWorkspaceAssistantData();
      assistants[defaultAssistant.id] = defaultAssistant;
      this._assistantsForChat$.next(assistants);
    });
  }

  private async getWorkspaceAssistantData(): Promise<AssistantChatData> {
    const workspace = await firstValueFrom(this.workspaceService.current$);
    const icon = this.workspaceService.getLogo();
    return {
      id: undefined,
      name: workspace?.name,
      icon: { type: 'img', value: icon },
      description: 'Chat and ask questions to uncover everything you want to know from your company’s knowledge base',
      isDefault: true,
    };
  }
  getLastModifyInfo(assistant: Experiences.ExperienceItem): { avatar: AvatarItemModel; description: string } {
    const avatar = this.experiencesService.getLastModified(assistant as Experiences.ExperienceItem);
    const description = assistant.createdAt === assistant.modifiedAt ? 'Created by' : 'Modified by';
    return {
      avatar,
      description,
    };
  }
  async goToChatPageWithHistory(answerItem: AnswerSearchItem, isGlobalAssistant?: boolean) {
    const { query, assistantId } = answerItem;
    const timestamp = Date.now();
    const answer: Chats.AssistantMessage = this.createMessageFromAnswer(answerItem, timestamp);
    const currentFilters: Filters.Values = this.filtersService.allFilters;
    const filtersToQuestion: Filters.Values = await this.filtersService.transformFiltersForDisplay(currentFilters);
    const question = {
      content: query,
      filters: filtersToQuestion,
      timestamp,
    };
    const chatId = generateId();
    const currentChat = await this.findChatByAssistant(assistantId);
    const currentChatId = currentChat?.id;
    const historyItem: Chats.ChatHistoryItem = {
      chatId,
      userMessage: question,
      assistantMessage: answer,
    };
    this.createSession(assistantId, chatId, currentChatId).then(() => {
      this.createHistoryMessage(historyItem);
    });
    const tempChatSession: Chats.Chat = { id: chatId, assistantId, chatHistory: [historyItem] };
    const isLauncher = await this.hubService.getIsLauncher();
    if (isLauncher) {
      localStorage.setItem(this.TEMP_CHAT_SESSION_STORAGE_KEY, JSON.stringify(tempChatSession));
    } else {
      this.localTempChat = tempChatSession;
    }
    this.chatResourcesService.updateResourceInCache(answerItem.resources);
    this.openChat(assistantId, null, isGlobalAssistant);
  }

  async openChat(assistantId?: string, query?: string, isGlobalAssistant?: boolean) {
    let chatUrl = `/${CHAT_PAGE_PATH}`;
    if (assistantId) {
      chatUrl += `/${assistantId}`;
    }
    if (query) {
      chatUrl += `?${QUESTION_DRAFT_QUERY}=${query}`;
    }
    //HACK: Add a flag to the URL to indicate that the global assistant is in use.
    if (isGlobalAssistant) {
      chatUrl += `?${IS_GLOBAL_ASSISTANT}=${true}`;
    }
    const currentFilters = this.filtersService.allFilters;
    if (!isEmpty(currentFilters)) {
      const filtersUrl = this.filtersService.getFiltersAsUrlParams(currentFilters);
      chatUrl += `?${filtersUrl}`;
    }
    const isLauncher = await this.hubService.getIsLauncher();
    if (this.isEmbed) {
      if (isLauncher) {
        return this.hubService.openStandardEmbed(chatUrl, true);
      }
      return this.routerService.navigateByUrl(chatUrl, { replaceUrl: true });
    }
    if (isLauncher) {
      return this.windowService.switchToStandard(chatUrl);
    }
    return this.routerService.navigateByUrl(chatUrl);
  }

  createSession(assistantId?: string, chatId?: string, currentChatId?: string): Promise<void> {
    return this.service.createSession(assistantId, chatId, currentChatId);
  }

  createHistoryMessage(historyItem: Chats.ChatHistoryItem) {
    this.service.createHistoryItem(historyItem);
  }

  convertToAnswerResources(resources: Search.ResultResourceItem[]): Chats.MessageResource[] {
    const updatedResources: Chats.MessageResource[] = (resources || []).map((r) => ({
      appId: r.resource?.appId,
      externalId: r.resource?.externalId,
      resourceId: r.resource?.id,
    }));
    return updatedResources;
  }

  generateEmptyChat(assistantId?: string): Chats.Chat {
    return { id: NEW_CHAT_ID, chatHistory: [], assistantId };
  }

  private handleChatRoute() {
    this.routerService.activeRoute$
      .pipe(
        filter((currentRoute: ActivatedRoute) => {
          return !this.shouldResetChat(currentRoute);
        })
      )
      .subscribe(async (currentRoute) => {
        this.chatErrorState = null;
        const assistantId = currentRoute?.snapshot?.params?.id;
        const draftQuery = currentRoute?.snapshot?.queryParams?.[QUESTION_DRAFT_QUERY];
        const isGlobalAssistant = currentRoute?.snapshot?.queryParams?.[IS_GLOBAL_ASSISTANT];
        const chatData: CurrentChatData = await this.initChatData(assistantId, draftQuery);
        if (chatData?.assistant) {
          this.updateCurrentChatData(chatData, isGlobalAssistant);
        } else {
          await this.handleMissingAssistantData(assistantId, chatData, isGlobalAssistant);
        }
      });
  }
  private async checkAssistantsData(assistantId: string) {
    const assistants = await firstValueFrom(this.experiencesService.all$); //without filter
    if (assistants?.length) {
      const foundAssistant = assistants.find((a) => a.id === assistantId);
      if (foundAssistant) {
        this.chatErrorState = 'no-access';
        return true;
      }
      return false;
    }
  }
  private async handleMissingAssistantData(assistantId: string, chatData: CurrentChatData, isGlobalAssistant?: boolean) {
    if (isGlobalAssistant) {
      // For global assistant: Allow using all general assistants, even if toggles are off.
      const foundAssistant = await this.experiencesService.getExperience(assistantId);
      if (foundAssistant && foundAssistant.experienceType === 'general') {
        this.updateChatDataWithFoundAssistant(chatData, foundAssistant, true);
      } else {
        this.chatErrorState = 'no-access';
      }
      return;
    }
    if (!chatData?.assistant) {
      const hasErrorState = await this.checkAssistantsData(assistantId);
      if (hasErrorState) {
        return;
      }
      this.chatErrorState = 'loading';
      this.getAssistantDataById(assistantId, chatData);
    }
  }

  private async getAssistantDataById(assistantId: string, chatData: CurrentChatData) {
    try {
      const foundAssistant = await this.experiencesService.getExperience(assistantId, 'skip');
      if (foundAssistant && this.chatAssistantsService.isValidAssistantForChat(foundAssistant)) {
        this.updateChatDataWithFoundAssistant(chatData, foundAssistant);
        this.chatErrorState = null;
        return;
      } else {
        this.chatErrorState = 'no-access';
        return;
      }
    } catch (error) {
      this.handleErrorAssistantResponse(error.status);
    }
  }
  private handleErrorAssistantResponse(status: number) {
    if (status === 404) {
      this.chatErrorState = 'not-found';
      return;
    }
    if (status === 403) {
      this.chatErrorState = 'no-access';
      return;
    }
  }

  private updateChatDataWithFoundAssistant(
    chatData: CurrentChatData,
    foundAssistant: Experiences.ExperienceItem,
    isGlobalAssistant?: boolean
  ) {
    chatData.assistant = this.createAssistantChatData(foundAssistant);
    this.updateCurrentChatData(chatData, isGlobalAssistant);
  }

  private updateCurrentChatData(chatData: CurrentChatData, isGlobalAssistant?: boolean) {
    if (chatData) {
      const currentChat = this.currentChat;
      const chatChanged =
        !currentChat ||
        currentChat.assistant?.id != chatData.assistant?.id ||
        (chatData?.chatSession?.id !== NEW_CHAT_ID && currentChat?.chatSession?.id != chatData?.chatSession?.id);
      if (chatChanged) {
        this.currentChat = { ...chatData, assistant: { ...chatData.assistant, isGlobalAssistant } };
        this.updateAnswersResources(chatData.latestChatSession || chatData.chatSession);
      }
    }
  }

  private async initChatData(assistantId?: string, draftQuery?: string): Promise<CurrentChatData> {
    const tempChatSession = this.getTempChatSession(assistantId);
    const chatSession = cloneDeep(tempChatSession) || this.generateEmptyChat(assistantId);
    const chatData: CurrentChatData = { chatSession };
    if (draftQuery) {
      chatData.draftQuery = draftQuery;
      this.routerService.removeQueryParam([QUESTION_DRAFT_QUERY], true);
    }
    if (!tempChatSession) {
      const latestChatSession = await this.findChatByAssistant(assistantId);
      chatData.latestChatSession = latestChatSession;
    }
    const assistants = await firstValueFrom(this.assistantsForChat$);
    const assistant = assistants?.[assistantId || undefined];
    chatData.assistant = assistant;
    return chatData;
  }

  private getTempChatSession(assistantId?: string) {
    if (this.localTempChat && this.localTempChat.assistantId == assistantId) {
      const localTempChat = this.localTempChat;
      this.localTempChat = null;
      return localTempChat;
    }
    const tempChatFromStorage = this.extractSessionFromStorage();
    if (!tempChatFromStorage || tempChatFromStorage.assistantId != assistantId) {
      return;
    }
    localStorage.removeItem(this.TEMP_CHAT_SESSION_STORAGE_KEY);
    return tempChatFromStorage;
  }

  private extractSessionFromStorage() {
    try {
      const storage = localStorage.getItem(this.TEMP_CHAT_SESSION_STORAGE_KEY);
      if (!storage) {
        return;
      }
      return JSON.parse(storage) as Chats.Chat;
    } catch (error) {
      return;
    }
  }

  private shouldResetChat(currentRoute: ActivatedRoute): boolean {
    const currentPage = currentRoute?.snapshot?.data.id;
    const resetChat = currentPage !== CHAT_PAGE_PATH;
    if (resetChat) {
      this.currentChat = null;
      this.localTempChat = null;
      this.chatErrorState = null;
    }
    return resetChat;
  }

  private createMessageFromAnswer(answerItem: AnswerSearchItem, timestamp: number): Chats.AssistantMessage {
    const { state, text, searchId, resources, intent } = answerItem;
    if (state === 'NoResults') {
      return { state: Chats.AssistantMessageState.NoResultsFound, timestamp, intent };
    }
    const updatedResources = this.convertToAnswerResources(resources);
    const answer: Chats.AssistantMessage = {
      content: text,
      state: state === 'RephraseRequired' ? Chats.AssistantMessageState.RephraseRequired : Chats.AssistantMessageState.ResultsAvailable,
      resources: updatedResources,
      searchId,
      timestamp,
      references: answerItem.references,
    };
    if (intent) {
      answer.intent = intent;
    }
    return answer;
  }

  private async findChatByAssistant(assistantId?: string): Promise<Chats.Chat> {
    const chats = await firstValueFrom(this.all$);
    const chat = chats[assistantId || undefined];
    return chat;
  }

  private updateAnswersResources(currentChat: Chats.Chat) {
    const externalResources = (currentChat?.chatHistory || [])
      .map((h) => h?.assistantMessage?.resources?.map((r) => r?.externalId) || [])
      .filter((r) => !!r)
      .flat();
    this.chatResourcesService.updateRemoteResources(externalResources);
  }
}
