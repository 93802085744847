import { Config } from '@environments/config';
import { Omnibox } from '@local/client-contracts';
import { Constants } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { ContextMenuItem } from '@shared/components';
import { ClickTrackDirective } from '@shared/directives/track.directive';
import { WorkspaceInviteComponent } from 'src/app/onboarding-web/containers/workspace-invite/workspace-invite.component';

export type MenuItemType = 'primary' | 'sub';

export interface MenuItem extends Omnibox.Suggestion {
  page: string;
  clickTrack: ClickTrackDirective['clickTrack'];
  hidden?: boolean;
  children?: MenuItem[];
  externalLink?: string;
  displayOrder: MenuItemOrder;
  shortcuts?: string[][];
}

export const enum MenuItemOrder {
  Page = 1,
  Web = 2,
}

const WEB_SEARCH_FOR_NATIVE: MenuItem[] = [
  {
    id: 'web-search_bing',
    title: 'Bing',
    page: `web-search/bing`,
    clickTrack: 'web-search.bing',
    icon: { type: 'img', value: { lightUrl: '/assets/bar/web-search/bing.svg' } },
    type: 'primary',
    label: 'web',
    displayOrder: MenuItemOrder.Web,
  },
  {
    id: 'web-search_duckduckgo',
    title: 'DuckDuckGo',
    page: `web-search/duckduckgo`,
    clickTrack: 'web-search.duckduckgo',
    icon: { type: 'img', value: { lightUrl: '/assets/bar/web-search/duckduckgo.svg' } },
    type: 'primary',
    label: 'web',
    displayOrder: MenuItemOrder.Web,
  },
  {
    id: 'web-search_google',
    title: 'Google',
    page: `web-search/google`,
    clickTrack: 'web-search.google',
    icon: { type: 'img', value: { lightUrl: '/assets/bar/web-search/google.svg' } },
    type: 'primary',
    label: 'web',
    displayOrder: MenuItemOrder.Web,
  },
  {
    id: 'web-search_wikipedia',
    title: 'Wikipedia',
    page: `web-search/wikipedia`,
    clickTrack: 'web-search.wikipedia',
    icon: { type: 'img', value: { lightUrl: '/assets/bar/web-search/wikipedia.svg' } },
    type: 'primary',
    label: 'web',
    displayOrder: MenuItemOrder.Web,
  },
];

const WEB_SEARCH_FOR_WEB: MenuItem[] = [];

export const WEB_SEARCH_ITEMS: MenuItem[] = isNativeWindow() ? WEB_SEARCH_FOR_NATIVE : WEB_SEARCH_FOR_WEB;

export interface ActionItem {
  event?: 'quit' | 'version';
  iconName: string;
  tooltipText?: string;
  clickTrack: ClickTrackDirective['clickTrack'];
}

export const HELP_MENU_ITEMS: ContextMenuItem[] = [
  {
    id: 'guide',
    text: 'Help Center',
    icon: { type: 'font-icon', value: 'icon-book2' },
    data: {
      url: Constants.GUIDE_URL,
    },
  },
  {
    id: 'invite',
    text: 'Invite Teammates',
    icon: { type: 'font-icon', value: 'icon-people-group' },
    data: {
      popup: WorkspaceInviteComponent,
    },
    disabled: !!Config.customDomain,
  },
  {
    id: 'release-notes',
    text: 'Release Notes',
    icon: { type: 'font-icon', value: 'font-icon icon-gift' },

    data: {
      url: Constants.getReleaseNoteURL(Config.version, Config.autoUpdate.ignoreBannerVersions),
    },
  },
];

export const BOTTOM_MENU_ITEMS: MenuItem[] = [];
