import { EmailInfo, InviteRequestInfo, SamlInfo, SessionInfo, Signin, User, Workspace } from '@local/client-contracts';
import { KeyValueStorageEntry, StoreLocation, StoreScope } from '@local/common';
import { EmailLoginStatus, EmbedSignInRequest, Session, SignInResponse } from '@local/common-web';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class SessionRpcInvoker implements Session {
  @invoker
  emailLogin(email: string, inviteCode?: string): Promise<EmailLoginStatus> {
    return;
  }

  @invoker
  updateUserInfo(name: string): Promise<void> {
    return;
  }
  @invoker
  updateWorkspace(w: Workspace.Workspace): Promise<void> {
    return;
  }
  @invoker
  entries(loc: StoreLocation, scope: StoreScope, namespace?: string): Promise<KeyValueStorageEntry[]> {
    return;
  }

  @invoker
  embedSignIn(r: EmbedSignInRequest): Promise<void> {
    return;
  }

  @invoker
  keys(loc: StoreLocation, scope: StoreScope, namespace?: string): Promise<string[]> {
    return;
  }

  @invoker
  activateUser(): Promise<void> {
    return;
  }

  @invoker
  async signalOnboardingDone() {
    return;
  }

  @invoker
  get deviceId$(): Observable<string> {
    return;
  }

  @invoker
  checkSaml(email: string, redirectUrl: string, state: string): Promise<SamlInfo> {
    return;
  }

  @invoker
  email(email: string, redirectUrl: string, state: string): Promise<EmailInfo> {
    return;
  }

  @invoker
  inviteEmailRequest(email: string): Promise<InviteRequestInfo> {
    return;
  }

  @invoker
  signInOAuth(request: Signin.SignInRequest): Promise<SignInResponse> {
    return;
  }

  @invoker
  getDeviceId(): Promise<string> {
    return;
  }

  @invoker
  verifyNonce(non: string): Promise<void> {
    return;
  }

  @invoker
  getNativeNonce(): Promise<string> {
    return;
  }

  @invoker
  inject(s: SessionInfo): Promise<void> {
    return;
  }

  @invoker
  get current$(): Observable<SessionInfo> {
    return;
  }

  @invoker
  get accountChanged$(): Observable<SessionInfo> {
    return;
  }

  @invoker
  syncApp(target: 'desktop'): Promise<void> {
    return;
  }

  @invoker
  setTaid(taid: { value: string; source: string }): Promise<void> {
    return;
  }

  @invoker
  deleteAccount(): Promise<void> {
    return;
  }

  @invoker
  signOut(): Promise<void> {
    return;
  }

  @invoker
  refreshWorkspace(): Promise<void> {
    return;
  }

  @invoker
  fork(target: 'desktop' | 'web'): Promise<SessionInfo & { nonce: string }> {
    return;
  }

  @invoker
  getMyUser(): Promise<User.Info> {
    return;
  }
}
