import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GoLinks, Permissions } from '@local/client-contracts';
import { PopupRef, PopupService, UAutoCompleteComponent, UiIconModel, UInputComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService, LogService } from '@shared/services';
import { AppService } from '@shared/services/app.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { windowSizeObserver } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, escape, isEmpty, isString } from 'lodash';
import { AppPopupComponent, AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';
import { HubService } from 'src/app/bar/services/hub.service';
import { UrlResolverService } from 'src/app/bar/services/url-resolver.service';
import { GoLinksPopupModel, GoLinksService, hasVariable } from '../../../../services/go-links.service';
import { ErrorGoLinksConstants } from './error.const';
import { KeyName } from '@local/ts-infra';
import { AvatarListService } from 'src/app/bar/services/avatar-list.service';
import { AvatarItemModel } from 'src/app/bar/models/avatar-item.model';
import { take } from 'rxjs';

export interface GoLinkViewModel {
  url: string;
  name: string;
  description: string;
  tags: any[];
  listed: boolean;
  iconLink: string;
}

export interface AutoCompleteViewModel {
  name: string;
  description: string;
}

export interface ErrorsViewModel {
  errorUrl: string;
  errorName: string;
  errorExistsUrls: GoLinks.SearchItem[];
  errorExistsName: GoLinks.SearchItem;
  errorExistsNameCreatedByYou: boolean;
}

export type keyEvent = 'errors' | 'action';

@UntilDestroy()
@Component({
  selector: 'go-links-popup',
  templateUrl: './go-links-popup.component.html',
  styleUrls: ['./go-links-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoLinksPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  editGoLink = false;
  viewModel: GoLinkViewModel;
  tagList: Array<AutoCompleteViewModel> = [];
  errors: ErrorsViewModel;
  focusName: boolean;
  isLauncher: boolean;
  disabledButton = true;
  inputTextAutoComplete: string;
  loadingButton = false;
  closedPopup = true;
  smallScreenLauncher = true;
  firstClick = true;
  withVariable: boolean;
  sharedAvatarsList: AvatarItemModel[] = [];
  private id: string;
  private goLinkResource: GoLinks.GetByIdResponse;
  private goLinkResultItem: GoLinks.SearchItem;
  private lastMetaDataUrl = '';
  private keyHandlerId: string;
  private errorsPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  private logger: Logger;
  @ViewChild('errorsPopupContent') errorsPopupContent: ElementRef;

  @ViewChild('inputUrl') inputUrl: UInputComponent;
  @ViewChild('autoComplete') autoComplete: UAutoCompleteComponent;

  readonly unlistedTooltip =
    'Unlisted Go Links are hidden from users without Edit permissions but remain accessible to anyone who knows the link.';

  readonly closeIcon: UiIconModel = {
    type: 'font',
    value: 'icon-Windows-close',
  };

  readonly closeIconLauncher: UiIconModel = {
    type: 'font',
    value: 'icon-arrow-left-key',
  };

  constructor(
    private ref: PopupRef<GoLinksPopupComponent, GoLinksPopupModel>,
    private cdr: ChangeDetectorRef,
    private goLinksService: GoLinksService,
    private hubService: HubService,
    private appService: AppService,
    private eventsService: EventsService,
    private keyboardService: KeyboardService,
    private popupService: PopupService,
    private routerService: RouterService,
    private urlResolverService: UrlResolverService,
    private avatarListService: AvatarListService,
    logger: LogService
  ) {
    this.logger = logger.scope('GoLinksPopupComponent');
    this.appService.windowStyle$.pipe(untilDestroyed(this)).subscribe((b) => {
      this.isLauncher = b != 'standard';
    });
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys: Array<KeyName>, event) => this.handleKeys(keys, event), 9);
  }

  ngOnInit(): void {
    this.initData();

    windowSizeObserver().subscribe((value) => {
      this.smallScreenLauncher = this.isLauncher && value.height < 505;
      this.cdr.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.editGoLink || this.ref.data?.name) {
        this.focusName = true;
      } else {
        this.focusName = false;
        this.inputUrl?.inputElement?.el.nativeElement.focus();
      }
      this.cdr.markForCheck();
    }, 0);
  }

  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  initData(): void {
    this.initTags();

    this.errors = {
      errorExistsName: null,
      errorExistsNameCreatedByYou: false,
      errorExistsUrls: null,
      errorName: '',
      errorUrl: '',
    };

    const inputData: GoLinksPopupModel = this.ref.data;

    if (inputData?.item) {
      this.editGoLink = true;
      this.id = inputData.item?.id;
      this.initItem(inputData.item.data);
      return;
    }

    this.updateViewModel(null, inputData?.url, inputData?.name);
  }

  initItem(item: GoLinks.SearchItem): void {
    this.goLinksService.getById(item.name).then(async (value) => {
      this.updateViewModel({ ...value, name: item.name });
      if (item?.shareOptions) {
        this.sharedAvatarsList = await this.createAvatarList(item.createdBy, item.shareOptions);
      }
      this.goLinkResultItem = item;
    });
    return;
  }

  private createAvatarList(createdBy: string, shareOptions: Permissions.ShareOptions) {
    if (!this.viewModel.listed) {
      const readScope = shareOptions.permissions.find((p) => p.scope === 'read');
      if (readScope) {
        readScope.workspaceIds = [];
      }
    }
    return this.avatarListService.createAvatarList(createdBy, shareOptions);
  }

  initTags(): void {
    this.goLinksService.getTagList().then((list) => {
      this.tagList = list.map((tag) => {
        return { name: tag.text, description: `${tag.count} links` };
      });
      this.cdr.markForCheck();
    });
  }

  updateViewModel(goLink?: GoLinks.GetByIdResponse, url = '', name = ''): void {
    this.viewModel = {
      url: goLink?.url || url,
      name: goLink?.name || name,
      description: goLink?.description || '',
      tags:
        goLink?.tags.map((tag) => {
          return { name: tag };
        }) || [],
      listed: goLink?.listed != false,
      iconLink: goLink?.iconLink,
    };
    this.withVariable = hasVariable(this.viewModel.url);
    this.goLinkResource = goLink;
    this.cdr.markForCheck();
  }

  private get isEmptyPopup(): boolean {
    return (
      this.viewModel.description === '' &&
      this.viewModel.url === '' &&
      this.viewModel.name === '' &&
      this.viewModel.tags.length === 0 &&
      this.viewModel.listed === true
    );
  }

  private get notErrorsPopup(): boolean {
    return (
      !this.errors.errorExistsName &&
      !this.errors.errorExistsNameCreatedByYou &&
      !this.errors.errorExistsUrls &&
      !this.errors.errorName &&
      !this.errors.errorUrl
    );
  }

  async saveGoLink(): Promise<void> {
    if (this.disabledButton) return;
    this.loadingButton = true;
    this.cdr.markForCheck();
    this.sendImpressionEvent('save');
    if (this.editGoLink) {
      if (this.goLinksService.isDefaultGoLinkIcon(this.viewModel.iconLink)) {
        this.viewModel.iconLink = undefined;
      }
      const updated = await this.goLinksService.update(
        this.convertUpdateRequest(this.goLinkResource),
        this.convertUpdateRequest(this.viewModel)
      );
      if (!updated) {
        this.openErrorsPopup();
      }
      this.loadingButton = false;
      this.cdr.markForCheck();
    } else {
      const created = await this.goLinksService.create(this.viewModel);
      if (!created) {
        this.openErrorsPopup();
      } else {
        setTimeout(async () => {
          this.goLinksService.closePopup();
          if (await this.hubService.getIsLauncher()) {
            this.hubService.addActivePage('golinks');
          } else {
            this.onShareClick(created);
            this.routerService.navigateByUrl('golinks');
          }
          this.goLinksService.newGoLink = this.viewModel.name;
          this.loadingButton = false;
          this.cdr.markForCheck();
        }, 500);
      }
    }
  }

  convertUpdateRequest(item: GoLinkViewModel | GoLinks.GetByIdResponse): GoLinks.UpdateRequest {
    return {
      id: this.id,
      lastModifiedTime: this.goLinkResource?.modifiedTime,
      description: escape(item?.description),
      url: item?.url,
      name: item?.name,
      tags: item?.tags?.map((tag) => (isString(tag) ? tag : tag.name)),
      listed: item?.listed,
      iconLink: item?.iconLink,
      shareOptions: this.goLinkResultItem?.shareOptions,
    };
  }

  onContainerClick() {
    this.closedPopup = false;
  }

  closePopup(event, target?: string): void {
    if (this.firstClick) {
      this.firstClick = false;
      if (event.target.className === 'go-links-popup-container' || event.target?.['parentElement']?.id === 'cancel') {
        this.closedPopup = true;
      }
    }
    if (!this.closedPopup) {
      this.closedPopup = true;
      return;
    }
    if (target) {
      this.sendImpressionEvent(target);
    } else {
      this.sendImpressionEvent('close');
    }
    if (!this.isUpdatedPopup()) {
      this.goLinksService.closePopup();
      return;
    }
    this.goLinksService.openWarningPopup();
  }

  private isUpdatedPopup(): boolean {
    if (!this.editGoLink) return !this.isEmptyPopup;
    const updated = this.goLinksService.isEqual(this.convertUpdateRequest(this.goLinkResource), this.convertUpdateRequest(this.viewModel));
    return !isEmpty(updated);
  }

  private openErrorsPopup() {
    if (this.errorsPopupRef) {
      this.errorsPopupRef.destroy();
    }
    this.errorsPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        showButtons: false,
        templateContent: this.errorsPopupContent,
      },
      { position: 'center' }
    );
  }

  closeErrorsPopup() {
    this.errorsPopupRef.destroy();
    this.errorsPopupRef = null;
  }

  onUrlChange($event: any): void {
    this.viewModel.url = $event;
    if ($event === '') {
      this.inputUrl?.inputElement?.el.nativeElement.focus();
    }
    this.withVariable = hasVariable(this.viewModel.url);
  }

  onNameChange($event: any): void {
    this.viewModel.name = $event;
    if ($event === '') {
      this.focusName = true;
      this.cdr.markForCheck();
    }
  }

  onDescriptionChange($event): void {
    this.viewModel.description = $event;
    this.resetDisabledButton();
  }

  onClickUnlisted(event: PointerEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.viewModel.listed = !this.viewModel.listed;
    this.resetDisabledButton();
    this.sendImpressionEvent('unlisted_golink');
  }

  onTagsChange($event): void {
    this.viewModel.tags = $event;
    this.resetDisabledButton();
  }

  resetDisabledButton() {
    if (this.isUpdatedPopup() && this.viewModel.url && this.viewModel.name) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
  }

  queryChangesAutoComplete(event): void {
    typeof event === 'string' ? (this.inputTextAutoComplete = event) : (this.inputTextAutoComplete = event.query);
  }

  displayCreateNew(): boolean {
    const existsTag: AutoCompleteViewModel = this.tagList.find((tag) => {
      return tag.name === this.inputTextAutoComplete;
    });
    return this.inputTextAutoComplete && !existsTag;
  }

  createNewTag(): void {
    this.viewModel.tags = this.viewModel.tags.filter((tag) => tag.name !== this.inputTextAutoComplete);
    this.viewModel.tags.push({ name: this.inputTextAutoComplete });
    this.inputTextAutoComplete = '';
    this.autoComplete?.autoComplete?.el?.nativeElement?.children[0]?.children[0]?.lastChild?.firstChild?.focus();
    this.autoComplete?.autoComplete?.hide();
    this.resetDisabledButton();
  }

  onBlurTags(event): void {
    if (event.target.value) {
      event.target.value = '';
    }
  }

  onFocus(target): void {
    this.sendImpressionEvent(target);
  }

  onClearUrl() {
    this.viewModel.url = null;
    this.withVariable = false;
    this.errors.errorUrl = ErrorGoLinksConstants.EMPTY_URL;
    this.errors.errorExistsUrls = null;
    this.disabledButton = true;
    this.cdr.markForCheck();
    this.sendImpressionEventError('enter_url');
  }

  async onBlurUrl() {
    if (!this.viewModel.url) {
      this.onClearUrl();
      return;
    }
    this.viewModel.url =
      this.viewModel.url.startsWith('http://') || this.viewModel.url.startsWith('https://')
        ? this.viewModel.url
        : `https://${this.viewModel.url}`;

    if ((await this.isValidUrl()) && this.isUpdatedPopup() && this.viewModel.name) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }

    await this.handleAlreadyExistsUrl();
    try {
      const host = new URL(this.viewModel.url).origin;
      if (this.lastMetaDataUrl !== host) {
        const data = await this.urlResolverService.resolveSite(this.viewModel.url);

        this.viewModel.iconLink = data?.icon || this.viewModel.iconLink;
        this.lastMetaDataUrl = host;
        this.viewModel.description = data?.description || this.viewModel.description;
      }
      this.cdr.markForCheck();
    } catch (error) {
      this.logger.error('got error on blur url', error);
    }
  }

  private async isValidUrl(): Promise<boolean> {
    const url = this.viewModel.url;
    this.errors.errorUrl = '';
    this.errors.errorExistsUrls = undefined;
    if (!this.isValidPatternUrl(url)) {
      this.errors.errorUrl = ErrorGoLinksConstants.INVALID_URL;
      this.sendImpressionEventError('invalid_url');
      return false;
    }
    return true;
  }

  private isValidPatternUrl(url: string): boolean {
    const pattern = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gy);
    return !!pattern.test(url);
  }

  private async handleAlreadyExistsUrl(): Promise<boolean> {
    const existUrls = await this.goLinksService.validateField('url', this.viewModel.url);
    if (!existUrls?.length || existUrls?.some((i) => i.id === this.id)) {
      return;
    }

    this.errors.errorExistsUrls = existUrls;
    let errorUrl;
    if (existUrls.length === 1) {
      errorUrl = ErrorGoLinksConstants.EXISTS_URL_SINGLE;
    } else {
      errorUrl = ErrorGoLinksConstants.EXISTS_URL_MULTIPLE;
    }
    this.errors.errorUrl = errorUrl;

    this.sendImpressionEventError('url_already_exist');
  }

  async onBlurName() {
    this.focusName = false;
    this.errors.errorName = '';
    this.errors.errorExistsName = undefined;
    this.errors.errorExistsNameCreatedByYou = false;

    this.viewModel.name = this.viewModel.name.toLowerCase();
    const name = this.viewModel.name;
    if (!name) {
      this.errors.errorName = ErrorGoLinksConstants.EMPTY_NAME;
      this.disabledButton = true;
      this.cdr.markForCheck();
      this.sendImpressionEventError('enter_link_shortcut');
      return;
    }

    if (!name.match('^[a-z0-9_-]+$')) {
      this.errors.errorName = ErrorGoLinksConstants.INVALID_NAME;
      this.disabledButton = true;
      this.cdr.markForCheck();
      this.sendImpressionEventError('invalid_link_shortcut');
      return;
    }

    if ((await this.validateExistName()) && this.isUpdatedPopup() && this.viewModel.url) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
    this.cdr.markForCheck();
  }

  private async validateExistName(): Promise<boolean> {
    const existNames = await this.goLinksService.validateField('name', this.viewModel.name);
    const existName = existNames?.find((n) => n.name === this.viewModel.name);
    if (!existName || (existName && existName.id === this.id)) {
      return true;
    }

    this.sendImpressionEventError('link_shortcut_already_exist');
    if (this.goLinksService.canEditOrDelete(existName)) {
      this.errors.errorName = ErrorGoLinksConstants.EXISTS_NAME_CREATED_BY_YOU;
      this.errors.errorExistsNameCreatedByYou = true;
      this.errors.errorExistsName = existName;
      return false;
    }
    if (this.goLinksService.canViewGoLink(existName)) {
      this.errors.errorName = ErrorGoLinksConstants.EXISTS_NAME_LISTED;
      this.errors.errorExistsName = existName;
      return false;
    }
    this.errors.errorName = ErrorGoLinksConstants.EXISTS_NAME_UNLISTED;
    return false;
  }

  existsUrl(): void {
    const name = `go/${this.errors.errorExistsUrls?.[0]?.name}`;
    this.goLinksService.closePopup();
    if (this.hubService.currentLocation === 'home') {
      this.routerService.navigateByUrl(`/search?q=${name}`);
    } else {
      this.hubService.query = name;
    }
  }

  existsName(): void {
    this.hubService.query = this.errors.errorExistsName.name;
    this.goLinksService.closePopup();
    if (this.hubService.currentLocation === 'home') {
      this.routerService.navigateByUrl(`/search?q=${this.errors.errorExistsName.name}`);
    }
  }

  async existsNameCreatedByYou(): Promise<void> {
    this.editGoLink = true;
    const item = await this.goLinksService.validateField('name', this.errors.errorExistsName.name)?.[0];
    this.id = item?.id;
    this.initItem(this.errors.errorExistsName);
    this.errors = {
      errorExistsName: null,
      errorExistsNameCreatedByYou: false,
      errorExistsUrls: null,
      errorName: '',
      errorUrl: '',
    };
  }

  private sendImpressionEvent(value: string): void {
    this.eventsService.event('go_links.action', {
      name: this.editGoLink ? 'edit_go_links_modal' : 'create_go_links_modal',
      location: { title: this.hubService.currentLocation },
      label: value === 'unlisted_golink' ? (this.viewModel.listed ? 'off' : 'on') : undefined,
      target: value,
    });
  }

  private sendImpressionEventError(value: string): void {
    this.eventsService.event('go_links.errors', {
      target: this.editGoLink ? 'edit_go_links_modal' : 'create_go_links_modal ',
      location: { title: this.hubService.currentLocation },
      label: value,
    });
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    event.stopPropagation();
    if (keys.length == 2 && keys[0] === 'tab' && keys[1] === 'shift' && event.target?.['parentElement']?.id === 'inputUrl') {
      event.preventDefault();
    }
    const key = keys[0];
    switch (key) {
      case 'escape': {
        event.preventDefault();
        this.closePopup(event);
        break;
      }
      case 'tab': {
        if (event.target?.['parentElement']?.id === 'createButton') {
          this.inputUrl.inputElement.el.nativeElement.focus();
          event.preventDefault();
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  async onShareClick(createdGoLink?: GoLinks.CreateResponse) {
    if (!this.editGoLink) {
      this.goLinksService.openShareGoLinkPopup(
        { id: createdGoLink?.id, modifiedTime: createdGoLink?.modifiedTime, listed: this.viewModel.listed },
        'create'
      );
      return;
    }
    this.goLinksService.openShareGoLinkPopup(this.goLinkResultItem, 'edit');
    this.goLinksService.shareGoLink$.pipe(take(2)).subscribe(async (g) => {
      const newShareOptions = g.shareOptions;
      this.goLinkResource.modifiedTime = this.goLinkResultItem.modifiedTime = g.modifiedTime;
      this.goLinkResultItem.shareOptions = cloneDeep(newShareOptions);
      this.sharedAvatarsList = await this.createAvatarList(this.goLinkResultItem?.createdBy, newShareOptions);
      this.cdr.markForCheck();
    });
  }
}
