import { Injectable } from '@angular/core';
import { observable } from '@local/common';
import { PopupRef, PopupService, INIT_POSITION_POPUP } from '@local/ui-infra';
import { EventsService } from '@shared/services';
import { ReplaySubject, take } from 'rxjs';
import { AppPopupComponent, AppPopupData, CheckboxOption } from 'src/app/bar/components/app-popup/app-popup.component';
import {
  VerificationOptionsDetailsComponent,
  VerificationOptionsDetailsModel,
} from 'src/app/bar/components/verifications/verification-options-details/verification-options-details.component';
import { HubService } from 'src/app/bar/services/hub.service';
import {
  WikiCardVersionsModel,
  WikiCardVersionsPopupComponent,
} from '../components/wiki-card-versions-popup/wiki-card-versions-popup/wiki-card-versions-popup.component';
import { EditPopupComponent, EditPopupData } from 'src/app/bar/components/edit-popup/edit-popup.component';
import { Wiki } from '@local/client-contracts';

@Injectable()
export class WikiPopupsService {
  private readonly WARNING_REQUESTS_POPUP_DATA: AppPopupData = {
    message: 'Pending verification requests <br> remain. Would you like to <br> dismiss them before you leave?',
    messageStyle: { fontSize: '14px' },
    showButtons: true,
    rightButtonStyle: { type: 'primary', size: 89, fontSize: 13 },
    leftButtonStyle: { fontSize: 13, size: 89 },
    content: {
      secondaryButton: 'Not now',
      primaryButton: 'Dismiss',
    },
    popupStyle: { padding: '32px 29px 21px 29px' },
  };
  private readonly VERIFY_CHECKBOX: CheckboxOption = {
    text: 'Verify Card',
    value: true,
    position: 'center',
  };
  private _onRestoreVersion$: ReplaySubject<Wiki.Card> = new ReplaySubject(1);
  warningRequestsPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  verificationDetailsPopupRef: PopupRef<VerificationOptionsDetailsComponent, VerificationOptionsDetailsModel> = null;
  verificationStatusPopupRef: PopupRef<AppPopupComponent, AppPopupData> = null;
  versionHistoryPopupRef: PopupRef<WikiCardVersionsPopupComponent, WikiCardVersionsModel> = null;
  restoreVersionPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  notesPopupRef: PopupRef<EditPopupComponent, EditPopupData> = null;
  offerVerifyPopupRef: PopupRef<AppPopupComponent, AppPopupData>;

  @observable
  get onRestoreVersion$() {
    return this._onRestoreVersion$.asObservable();
  }

  set onRestoreVersion(val: Wiki.Card) {
    this._onRestoreVersion$.next(val);
  }

  constructor(
    private popupService: PopupService,
    private eventsService: EventsService,
    private hubService: HubService
  ) {}

  changeVerificationStatus(status: 'Verify' | 'Unverify', cardsCount = 1) {
    if (this.verificationStatusPopupRef) {
      this.verificationStatusPopupRef.destroy();
    }
    const relevantMessage =
      cardsCount > 1
        ? `mark <br> these ${cardsCount} cards as ${status === 'Verify' ? 'verified' : 'unverified'}`
        : `${status.toLowerCase()} <br> this card`;
    this.verificationStatusPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: `Are you sure you want to ${relevantMessage}?`,
        showButtons: true,
        rightButtonStyle: { type: status === 'Verify' ? 'green' : 'primary-danger', size: 89 },
        leftButtonStyle: { size: 89 },
        content: {
          secondaryButton: 'Cancel',
          primaryButton: status,
        },
        messageStyle: { fontSize: '14px' },
      },
      { position: INIT_POSITION_POPUP }
    );
    this.verificationStatusPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.verificationStatusPopupRef = null;
    });
    this.verificationStatusPopupRef.compInstance.primaryButton.pipe(take(1)).subscribe(() => {
      if (status === 'Verify') {
        this.sendVerifyEvent('verify');
      }
    });
    this.verificationStatusPopupRef.compInstance.secondaryButton.pipe(take(1)).subscribe(() => {
      if (status === 'Verify') {
        this.sendVerifyEvent('cancel');
      }
    });
    return this.verificationStatusPopupRef;
  }

  sendVerifyEvent(target: 'cancel' | 'verify') {
    this.eventsService.event('cards_verifications.verify_card_prompt', {
      location: { title: this.hubService.currentLocation },
      target,
    });
  }

  openWarningRequestsPopup(): PopupRef<AppPopupComponent, AppPopupData> {
    this.warningRequestsPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      this.WARNING_REQUESTS_POPUP_DATA,
      {
        position: 'center',
        closeOnClickOut: false,
        backdropStyle: 'blur-2',
      }
    );
    this.warningRequestsPopupRef.destroy$.subscribe(() => {
      this.warningRequestsPopupRef = null;
    });
    return this.warningRequestsPopupRef;
  }

  openVerificationDetailsPopup(verificationDetailsModel: VerificationOptionsDetailsModel) {
    if (this.verificationDetailsPopupRef) {
      this.verificationDetailsPopupRef.destroy();
    }
    this.verificationDetailsPopupRef = this.popupService.open<VerificationOptionsDetailsComponent, VerificationOptionsDetailsModel>(
      'center',
      VerificationOptionsDetailsComponent,
      {
        ...verificationDetailsModel,
        updateGroupAndMembers: 'Verifier',
      },
      { closeOnClickOut: true, backdropStyle: 'blur-2', panelClass: 'verification-options-details-popup' }
    );

    this.verificationDetailsPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.verificationDetailsPopupRef = null;
    });
    return this.verificationDetailsPopupRef;
  }

  openRestoreVersionPopup(restoreVersionText: string, showVerifyOption = false) {
    if (this.restoreVersionPopupRef) {
      this.restoreVersionPopupRef.destroy();
    }
    this.restoreVersionPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: 'Restoring the card will not allow saving <br> changes made to the draft state of the <br> existing card.',
        showButtons: true,
        rightButtonStyle: { size: 96, type: 'primary' },
        leftButtonStyle: { size: 96 },
        content: {
          title: `Are you sure you want to <br> restore ${restoreVersionText}?`,
          secondaryButton: 'Cancel',
          primaryButton: 'Restore',
        },
        messageStyle: { fontSize: '14px' },
        titleStyle: { fontSize: '16px', fontWeight: '700' },
        supportLoadingPrimaryButton: true,
        checkbox: showVerifyOption ? this.VERIFY_CHECKBOX : null,
      },
      { position: INIT_POSITION_POPUP }
    );
    return this.restoreVersionPopupRef;
  }

  openVersionHistoryPopup(data: WikiCardVersionsModel) {
    if (this.versionHistoryPopupRef) {
      this.versionHistoryPopupRef.destroy();
    }
    this.versionHistoryPopupRef = this.popupService.open<WikiCardVersionsPopupComponent, WikiCardVersionsModel>(
      'center',
      WikiCardVersionsPopupComponent,
      data,
      { closeOnClickOut: true, backdropStyle: 'blur-2', panelClass: 'wiki-card-versions-popup' }
    );

    this.versionHistoryPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.versionHistoryPopupRef = null;
    });
    return this.versionHistoryPopupRef;
  }

  openNotesPopup(showVerifyOption = false): PopupRef<EditPopupComponent, EditPopupData> {
    if (this.notesPopupRef) {
      this.notesPopupRef.destroy();
    }

    this.notesPopupRef = this.popupService.open<EditPopupComponent, EditPopupData>(
      'center',
      EditPopupComponent,
      {
        title: 'Publish',
        primaryButtonLabel: 'Publish',
        secondaryButtonLabel: 'Cancel',
        textareaPlaceholder: 'Describe key changes',
        minLength: 0,
        subtitle: 'Summarize changes made to this version (Optional)',
        showCloseIcon: true,
        displayWarningPopup: false,
        checkbox: showVerifyOption ? this.VERIFY_CHECKBOX : null,
      },
      { closeOnClickOut: true, backdropStyle: 'blur-2' }
    );

    this.notesPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.notesPopupRef = null;
    });
    return this.notesPopupRef;
  }

  openOfferVerifyPopup() {
    if (this.offerVerifyPopupRef) {
      this.offerVerifyPopupRef.destroy();
    }
    this.offerVerifyPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: 'Do you want to verify the card?',
        showButtons: true,
        rightButtonStyle: { size: 96, type: 'primary' },
        leftButtonStyle: { size: 96 },
        content: {
          secondaryButton: 'Not now',
          primaryButton: 'Verify',
        },
        messageStyle: { fontSize: '14px' },
      },
      { position: INIT_POSITION_POPUP, closeOnClickOut: false }
    );
    return this.offerVerifyPopupRef;
  }
}
