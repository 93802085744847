import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UiIconModel } from '../../types/ui.icon.model';

@Component({
  selector: 'u-icon',
  templateUrl: './u-icon.component.html',
  styleUrls: ['./u-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIconComponent {
  private readonly DEFAULT_FALLBACK_URL = './assets/appicon.svg';
  @Input() styles: any = {};
  @Input() isBold: boolean;
  @Input() reverseTheme: boolean;
  @Input() imageId = '';
  @Output() error = new EventEmitter();
  @Input() iconCtx: 'url' | 'style' = null;
  @Input() customClass = '';
  @Input() disabled = false;
  @Input() referrerpolicy = 'origin';
  @Input() inSvgIgnoreColors: string[];
  private _inSvg: any = null;
  public get inSvg(): any {
    return this._inSvg;
  }
  @Input() public set inSvg(value: string) {
    if (value && !value.endsWith('.svg')) return;
    this._inSvg = value;
  }

  private _fallback: string = this.DEFAULT_FALLBACK_URL;
  get fallback(): string {
    return this._fallback;
  }
  @Input()
  set fallback(value: string) {
    this._fallback = value;
    this.updateModel(this._model);
  }

  private _model: UiIconModel;
  get model(): UiIconModel {
    return this._model;
  }
  @Input()
  set model(v: UiIconModel) {
    this.updateModel(v);
  }

  private updateModel(v: UiIconModel) {
    this._model = v;
    if (v?.type === 'img' && v.value) {
      if (Object.prototype.hasOwnProperty.call(v.value, 'lightUrl') && !v.value.lightUrl && this.fallback !== this.DEFAULT_FALLBACK_URL) {
        this._model.value.lightUrl = this.fallback;
      }
      if (Object.prototype.hasOwnProperty.call(v.value, 'darkUrl') && !v.value.darkUrl && this.fallback !== this.DEFAULT_FALLBACK_URL) {
        this._model.value.darkUrl = this.fallback;
      }
    }
  }
}
