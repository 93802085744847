import { Injectable } from '@angular/core';
import { Wiki } from '@local/client-contracts';
import { keyBy } from 'lodash';
import { BlobsService } from 'src/app/bar/services/blob.service';

export type AttachmentsStorage = { [blobId: string]: Wiki.CardAttachment };

@Injectable()
export class FrameEditorStorageAttachmentsService {
  private storageByFollowingId: { [followingId: string]: AttachmentsStorage } = {};

  constructor(private blobsService: BlobsService) {}

  initAttachments(followingId: string, attachments: Wiki.CardAttachment[]) {
    const storage = keyBy(attachments || [], 'blobId');
    this.storageByFollowingId[followingId] = storage;
  }

  addAttachment(followingId: string, attachment: Wiki.CardAttachment) {
    if (!attachment.blobId) {
      return;
    }
    if (!this.storageByFollowingId[followingId]) {
      this.storageByFollowingId[followingId] = {};
    }
    this.storageByFollowingId[followingId][attachment.blobId] = attachment;
  }

  getAttachment(followingId: string, blobId: string) {
    return this.storageByFollowingId[followingId]?.[blobId];
  }

  updateAttachmentName(followingId: string, blobId: string, newName: string) {
    if (!this.storageByFollowingId[followingId]?.[blobId]) {
      return;
    }
    this.storageByFollowingId[followingId][blobId].name = newName;
  }

  getAttachments(followingId: string, blobIds: string[]) {
    if (!blobIds?.length) {
      return [];
    }
    return blobIds.map((blobId) => this.getAttachment(followingId, blobId)).filter((a) => !!a);
  }

  resetAttachments(followingId: string, currentBlobIds: string[]) {
    this.deleteUnRelevantAttachments(followingId, currentBlobIds);
    delete this.storageByFollowingId[followingId];
  }

  private deleteUnRelevantAttachments(followingId: string, currentBlobIds: string[]) {
    const currentBlobIdsSet = new Set(currentBlobIds || []);
    const unRelevantIds = Object.keys(this.storageByFollowingId[followingId] || {}).filter((id) => !currentBlobIdsSet.has(id));
    if (unRelevantIds?.length) {
      this.blobsService.delete(unRelevantIds);
    }
  }
}
