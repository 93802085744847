import { Applications, Calculators, Experiences, Favorites, LocalActions, Results, Search, WebSearch } from '@local/client-contracts';
import { isMac } from '@local/common-web';
import { getRandom } from '@shared/utils/utils';
import { WikiCardCollectionResultItem } from 'src/app/bar/services/search/client/wiki-collection-items';
import {
  AddToCollectionItem,
  AnswerSearchItem,
  BrowserBookmarkItem,
  BrowserTabItem,
  CollectionItem,
  CollectionsStaticItem,
  CreateGoLinkItem,
  FavoritesItem,
  FileViewTitle,
  GalleryGroup,
  GoToItem,
  HeaderItem,
  ImpressionResourceList,
  PCName,
  PersonItem,
  RecentSearchItem,
  ResultDisplayModel,
  RfpSearchItem,
  StaticSearchItem,
  SuggestionsItem,
  TimeActionItem,
  TopPicksResult,
} from '../models/results-types';
import { Action } from '../models/view-filters';

export const isResourceItem = (x: any): x is Search.ResourceItem => !!(<any>x).resource;
export const isSelectable = (x: any) =>
  ((x.type != 'header' && x.type !== 'collection-header') || x.selectable) && (x.type != 'result' || !x.deleted);
export const isResult = (x: any): x is Search.ResultItem => x.type === 'result';
export const isErrorItem = (x: any): x is Search.ErrorItem => x.type === 'error';
export const isResourceResult = (x: any): x is Search.ResultResourceItem => x?.type === 'result';
export const isLocalAction = (x: any): x is LocalActions.LocalActionItem => x.type === 'local-action';
export const isCalculation = (x: any): x is Calculators.Item => x.type === 'calculator';
export const isRecentSearch = (x: any): x is RecentSearchItem => x.type === 'recent-search';
export const isGoTo = (x: any): x is GoToItem => x.type === 'goto';
export const isCreateGoLink = (x: any): x is CreateGoLinkItem => x.type === 'create-go-link';
export const isAddToCollection = (x: any): x is AddToCollectionItem => x.type === 'add-to-collection';
export const isWebSearch = (x: any): x is WebSearch.ResultItem => x.type === 'web-search';
export const isGoLink = (x: any): x is Search.GoLinkResultItem => x.type === 'go-link';
export const isLocalResult = (x: any): x is Search.ResultResourceItem => isResourceResult(x) && x.resource?.appId === 'pc';
export const isCollection = (x: any): x is CollectionItem => x?.type === 'collection';
export const isHeader = (x: any): x is HeaderItem => x?.type === 'header' || x?.type === 'search-line';
export const isSuggestion = (x: any): x is SuggestionsItem => x.type === 'suggestions';
export const isAttachment = (x: any): x is Results.Attachment => x.type === 'attachment';
export const isPerson = (x: any): x is PersonItem => x?.type === 'person';
export const isBookmark = (x: any): x is BrowserBookmarkItem => x.type === 'browser-bookmark';
export const isTab = (x: any): x is BrowserTabItem => x.type === 'browser-tab';
export const isFavorite = (x: any): x is FavoritesItem => isResourceItem(x) && (x as FavoritesItem).isFavorite;
export const isCollectionStaticItem = (x: any): x is CollectionsStaticItem => x.type === 'collection-static-item';
export const isCollectionFileStaticItem = (x: any): x is Search.CollectionFileResultItem => x.type === 'collection-file';
export const isStaticResultItem = (x: any): x is Search.StaticCollectionResultItem => x.kind === 'static-collection-item';

export const isWikiCardLocal = (x: any): x is WikiCardCollectionResultItem => x?.filterType === 'wiki-local';
export const isWikiCardRemote = (x: any): x is Search.ResourceItem =>
  x?.resource?.appId === 'wiki' && x?.filterType?.toLowerCase() === 'wiki card';
export const isOpenAdvancedSearch = (x: any): x is StaticSearchItem => x.invokeType === 'open-external';
export const isAnswerSearch = (x: any): x is StaticSearchItem => x.invokeType === 'search-answer';
export const isCollectionHeaderItem = (x: any): x is Search.ResultItem => x.type === 'collection-header';
export const isAnswerItem = (x: any): x is AnswerSearchItem => x.type === 'answer';
export const isCollectionUrl = (x: any): x is Search.CollectionUrlResultItem => x.type === 'collection-url';
export const isRelevantPeopleItem = (x: any): x is Search.RelevantPeopleItem => x?.action?.type === 'relevant-people';
export const isAssistantItem = (x: any): x is Experiences.ExperienceItem => x.type === 'assistant';
export const isWikiItem = (x: any): x is WikiCardCollectionResultItem => x.source === 'wiki-collection-items';
export const isWikiDraft = (x: any): x is WikiCardCollectionResultItem => x?.source === 'wiki-drafts';
export const isRfpItem = (x: any): x is RfpSearchItem => x.invokeType === 'fill-questionnaire';
export const isGalleryGroup = (x: any): x is GalleryGroup => x.type === 'gallery-group';
export const isOpenChat = (x: any): x is StaticSearchItem => x.invokeType === 'open-chat';
export const isStaticItem = (x: any): x is StaticSearchItem => x.type === 'static-search-item';
export const isTimeActionItem = (x: any): x is TimeActionItem => x.type === 'time-action';
export const isFollowUpChatItem = (x: any): x is StaticSearchItem => x.invokeType === 'follow-up-chat';

export const descriptionsToBullets = (descriptions: Applications.Description[], max = 3): string[] => {
  const lengthToBullets: Record<number, () => Applications.Bullet[]> = {
    1: () => descriptions[0].bullets,
    2: () => {
      const final = [descriptions[0].bullets[0], descriptions[1].bullets[0]];
      let randomIndex = Math.floor(Math.random() * descriptions.length) - 1;
      randomIndex = randomIndex >= 0 ? randomIndex : 0;
      final.push(descriptions[randomIndex].bullets[1]);
      return final;
    },
    3: () => [descriptions[0].bullets[0], descriptions[1].bullets[0], descriptions[2].bullets[0]],
    4: () => getRandom(descriptions, max).map((d) => d.bullets[0]),
  };

  if (!lengthToBullets[descriptions.length]) return;

  const bullets = lengthToBullets[descriptions.length]();
  if (bullets.length > 0)
    return bullets
      .filter((b) => !!b)
      .map((b) => b.text.toLowerCase())
      .slice(0, max);
};

export const mergeFilter = <T extends Search.Filter>(first?: T[], second?: T[]) => {
  if (!first?.length || !second?.length) return [...(first || []), ...(second || [])];

  const filters: { [id: string]: T } = first.reduce((map, filter) => {
    map[filter.id] = filter;
    return map;
  }, {});

  for (const filter of second) {
    const key = filter.id;
    filters[key] = filters[key] || filter;
    filters[key].selected = filters[key]?.selected || filter?.selected;
  }
  return Object.values(filters);
};

export const mergeResponseFilters = (first: Search.ResponseFilters, second: Search.ResponseFilters): Search.ResponseFilters => {
  const data = {};

  for (const key of Object.keys(first)) {
    data[key] = mergeFilter(first[key], second?.[key]);
  }
  return data;
};

export const mergeSearchResponses = (first: Search.Response, second: Search.Response): Search.Response => {
  const result = {
    totalResults: 0,
    results: [],
    siteLinks: [],
    ...first,
  } as Search.Response;
  result.results = result.results.concat(second?.results || []);
  result.siteLinks = result.siteLinks.concat(second?.siteLinks || []);
  if (second?.filters) result.filters = mergeResponseFilters(first.filters, second.filters);
  result.totalResults = (result.totalResults || 0) + (second?.totalResults || 0);
  return result;
};

export const filtersEqual = (a: Search.RequestFilters, b: Search.RequestFilters): boolean => {
  if (!a && !b) return true;
  if (!a || !b) return false;

  const res =
    filterParamsEqual(a.app, b.app) &&
    filterParamsEqual(a.service, b.service) &&
    filterParamsEqual(a.type, b.type) &&
    filterParamsEqual(a.account, b.account);

  return res;
};

export const filterParamsEqual = (a: string[], b: string[]) => {
  if (!a && !b) return true;
  a = a || [];
  b = b || [];
  if (a.length != b.length) return false;
  if (a.find((x) => !b.find((y) => y == x))) return false;
  if (b.find((x) => !a.find((y) => y == x))) return false;
  return true;
};

export const requestEqual = (r1: Search.Request, r2: Search.Request) => {
  if (r1 == r2) return true;
  if (!r1 || !r2) return false;

  const result = r1.query == r2.query && filtersEqual(r1.preFilters, r2.preFilters) && filtersEqual(r1.postFilters, r2.postFilters);

  return result;
};

export const prevSiblingThatHasSelector = (element: HTMLElement, selector: string) => {
  let curr: HTMLElement = element.previousElementSibling as HTMLElement;

  while (!curr || !curr.webkitMatchesSelector(selector)) {
    const prev = curr?.previousElementSibling as HTMLElement;
    if (!prev) return curr;

    curr = prev;
  }

  return curr;
};

export const nextSiblingThatHasSelector = (element: HTMLElement, selector: string) => {
  if (!element) return;

  let curr: HTMLElement = element.nextElementSibling as HTMLElement;

  while (!curr || (curr && !curr.webkitMatchesSelector(selector))) {
    const next = curr?.nextElementSibling as HTMLElement;
    if (!next) return curr;

    curr = next;
  }

  return curr;
};

/** We don't want to rely on the applications service to load in order to render the result.
 * Therefor we apply this hack , to get an initial value that will be replaced with the validated value later
 */
export const pcAppName = (): PCName => {
  return isMac() ? 'This Mac' : 'This PC';
};

export const lastPCIndex = (items: ResultDisplayModel[]): number => {
  return [...items].reverse().findIndex((i) => isResourceResult(i) && i.resource.appId === 'pc');
};

export const listNameByType = (item: Search.Item | { type: string; id?: string }, viewName?: string): ImpressionResourceList => {
  if (isCalculation(item)) return 'calculator_search_results';

  if (isPerson(item)) return 'people';

  if (isFavorite(item)) return 'favorites';

  if (isBookmark(item)) return 'bookmarks';

  if (isTab(item)) return 'tabs';

  if (isLocalAction(item)) return 'actions_search_results';

  if (isLocalResult(item)) return 'local_search_results';

  if (isResult(item)) {
    if (viewName?.includes('c/')) {
      return 'collection_cloud_results';
    }
    const topPicksItem = <TopPicksResult>item;
    if (!topPicksItem.kind) return 'cloud_search_results';
    return topPicksItem.kind;
  }

  if (isCollection(item) && ['search', 'collections', 'favorites'].includes(viewName)) {
    return 'collections';
  }
};

export const addPluralIfNeeded = (text: string, count: number) => {
  if (count > 1) {
    if (text.endsWith('y')) return text.substring(0, text.length - 1) + 'ies';

    return text + 's';
  }
  return text;
};

export const buildFileViewItemTitle = (title): FileViewTitle => {
  if (!title?.text) return;
  const fullTitle = title?.text;
  const splitted = fullTitle.split('.');
  const fileType = splitted.length > 1 ? splitted[splitted.length - 1] : '';
  const fileName = fullTitle.slice(0, fullTitle.length - fileType.length);
  const end = fileType ? `${fileName.slice(Math.max(fileName.length - 5, 0))}${fileType}` : '';
  return {
    start: { tooltip: fullTitle, ...title, text: fullTitle.slice(0, fullTitle.length - end.length) },
    end: end,
  };
};

const viewsSupportedPreview: string[] = ['people', 'mail', 'favorites', 'search'];
export const supportPreview = (view: string): boolean => {
  return viewsSupportedPreview.includes(view);
};

export const isWikiCard = (item: any) => item?.action?.type === 'wiki card';
export const isWikiCardFile = (item: Search.ResultResourceItem) => item?.resource?.type === 'wiki:card:attachment';

export const isPreviewClickAction = (action: Action) => {
  return action?.click?.primary?.type === 'preview' || action?.click?.secondary?.type === 'preview';
};

export const getPrimaryActionClick = (action: Action) => {
  return action?.click?.primary?.type;
};

export const isSummaryClickAction = (action: Action) => {
  return action?.click?.actions?.find((a) => a?.type === 'summary');
};

export const isEnabledSummary = (action: Action) => {
  return isSummaryClickAction(action);
};

export const convertResultTypeToFavoriteType = (type: Search.ResultType): Favorites.Type => {
  if (!type) return 'link-resource';
  if (
    ['collection-url', 'collection-header', 'collection-file', 'collection-static-item', 'add-to-collection', 'collection'].includes(type)
  ) {
    return 'collection';
  }
  if (type === 'go-link') {
    return 'go-links';
  }
  return 'link-resource';
};
