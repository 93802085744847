import { Injectable } from '@angular/core';
import { Assistants } from '@local/client-contracts';
import { observable } from '@local/common';
import { Observable, ReplaySubject } from 'rxjs';
import { AssistantsRpcInvoker } from './invokers';
import { ServicesRpcService } from './rpc.service';

@Injectable()
export class AssistantsService implements Assistants.Service {
  private service: Assistants.Service;
  private _llms$: ReplaySubject<Assistants.AssistantLlmsResponseItem[]> = new ReplaySubject<Assistants.AssistantLlmsResponseItem[]>(1);

  constructor(rpcServices: ServicesRpcService) {
    this.service = rpcServices.invokeWith(AssistantsRpcInvoker, 'assistants');
    this.service.llms$.subscribe((llms) => {
      this._llms$.next(llms);
    });
  }

  @observable
  get llms$(): Observable<Assistants.AssistantLlmsResponseItem[]> {
    return this._llms$.asObservable();
  }

  ticket$(req: Assistants.AssistantTicketRequest): Observable<Assistants.AssistantTicketContext> {
    return this.service.ticket$(req);
  }
  summaryTicket$(req: Assistants.AssistantSummaryRequest): Observable<Assistants.AssistantSummaryContext> {
    return this.service.summaryTicket$(req);
  }
  answers$(req: Assistants.BaseAnswersSearchRequest): Observable<Assistants.AnswersSearchResponse> {
    return this.service.answers$(req);
  }
}
