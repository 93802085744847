import { getFileSize } from '@shared/utils';
import { ToasterData } from 'src/app/bar/components/toaster/toaster-data';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import tinymce from 'tinymce';

const frameEditorReadonlyFields = {
  frameEditorToaster: {
    SPACE_BOTTOM_TOASTER: 50,
    SPACE_LEFT_TOASTER: 20,
  },
  TOASTER_ERROR_DATA: {
    id: 'upload-file-error',
    title: 'Oops.. something went wrong. Please try again! ',
    icon: { type: 'font', value: 'icon-duo-exclamation-circle' },
    iconIntent: 'danger',
  } as ToasterData,
};

export const attachmentsRegex = {
  FIND_ID_RXJS: /id="([^"]+)" class="attachment"/g,
  EXTRACT_ID_RXJS: /"([^"]+)"/,
  FIND_ID_MEDIA_RXJS: /<(img|video)[^>]*id="([^"]+)"/g,
};

export const frameEditorNumbers = {
  MB: 1024 * 1024,
};

export function getPositionToaster(editorId: string) {
  tinymce.get(editorId).focus();
  const frame = document.activeElement?.parentElement?.parentElement?.parentElement;
  if (frame) {
    const { right, left, bottom } = frame.getBoundingClientRect();
    return {
      top: bottom - frameEditorReadonlyFields.frameEditorToaster.SPACE_BOTTOM_TOASTER,
      right,
      left: left + frameEditorReadonlyFields.frameEditorToaster.SPACE_LEFT_TOASTER,
    };
  }
  return;
}

export function showEditorToasterError(showToasterService: ShowToasterService, editorId: string, title?: string) {
  const position = getPositionToaster(editorId);
  showToasterService.showToaster({ ...frameEditorReadonlyFields.TOASTER_ERROR_DATA, title, position });
}

export function getSizeFileError(size: number): string {
  return `Upload failed. Max file size of ${getFileSize(size, false, false)}`;
}

export function validateFileSize(editorId: string, size: number, maxSizeMB: number, showToasterService: ShowToasterService): boolean {
  const maxSize = maxSizeMB * frameEditorNumbers.MB;
  if (size < maxSize) {
    return true;
  }
  showEditorToasterError(showToasterService, editorId, getSizeFileError(maxSize));
  return false;
}

export function removeAllAttachments(html: string): string {
  if (!html) return;
  const ids = html.match(attachmentsRegex.FIND_ID_RXJS) || [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  for (const fullId of ids) {
    const id = fullId.match(attachmentsRegex.EXTRACT_ID_RXJS)?.[1];
    const elementToRemove = doc.getElementById(id);
    if (elementToRemove) {
      if (elementToRemove?.nextSibling?.textContent === '\n') {
        elementToRemove.nextSibling.remove();
      }
      elementToRemove.remove();
    }
  }

  let innerHtml = doc.body.innerHTML;
  // we remove \n if it's the first char in the html , to fix issue with Tinymce that ignore first enter
  if (innerHtml[0] === '\n') {
    innerHtml = innerHtml.slice(1, innerHtml.length - 1);
  }
  return innerHtml;
}
