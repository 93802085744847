<div class="u-dropdown-container">
  <p-dropdown
    [options]="options"
    [(ngModel)]="selectedValue"
    [class]="'u-dropdown ' + dropdownSize"
    [class.readonly]="readonly"
    (onClear)="onClearEvent($event)"
    [optionLabel]="optionLabel"
    (onChange)="onChangeEvent($event)"
    (onFilter)="onFilter.emit($event)"
    (onShow)="onShow.emit($event)"
    (onHide)="onHide.emit($event)"
    [filter]="filter"
    [filterBy]="filterBy"
    [showClear]="showClear"
    [placeholder]="placeholder"
    [appendTo]="appendTo"
    [panelStyleClass]="
      'u-dropdown-panel ' +
      (inTable ? 'panel-in-table ' : '') +
      (hideItemsContainer ? 'hide-items-container' : '') +
      (panelStyleClass ? panelStyleClass : '')
    "
    [styleClass]="styleClass"
    [style]="styles"
    [ngClass]="{
      'in-table': inTable,
      'outside-icon': dropdownSelectIcon && placeholder && !selectedValue,
      'hide-items-container': hideItemsContainer,
      'has-values': hasValues,
    }"
    [disabled]="disabled"
    [filterPlaceholder]="filterPlaceholder"
    #dropdown
  >
    <ng-template let-value pTemplate="selectedItem">
      <div class="new-selectedItem-container">
        <span *ngIf="dropdownType" class="dropdown-type">{{ dropdownType }}:</span>
        <div *ngIf="dropdownSelectIcon" class="dropdown-icon">
          <u-icon
            [styles]="selectedItemIconStyle"
            [model]="dropdownSelectIcon"
            [inSvg]="iconInnerSvg ? dropdownSelectIcon?.value?.lightUrl : null"
          ></u-icon>
        </div>
        <div
          class="new-selectedItem-label"
          [ngClass]="{ 'with-icon': dropdownSelectIcon }"
          tooltipPosition="top"
          [pTooltip]="value[optionLabel]"
          tooltipStyleClass="u-tooltip"
          [tooltipDisabled]="!showSelectedItemTooltip || value[optionLabel]?.length < maxCharacters"
        >
          {{ value[optionLabel] }}
        </div>
      </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div class="item-container" [ngClass]="{ disabled: item[disabledField] }">
        <div
          class="text-container"
          [ngClass]="{ 'add-element': inTable && item[optionLabel] === endListElement, 'has-sub-title': item[optionalSubLabel] }"
        >
          <u-icon
            class="item-icon"
            [styles]="itemIconStyle"
            [model]="item?.icon"
            [inSvg]="iconInnerSvg ? item?.icon?.value?.lightUrl : null"
          ></u-icon>
          <div class="text">
            <span class="title" [class.has-sub-title]="item[optionalSubLabel]">{{ item[optionLabel] }}</span>
            <span class="sub-title" *ngIf="item[optionalSubLabel]">{{ item[optionalSubLabel] }}</span>
          </div>
        </div>
        <u-icon
          *ngIf="item[disabledField] && item[disabledTooltipField]"
          [model]="{ type: 'font', value: 'icon-exclamation-mark-circle' }"
          [styles]="{ color: 'var(--yellow-600)', fontSize: '14px' }"
          [pTooltip]="item[disabledTooltipField]"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
        ></u-icon>
        <i *ngIf="item?.[optionLabel] === selectedValue?.[optionLabel]" class="pi pi-check"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div *ngIf="showClearAll && selectedValue" class="clear-all-section" (click)="onClearAll()">
        <u-button label="Clear All" type="secondary" class="no-state clear-all" [styles]="{ marginTop: '8px' }"> </u-button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <i
        *ngIf="filter && dropdown?._filterValue"
        class="pi pi-times clear-icon dropdown-filter-clear-icon"
        (click)="clearFilter($event)"
      ></i>
    </ng-template>
  </p-dropdown>

  <div *ngIf="dropdownSelectIcon && placeholder && !selectedValue" class="dropdown-icon outside-dropdown">
    <u-icon [styles]="selectedItemIconStyle" [model]="dropdownSelectIcon"></u-icon>
  </div>
</div>
