<ng-container #page>
  <main class="wrapper" [class.empty]="emptyState">
    <ng-container *ngIf="!emptyState">
      <ng-scrollbar visibility="hover">
        <ol class="links-list">
          <ng-container *ngIf="links?.length">
            <link-item
              *ngFor="let link of links; index as i; trackBy: trackLink"
              [link]="link"
              [index]="i"
              [active]="i == selectedLinkIndex"
              [usedColors]="usedColors"
              [appName]="model?.name"
              (invoke)="onInvoke($event)"
              (selected)="setSelected($event)"
              [lastSync]="latestSyncLinks.get(link.id)"
              class="links-item"
            ></link-item>
          </ng-container>
        </ol>
      </ng-scrollbar>
    </ng-container>
    <section *ngIf="emptyState" class="empty-state-container">
      <div class="empty-state-content">
        <u-icon class="empty-state-icon" [model]="{ type: 'img', value: model.icon }"></u-icon>
        <p class="empty-state-description">
          {{ description?.header }}
        </p>
        <ol class="empty-state-list">
          <li class="empty-state-list-bullet" *ngFor="let bullet of description?.bullets">{{ bullet.text }}</li>
        </ol>
        <u-button
          [attr.data-cy]="'linkapp-item-btn'"
          label="Connect"
          [styles]="{ width: 'calc(var(--hub-content-width) / 4)' }"
          type="primary"
          class="empty-state-connect-button"
          [disabled]="model.state.disabled"
          (click)="addLink()"
        ></u-button>
      </div>
    </section>

    <section *ngIf="!emptyState" class="app-container">
      <ng-container *ngIf="model; else appGhosting">
        <u-icon class="app-icon" [model]="{ type: 'img', value: model.icon }"></u-icon>
        <p class="app-title">
          {{ model?.name }}
        </p>
        <p class="app-description">
          {{ description?.header }}
        </p>
        <ul>
          <li class="app-list-bullet" *ngFor="let bullet of description?.bullets">{{ bullet.text }}</li>
        </ul>
        <u-button
          data-cy="linkapp-add-account-btn"
          label="Add Account"
          type="primary"
          class="app-add-account"
          (click)="addLink()"
          fontIcon="plus"
          [iconStyles]="{ fontSize: '10px', fontWeight: 'bold' }"
          [styles]="{ width: '270px' }"
          [disabled]="isDisableApp"
          tooltipPosition="bottom"
          tooltipStyleClass="u-tooltip"
          [pTooltip]="tooltipDisabledConnect"
          [tooltipDisabled]="!model.state.workspaceDisabled && !this.isOwnerOrAdmin"
        >
        </u-button>
      </ng-container>
      <ng-template #appGhosting>
        <div class="app-icon app-icon-ghost"></div>
        <p class="app-description app-description-ghost"></p>
        <ol>
          <li class="app-list-bullet app-list-bullet-ghost" *ngFor="let i of 8 | fill"></li>
        </ol>
      </ng-template>
    </section>
    <div #sideBar>
      <u-sidebar
        [visible]="sidebarVisible"
        [transitionOptions]="transitionOptions"
        [content]="content"
        [showCloseIcon]="false"
        [showMask]="false"
        [position]="'right'"
      >
      </u-sidebar>
    </div>
  </main>
</ng-container>

<ng-template #content>
  <ng-scrollbar visibility="hover">
    <link-item
      #linkItem
      [usedColors]="usedColors"
      [link]="links?.[selectedLinkIndex]"
      [appName]="model?.name"
      [isPaletteOpen]="true"
      (invoke)="onInvoke($event)"
      [lastSync]="latestSyncLinks.get(links?.[selectedLinkIndex]?.id)"
    ></link-item>
  </ng-scrollbar>
</ng-template>

<error-overlay *ngIf="error" (back)="this.error = undefined" (again)="retryInvoke(lastCommand)"></error-overlay>
