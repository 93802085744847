import { Injectable } from '@angular/core';
import DiffMatchPatch from 'diff-match-patch';

@Injectable()
export class FrameEditorDiffHtmlService {
  private readonly BASE_CHAR_CODE = 57344;
  private readonly ADDED_CLASS_NAME = 'u-revisionhistory-added';
  private readonly REMOVED_CLASS_NAME = 'u-revisionhistory-removed';
  private readonly IGNORE_TEXTS = ['\n', ' ', '&nbsp;'];
  private readonly MODIFIED_TEXTS = ['<ul', '<ol', '<li', '<tr', '<td', '<th', '<col', '<span', '<em>', '<s>', '<strong>', '<code>'];

  private unicodeToHtmlMap = new Map<string, string>();
  private diffMatch: DiffMatchPatch;

  constructor() {
    this.diffMatch = new DiffMatchPatch();
  }

  renderDiff(oldHtml: string, newHtml: string): { diffOldHtml: string; diffNewHtml: string } {
    this.unicodeToHtmlMap.clear();
    const oldUnicodeHtml = this.convertTagHtmlToUnicode(oldHtml);
    const newUnicodeHtml = this.convertTagHtmlToUnicode(newHtml);

    const diffs = this.diffMatch.diff_main(oldUnicodeHtml, newUnicodeHtml);
    this.diffMatch.diff_cleanupSemantic(diffs);

    return this.rejoinDiffs(diffs);
  }

  private convertTagHtmlToUnicode(html: string): string {
    return html.replace(/<[^>]+>/g, (tag) => this.getUnicodeString(tag));
  }

  private getUnicodeString(tag: string): string {
    const tagName = tag.replace(/[<>]/g, '');
    const existsTag = this.unicodeToHtmlMap.get(tagName);
    if (existsTag) {
      return existsTag;
    }
    const hash = (input: string): number => {
      let e = 0;
      for (let n = 0; n < input.length; n++) {
        e = (e << 5) - e + input.charCodeAt(n);
        e |= 0;
      }
      return e;
    };
    const unicode = String.fromCharCode(this.BASE_CHAR_CODE + hash(tagName));
    this.unicodeToHtmlMap.set(unicode, tagName);
    return unicode;
  }

  private rejoinDiffs(diffs: Array<[number, string]>): { diffOldHtml: string; diffNewHtml: string } {
    let diffOldHtml = '';
    let diffNewHtml = '';

    diffs.forEach(([operation, segment]) => {
      const htmlSegment = this.convertUnicodeTagToHtml(segment);
      if (operation === 0) {
        diffOldHtml += htmlSegment;
        diffNewHtml += htmlSegment;
        return;
      }
      // Split each segment into parts (tags and text)
      const parts = htmlSegment.split(/(<[^>]+>)/g).filter(Boolean);
      parts.forEach((part) => {
        if (operation === -1) {
          diffOldHtml += this.updateSemanticDiff(part);
        }
        if (operation === 1) {
          diffNewHtml += this.updateSemanticDiff(part, true);
        }
      });
    });

    return { diffOldHtml, diffNewHtml };
  }

  private updateSemanticDiff(text: string, added = false): string {
    if (this.IGNORE_TEXTS.includes(text) || this.MODIFIED_TEXTS.some((ignoreText) => text.startsWith(ignoreText))) {
      return text;
    }
    const isTag = /<[^>]+>/.test(text);
    const className = added ? this.ADDED_CLASS_NAME : this.REMOVED_CLASS_NAME;
    if (!isTag) {
      const tagName = added ? 'ins' : 'del';
      return `<${tagName} class="${className}">${text}</${tagName}>`;
    }
    return text.replace(/<(\w+)([^>]*)>/, (_, tagName, attributes) => {
      const classMatch = attributes.match(/class\s*=\s*["']([^"']*)["']/);
      if (classMatch) {
        const existingClasses = classMatch[1];
        const updatedClasses = `${existingClasses} ${className}`;
        return `<${tagName}${attributes.replace(classMatch[0], `class="${updatedClasses}"`)}>`;
      } else {
        return `<${tagName}${attributes} class="${className}">`;
      }
    });
  }

  private convertUnicodeTagToHtml(unicodeStr: string): string {
    let result = unicodeStr;
    this.unicodeToHtmlMap.forEach((tag, unicode) => {
      const spl = result.split(unicode);
      result = spl.join(`<${tag}>`);
    });
    return result;
  }
}
