import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { UiIconModel } from '../../types/ui.icon.model';
interface Option {
  [key: string]: any;
}
type StyleObject = {
  [key: string]: string;
};
@Component({
  selector: 'u-dropdown',
  templateUrl: './u-dropdown.component.html',
  styleUrls: ['./u-dropdown.component.scss'],
})
export class UDropdownComponent implements OnInit {
  @Input() options: Option[] = [];
  @Input() selectedValue: any;
  @Input() optionLabel = 'name';
  @Input() optionalSubLabel: string;
  @Input() filter = false;
  @Input() filterBy: string = null;
  @Input() showClear = false;
  @Input() placeholder: string = null;
  @Input() inTable = false;
  @Input() endListElement: any = null;
  @Input() styles: any = {};
  @Input() appendTo: string = null;
  @Input() dropdownSize = '';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() dropdownSelectIcon: UiIconModel;
  @Input() showClearAll = false;
  @Input() filterPlaceholder: string = null;
  @Input() hideItemsContainer = false;
  @Input() disabledField = 'disabled';
  @Input() disabledTooltipField = 'disabledTooltip';
  @Input() styleClass: string;
  @Input() panelStyleClass: string;
  @Input() iconInnerSvg: boolean;
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeEndList: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClearAllEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onHide: EventEmitter<any> = new EventEmitter<any>();
  @Output() onShow: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClearFilter: EventEmitter<any> = new EventEmitter<any>();
  @Input() indicationSelected = false;
  @Input() hideOnClickOut = false;
  @Input() dropdownType: string;
  @Input() showSelectedItemTooltip: boolean;
  @Input() maxCharacters: number;
  @Input() disableHandleKeys;
  @Input() itemIconStyle: StyleObject = { width: '14px', marginRight: '8px' };
  @Input() selectedItemIconStyle: StyleObject = { fontSize: '14px', width: '14px' };
  _hasValues = false;
  get hasValues(): boolean {
    return this._hasValues;
  }
  @Input() set hasValues(value: boolean) {
    if (!value) {
      this.selectedValue = null;
      this.dropdown?.resetFilter();
    }
    this._hasValues = value;
  }

  @Input() marked; //TODO: FIXED

  @Input() set open(o: boolean) {
    setTimeout(() => {
      if (this.dropdown) {
        o ? this.dropdown.show() : this.dropdown.hide();
      }
    }, 0);
  }

  @ViewChild(Dropdown) dropdown: Dropdown;
  private _previousValue: any;

  @HostListener('document:mousedown', ['$event'])
  onDocumentMouseDown(event: MouseEvent) {
    if (this.hideOnClickOut && !this.dropdown?.el?.nativeElement?.contains(event.target) && !this.appendTo) {
      this.dropdown?.hide();
    }
  }

  ngOnInit(): void {
    if (this.inTable && this.endListElement) {
      this.options = [...this.options, { [this.optionLabel]: this.endListElement }];
    }
    this._previousValue = this.selectedValue;
  }

  onClearEvent($event) {
    this.selectedValue = null;
    this.onClear.emit($event);
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown($event: KeyboardEvent) {
    if (!this.disableHandleKeys && (this.dropdown.overlayVisible || this.dropdown.focused)) {
      if (['Tab', 'Escape', 'Enter', 'Space', 'ArrowDown', 'ArrowUp'].includes($event.code)) {
        event.stopPropagation();
      }
    }
  }

  onChangeEvent($event) {
    if (!$event || $event?.value[this.disabledField]) {
      return;
    }
    this.dropdown.resetFilter();
    if (this.inTable && $event.value[this.optionLabel] === this.endListElement) {
      this.selectedValue = this._previousValue;
      setTimeout(() => {
        this.dropdown.onOptionSelect($event, this._previousValue);
      }, 0);
      this.onChangeEndList.emit();
    } else {
      if ($event.originalEvent.value && $event.originalEvent.value !== $event.value) {
        //if the event is the event the throws from setTimeout we dont want the onChange to call again
      } else {
        this.onChange.emit($event.value);
      }
    }
    this._previousValue = this.selectedValue;
  }

  onClearAll() {
    this.selectedValue = null;
    this.onClearAllEvent.emit();
  }

  clearFilter(event?) {
    event?.preventDefault();
    event?.stopPropagation();
    this.dropdown?.resetFilter();
    this.onClearFilter.emit();
  }
}
