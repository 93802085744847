<div class="popup-wrapper" (click)="clickOutPopup()">
  <div class="popup-container" (click)="$event.stopPropagation()" [style]="popupConfig.popupStyles">
    <u-icon
      *ngIf="popupConfig.showCloseIcon"
      (click)="closePopup($event)"
      class="close-icon"
      [model]="{ type: 'font', value: 'icon-times' }"
      [styles]="{ fontSize: '18px' }"
    ></u-icon>
    <div class="popup-header">
      <span class="popup-title">{{ popupConfig.title }}</span>
      @if (popupConfig.checkbox && popupConfig.checkbox.position === 'center') {
        <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
      }
      @if (popupConfig.subtitle) {
        <div class="popup-subtitle">{{ popupConfig.subtitle }}</div>
      }
    </div>
    <div class="popup-textarea">
      <u-textarea
        [styles]="{ height: popupConfig.textAreaHeight + 'px' }"
        [maxLength]="popupConfig.textareaMaxLength"
        [placeholder]="popupConfig.textareaPlaceholder || ''"
        [model]="textContent"
        [autoResize]="false"
        [rows]="popupConfig.rows"
        [minHeight]="popupConfig.textAreaHeight"
        (onFocus)="focusTextArea()"
        #textArea
      ></u-textarea>
    </div>
    @if (popupConfig.footer) {
      <div class="popup-footer">
        <span class="popup-footer-text">{{ popupConfig.footer }}</span>
      </div>
    }
    <div class="popup-buttons">
      <u-button
        [label]="popupConfig.secondaryButtonLabel"
        type="secondary"
        (click)="closePopup()"
        [ngClass]="{ focus: currentFocus === 'leftButton' }"
        [elementSize]="'small'"
      ></u-button>
      <u-button
        [label]="popupConfig.primaryButtonLabel"
        type="primary"
        [disabled]="!isValid()"
        (click)="onPrimaryClick()"
        [ngClass]="{ focus: currentFocus === 'rightButton' }"
        [elementSize]="'small'"
      ></u-button>
    </div>
  </div>
</div>

<ng-template #checkboxTemplate>
  <div class="popup-checkbox-section">
    <u-checkbox
      [checked]="popupConfig.checkbox.value"
      [disabled]="popupConfig.checkbox.disabled"
      [binary]="true"
      (onChange)="onChangeCheckbox($event)"
    >
    </u-checkbox>
    <div [ngClass]="{ disabled: popupConfig.checkbox.disabled }" class="text">{{ popupConfig.checkbox.text }}</div>
  </div>
</ng-template>
