<div class="guide-header">
  <u-icon [model]="model?.integrationImgModel"></u-icon>
  <span class="title" [innerHTML]="model?.header"></span>
</div>
<ng-scrollbar visibility="hover" [style.width]="'100%'">
  <div class="guide-instructor">
    <div class="guide-steps">
      <div class="guide-step">
        <div class="step-wrapper">
          <span class="step-number">1.</span>
          <span class="install-label" [class.disable-install]="isOwnerOrAdmin === false" (click)="installLink()">{{
            model.installStepLabel
          }}</span>
          <span>{{ model.installStep }}</span>
          <div class="icon-wrapper" *ngIf="model.installStepIcon" [style.top]="'14px'">
            <u-icon [model]="{ type: 'font', value: model.installStepIcon }" [styles]="{ fontSize: '12px' }"></u-icon>
          </div>
          <div class="icon-wrapper vi-check" *ngIf="isAssistantInstalled" [style.top]="'14px'">
            <u-icon [model]="{ type: 'font', value: 'icon-check' }" [styles]="{ fontSize: '12px', color: 'white' }"></u-icon>
          </div>
        </div>
      </div>
      <div *ngFor="let step of model?.stepsArray; index as i" class="guide-step">
        <div class="step-wrapper">
          <span class="step-number">{{ i + START_INDEX }}.</span>
          <span [innerHTML]="step.stepText"></span>
          <div class="icon-wrapper" *ngIf="step.stepIcon">
            <u-icon [model]="{ type: 'font', value: step.stepIcon }" [styles]="{ fontSize: '12px' }"></u-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="guide-preview">
      <media-preview
        *ngIf="model?.video"
        [model]="mediaPreviewModel"
        [show]="true"
        [previewMode]="'side'"
        (click)="openFilePreviewPopup()"
      ></media-preview>
      <u-icon
        *ngIf="model?.image"
        [model]="{ type: 'img', value: { lightUrl: model.image.srcUrl } }"
        (click)="openFilePreviewPopup()"
      ></u-icon>
    </div>
  </div>
</ng-scrollbar>
