import { Injectable } from '@angular/core';
import { Commands, Downloads } from '@local/client-contracts';
import { ContextMenuItem } from '@shared/components';
import { ServicesRpcService } from '@shared/services';
import { KeyboardService, Shortcuts } from '@shared/services/keyboard.service';
import { ResourcesService } from '@shared/services/resources.service';
import { KeyName, keyCodes } from '@local/ts-infra';
import { SummaryPopupData } from '../components/summary-popup/summary-popup.component';
import { ResultContextMenuItem, SearchResults } from '../models/results-types';

export const actionToIcon: Record<string, string> = {
  copy: 'icon-copy',
  'copy-clipboard': 'icon-link2',
  open: 'icon-launch',
  'open-url': 'icon-network',
  'open-desktop': 'icon-cube',
  favorite: 'icon-star',
  unfavorite: 'icon-star-solid',
  download: 'icon-download2',
  copyEmail: 'icon-mail',
  copyPhone: 'icon-iphone',
  openPreview: 'icon-eye',
  remove: 'icon-delete',
  rename: 'icon-edit-frame',
  'make-copy': 'icon-copy-3',
  summary: 'icon-board-list',
  delete: 'icon-delete',
};

export interface ResultCommandBuilderParams {
  resourceId?: string;
  open?: { appUri: string; url: string };
  isFavorite?: { isFavorite: boolean };
  copy?: { text: string };
  download?: { url: string; grantType?: Downloads.GrantType };
  preview?: Commands.Preview<SearchResults>;
  dynamicCommands?: string[];
  removeCommands?: { [id: string]: boolean };
  summary?: SummaryPopupData;
  copyContent?: { text: string };
}

@Injectable({
  providedIn: 'root',
})
/** Build a presentable item for command */
export class ResultCommandBuilder {
  constructor(
    private services: ServicesRpcService,
    private resources: ResourcesService,
    protected keyboardService: KeyboardService
  ) {}

  async build(params: ResultCommandBuilderParams): Promise<ResultContextMenuItem[]> {
    let items = [];
    const appUri = params.open?.appUri;
    const url = params.open?.url;
    const downloadUrl = params.download?.url;
    const shortcuts: Shortcuts = await this.keyboardService.loadShortcuts<Shortcuts>(['_home']);

    if (url && !params.removeCommands?.open) {
      const openItems = await this.openItems(
        params.resourceId,
        appUri,
        url,
        shortcuts.open,
        params.preview && params.preview.previewable ? null : shortcuts.openPreview
      );
      items = [...items, ...openItems];
    }

    if (params.copy) {
      const copyTextShortcut = [...shortcuts.copy];
      copyTextShortcut.splice(1, 0, 'shift');
      items.push({
        id: 'copy',
        text: 'Copy',
        command: { type: 'copy-clipboard', value: params?.copy.text } as Commands.CopyClipboard,
        shortcut: copyTextShortcut,
        icon: { type: 'font-icon', value: actionToIcon['copy'] },
      });
    }

    if (url && !params.removeCommands?.copyUrl) {
      items.push({
        id: 'copy_clipboard',
        text: 'Copy URL',
        command: { type: 'copy-clipboard', value: url },
        shortcut: shortcuts.copy,
        icon: { type: 'font-icon', value: actionToIcon['copy-clipboard'] },
      });
    }

    if (!params.removeCommands?.favorite) {
      items.push({
        id: 'favorite',
        text: `${params.isFavorite.isFavorite ? 'Remove from' : 'Add to'} Favorites`,
        command: {
          type: 'change-favorite-status',
          status: params.isFavorite.isFavorite ? 'remove' : 'add',
        } as Commands.ChangeFavoriteStatus,
        shortcut: shortcuts?.favorite as Array<KeyName>,
        icon: { type: 'font-icon', value: actionToIcon[params.isFavorite ? 'unfavorite' : 'favorite'] },
      });
    }

    if (downloadUrl) {
      items.push({
        id: 'download',
        text: 'Download',
        data: { url: downloadUrl },
        command: { type: 'download-url', url: downloadUrl, grantType: params.download?.grantType },
        shortcut: shortcuts.download,
        icon: { type: 'font-icon', value: actionToIcon['download'] },
      });
    }

    if (params.preview && params.preview.previewable) {
      items.unshift({
        id: 'preview',
        text: 'Preview',
        command: { ...params.preview } as Commands.Preview<SearchResults>,
        shortcut: shortcuts?.openPreview as Array<KeyName>,
        icon: { type: 'font-icon', value: actionToIcon['openPreview'] },
      });
    }

    let deleteDynamicCommand;
    if (params.dynamicCommands && !params.removeCommands?.dynamicCommands) {
      params.dynamicCommands.forEach((command) => {
        switch (command) {
          case 'remove':
            items.push({
              id: 'remove',
              text: 'Remove',
              command: { type: 'dynamicCommands', value: 'remove' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: actionToIcon['remove'] },
            });
            break;
          case 'rename':
            items.push({
              id: 'rename',
              text: 'Rename',
              command: { type: 'dynamicCommands', value: 'rename' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: actionToIcon['rename'] },
            });
            break;
          case 'add-to-collection':
            if (!params.removeCommands?.addToCollection) {
              items.push({
                id: 'add_to_collection',
                text: 'Add to Collection',
                command: { type: 'add-to-collection' },
                icon: { type: 'font-icon', value: 'icon-collection' },
              });
            }
            break;
          case 'move-to-collection':
            items.push({
              id: 'move-to-collection',
              text: 'Move to Collection',
              command: { type: 'move-to-collection' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: 'icon-collection' },
            });
            break;
          case 'move-to-wiki':
            items.push({
              id: 'move-to-collection',
              text: 'Move to...',
              command: { type: 'move-to-collection' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: 'icon-exit' },
            });
            break;
          case 'make_a_copy':
            items.push({
              id: 'make_a_copy',
              text: 'Make a copy',
              command: { type: 'dynamicCommands', value: 'make-a-copy' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: 'icon-duplicate' },
            });
            break;
          case 'verification_details':
            items.push({
              id: 'verification_details',
              text: 'Verification details',
              command: { type: 'dynamicCommands', value: 'verification-details' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: 'icon-info' },
            });
            break;
          case 'verify':
            items.push({
              id: 'verify',
              text: 'Verify',
              command: { type: 'dynamicCommands', value: 'verify' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: 'icon-verify' },
            });
            break;
          case 'unverify':
            items.push({
              id: 'unverify',
              text: 'Unverify',
              command: { type: 'dynamicCommands', value: 'unverify' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: 'icon-unverify' },
            });
            break;
          case 'request_verification':
            items.push({
              id: 'request_verification',
              text: 'Request verification',
              command: { type: 'dynamicCommands', value: 'request-verification' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: 'icon-request' },
            });
            break;
          case 'delete':
            deleteDynamicCommand = {
              id: 'delete',
              text: 'Delete',
              command: { type: 'dynamicCommands', value: 'remove' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: actionToIcon['delete'] },
            };
            break;
          case 'delete-draft':
            items.push({
              id: 'delete-draft',
              text: 'Delete draft',
              command: { type: 'dynamicCommands', value: 'delete-draft' } as Commands.DynamicCommand,
              icon: { type: 'font-icon', value: actionToIcon['delete'] },
            });
            break;
          default:
            break;
        }
      });
    }

    if (params?.summary) {
      items.push({
        id: 'summary',
        text: 'Show summary',
        command: { ...params.summary, type: 'summary', position: 'center' } as Commands.Summary,
        icon: { type: 'font-icon', value: actionToIcon['summary'] },
      });
    }

    if (params?.copyContent) {
      items.push({
        id: 'copy-answer',
        text: 'Copy answer',
        command: { type: 'copy-clipboard', value: params?.copyContent?.text } as Commands.CopyClipboard,
        icon: { type: 'font-icon', value: 'icon-copy1' },
        shortcut: [keyCodes.commandOrControl, keyCodes.shift, keyCodes.c] as KeyName[],
      });
    }

    if (deleteDynamicCommand) {
      items.push(deleteDynamicCommand);
    }

    return items;
  }

  protected async openItems(
    resourceId: string,
    appUri: string,
    url: string,
    shortcut: Shortcuts['open'],
    previewShortcuts?: Shortcuts['openPreview']
  ): Promise<ContextMenuItem[]> {
    const shortcuts = previewShortcuts ? (previewShortcuts as Array<KeyName>) : (shortcut as Array<KeyName>);
    const open: ResultContextMenuItem = {
      id: 'open',
      text: 'Open',
      command: { type: 'open-url', url } as Commands.OpenUrl,
      icon: { type: 'font-icon', value: actionToIcon['open'] },
      shortcut: shortcuts,
    };

    if (!appUri) return [open];

    const { '0': desktopAppInstalled, '1': openWith } = await Promise.all([
      this.desktopAppByResourceId(resourceId),
      this.resources.openWith(resourceId),
    ]);

    if (!desktopAppInstalled) return [open];

    const openInDesktop: ResultContextMenuItem = {
      id: 'open_desktop',
      text: 'Open in Desktop App',
      command: { type: 'open-url', url: appUri } as Commands.OpenUrl,
      data: { openWith: 'app' as Commands.OpenWith },
      icon: { type: 'font-icon', value: actionToIcon['open-desktop'] },
    };

    const openInBrowser: ResultContextMenuItem = {
      id: 'open_browser',
      text: 'Open in Browser',
      command: { type: 'open-url', url } as Commands.OpenUrl,
      data: { openWith: 'app' as Commands.OpenWith },
      icon: { type: 'font-icon', value: actionToIcon['open-url'] },
    };

    switch (openWith) {
      case 'app':
        return [
          { ...openInDesktop, text: 'Open', shortcut: shortcuts },
          { ...openInBrowser, shortcut: ['shift', ...shortcuts] },
        ];
      case 'browser':
        return [
          { ...openInBrowser, text: 'Open' },
          { ...openInDesktop, shortcut: ['shift', ...shortcuts] },
        ];
      default: {
        return [openInBrowser, openInDesktop];
      }
    }
  }

  private async desktopAppByResourceId(resourceId: string): Promise<boolean> {
    return this.resources.desktopApp(resourceId);
  }
}
