import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
@Component({
  selector: 'u-sidebar',
  templateUrl: './u-sidebar.component.html',
  styleUrls: ['./u-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class USidebarComponent {
  private readonly TRANSITION_OPTIONS = '150ms cubic-bezier(0, 0, 0.2, 1)';
  @Input() transitionOptions = this.TRANSITION_OPTIONS;
  @Input() visible = false;
  @Input() content: TemplateRef<any>;
  @Input() header: TemplateRef<any>;
  @Input() footer: TemplateRef<any>;
  @Input() styles: any = {};
  @Input() styleClass: string;
  @Input() showCloseIcon: boolean;
  @Input() showMask: boolean;
  @Input() position: string = 'left';
  @Output() visibleChange = new EventEmitter<boolean>();

  onVisibleChange(visible: boolean) {
    this.visibleChange.emit(visible);
  }
}
