<div class="card flex justify-content-center">
  <p-sidebar
    [style]="styles"
    [styleClass]="styleClass"
    [visible]="visible"
    [transitionOptions]="transitionOptions"
    [showCloseIcon]="showCloseIcon"
    [modal]="showMask"
    [position]="position"
    (visibleChange)="onVisibleChange($event)"
  >
    <ng-template pTemplate="header"> <ng-container *ngIf="header" [ngTemplateOutlet]="header"></ng-container> </ng-template>
    <ng-template pTemplate="content">
      <ng-container *ngIf="content" [ngTemplateOutlet]="content"></ng-container>
    </ng-template>
    <ng-template pTemplate="footer">
      <ng-container *ngIf="footer" [ngTemplateOutlet]="footer"></ng-container>
    </ng-template>
  </p-sidebar>
</div>
