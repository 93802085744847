import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { Filters, NavTree, Stats, Verifications, Wiki } from '@local/client-contracts';
import { observable } from '@local/common';
import { generateId } from '@local/common-web';
import { generateTitleUrl } from '@local/ts-infra';
import { UiIconModel } from '@local/ui-infra';
import { DisplaySearchFilterValue, Filter } from '@shared/components/filters/models';
import { LogService, ServicesRpcService } from '@shared/services';
import { cloneDeep, isEmpty } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  takeUntil,
} from 'rxjs';
import { VerificationOptionsDetailsModel } from '../../components/verifications/verification-options-details/verification-options-details.component';
import { CollectionTag } from '../../views/collections-page/models';
import { CollectionPopupService } from '../../views/collections-page/services/collection-popup.service';
import { WikiPopupsService } from '../../views/collections-page/services/wiki-popups.service';
import { CollectionsUtilService } from '../collections-util.service';
import { FiltersService } from '../filters.service';
import { HubService } from '../hub.service';
import { WikiCardsRpcInvoker } from '../invokers/wiki-cards-rpc-invoker';
import { NavTreeService } from '../nav-tree.service';
import { ShowToasterService } from '../show-toaster.service';
import { VerificationsFyiHelperService } from '../verifications-fyi-helper-service';
import { Logger } from '@unleash-tech/js-logger';
import { Breadcrumb } from '@shared/services/breadcrumbs.service';
import { PreviewService } from '../preview.service';

type StatusFilterData = { id: string; title: string; icon: UiIconModel };
const STATUSES: StatusFilterData[] = [
  {
    id: 'verified',
    icon: {
      type: 'img',
      value: { lightUrl: './assets/verification/verified-light.svg' },
    },
    title: 'Verified',
  },
  {
    id: 'unverified',
    icon: {
      type: 'img',
      value: { lightUrl: './assets/verification/unverified-light.svg' },
    },
    title: 'Unverified',
  },
];
@Injectable()
export class WikiCardsService {
  readonly LIMIT_CARDS = 100;
  readonly FULL_PAGE_CARD_PARAM = 'a';
  readonly FOLDER_CARD_PARAM = 'activeFolder';

  private service: Wiki.CardService;
  private _updated$ = new ReplaySubject<string>(1);
  private _cardsPageFilters$ = new BehaviorSubject<Filter[]>(null);
  private _cardsTags$ = new BehaviorSubject<CollectionTag[]>(null);
  private _fetchRunning$ = new BehaviorSubject<string>(null);
  private lastListTimestamp: number;
  private LIST_CACHE_EXPIRES = 5 * 1000;
  private logger: Logger;

  @observable
  get cardsPageFilters$(): Observable<Filter[]> {
    return this._cardsPageFilters$;
  }

  @observable
  get cardsTags$(): Observable<CollectionTag[]> {
    return this._cardsTags$;
  }

  @observable
  get cardsCount$(): Observable<number> {
    return this.service.cardsCount$;
  }

  @observable
  get fetchRunning$(): Observable<string> {
    return this._fetchRunning$;
  }

  @observable
  get updated$(): Observable<string> {
    return this._updated$;
  }

  constructor(
    services: ServicesRpcService,
    private navTreeService: NavTreeService,
    private filtersService: FiltersService,
    private collectionPopupService: CollectionPopupService,
    private collectionsHelperService: CollectionsUtilService,
    private showToasterService: ShowToasterService,
    private verificationsFyiHelperService: VerificationsFyiHelperService,
    private wikiPopupsService: WikiPopupsService,
    private hubService: HubService,
    private previewService: PreviewService,
    logger: LogService
  ) {
    this.logger = logger.scope('WikiCardService');
    this.service = services.invokeWith(WikiCardsRpcInvoker, 'wikicards');
    this.service.updated$.subscribe((c) => this._updated$.next(c));
    this.service.tags$.subscribe((tags) => {
      this.createCardsTags(tags);
      this.updateFilterTags();
    });
    this.navTreeService.registerPrefixResolver('a/', (nodeId: string, nodes: NavTree.Node[]) => {
      const subject = new Subject<NavTree.Node>();
      this.getWikiNode(subject, nodeId, nodes);
      return subject;
    });
    this.service.all$.subscribe((all) => {
      const fyiDataItems = (all || []).map((c) => ({ id: c.id, title: c.title }));
      this.verificationsFyiHelperService.insertItems(fyiDataItems, 'card');
    });
    combineLatest([
      this.service.all$,
      this.filtersService.allFilters$.pipe(
        distinctUntilChanged((prev, current) => {
          return !this.isFiltersCard(current) && !this.isFiltersCard(prev);
        })
      ),
      this.filtersService.definitions$.pipe(distinctUntilChanged()),
    ]).subscribe(([_, allFilters, activeFilters]) => {
      this.createCardPageFilters(allFilters, activeFilters?.modifiedAt);
    });

    this.service.fetchRunning$.subscribe((fetch) => {
      this._fetchRunning$.next(fetch);
    });
  }

  private isFiltersCard(filters: Filters.Values) {
    if (isEmpty(filters)) {
      return false;
    }
    return Object.keys(filters).some((f) => f.startsWith('card-'));
  }

  @observable
  getVerificationByCardId$(id: string) {
    return this.service.getVerificationByCardId$(id);
  }

  getNativeQueryParams(paramKey: string) {
    const url = new URL(window.location.href);
    return url.searchParams.get(paramKey);
  }

  private async getWikiNode(subject: Subject<NavTree.Node>, nodeId: string, nodes: NavTree.Node[]) {
    const queryParams = this.getNativeQueryParams('purl');
    const popupMode = !!queryParams;
    const cardId = nodeId.split('-').slice(-1)[0];
    let resp = await this.list({ ids: [cardId], details: 'metadata', fromCache: true });
    const cacheCard = resp?.cards?.[0];
    if (cacheCard) {
      if (popupMode) {
        const newNode = nodes?.find((n) => n.id?.endsWith('-' + cacheCard?.collectionId));
        if (newNode) {
          subject.next(newNode);
        }
      } else {
        const newNode: NavTree.Node = { type: 'standard', title: cacheCard.title, id: nodeId };
        if (resp.cards.length === 1) {
          subject.next(newNode);
        }
      }
    }
    const now = Date.now();
    if (this.lastListTimestamp + this.LIST_CACHE_EXPIRES < now) {
      this.lastListTimestamp = now;
      resp = await this.list({ ids: [cardId], details: 'metadata', fromCache: false });
      const noCacheCard = resp?.cards?.[0];
      let newNodeNoCache: NavTree.Node;
      if (popupMode) {
        newNodeNoCache = nodes?.find((n) => n.id?.endsWith('-' + noCacheCard?.collectionId));
      } else {
        newNodeNoCache = { type: 'standard', title: noCacheCard?.title, id: nodeId };
      }
      if (cacheCard.modifiedTime !== noCacheCard?.modifiedTime) {
        subject.next(newNodeNoCache);
      }
    }
    subject.complete();
  }

  async refresh() {
    this.service.refresh();
  }

  async update(req: Wiki.UpsertCardRequest): Promise<string> {
    return this.service.update(req);
  }

  list(req: Wiki.ListCardRequest): Promise<Wiki.ListCardResponse> {
    return this.service.list(req);
  }

  getCard(cardId: string, useCache: boolean): Promise<Wiki.Card> {
    return this.service.getCard(cardId, useCache);
  }

  @observable
  getCard$(cardId: string): Observable<Wiki.Card> {
    return this.service.getCard$(cardId);
  }

  async search(request: Wiki.SearchRequest): Promise<Wiki.SearchResponse> {
    return this.service.search(request);
  }

  async filterSearch(request: Wiki.FilterSearchRequest): Promise<Wiki.FilterSearchResponse> {
    return this.service.filterSearch(request);
  }

  upsertStat(stat: Stats.Stat): Promise<void> {
    stat.id = generateId();
    return this.service.upsertStat(stat);
  }

  createVerificationRequest(message: string, cardId: string): Promise<void> {
    return this.service.createVerificationRequest({ message, objectId: cardId });
  }

  approveVerificationRequests(cardId: string) {
    this.service.deleteVerificationRequests(cardId);
  }

  getCardUrl(cardTitle: string, cardId: string, full = false) {
    const suffix = generateTitleUrl('a', cardTitle, cardId);
    if (!full) {
      return suffix;
    }
    return `${Config.baseUrl}/${suffix}`;
  }

  async createCardPageFilters(activeFilters?: Filters.Values, lastEdited?: Filters.FilterDefinition): Promise<Filter[]> {
    const filters: Filter[] = [];
    const tasks = [];

    const createdByTask = this.createFetcherFilter('card-createdBy', false, activeFilters, {
      title: 'Created by',
      icon: { type: 'font-icon', value: 'icon-user-circle' },
      sort: 'none',
      viewDetails: {
        isTwoLine: true,
        showClearAll: true,
      },
    });

    const tagsTask = this.createFetcherFilter('card-tags', true, activeFilters, {
      title: 'Tags',
      icon: { type: 'font-icon', value: 'icon-tag-label' },
      viewDetails: {
        showClearAll: true,
      },
    });

    const wikiNameTask = this.createFetcherFilter('card-wiki-name', false, activeFilters, {
      title: 'Wiki',
      icon: { type: 'font-icon', value: 'icon-wiki' },
      viewDetails: {
        isFullDetailLine: true,
        showItemIcon: true,
        showClearAll: true,
        tooltipLabel: 'title',
      },
    });

    const wikiFolderTask = this.createFetcherFilter('card-folder-name', false, activeFilters, {
      title: 'Folder',
      icon: { type: 'font-icon', value: 'icon-folder' },
      viewDetails: {
        isFullDetailLine: true,
        showItemIcon: true,
        showClearAll: true,
        tooltipLabel: 'title',
      },
    });

    tasks.push(createdByTask, tagsTask, wikiNameTask, wikiFolderTask);

    if (lastEdited) {
      tasks.push(this.filtersService.getSuggestions('', lastEdited?.name, 'box', { returnAllValues: true }, {}));
    }

    const [createdByFilter, tagsFilter, wikiNameFilter, wikiFolderFilter, lastEditedValues] = await Promise.all(tasks);

    filters.push(createdByFilter);

    if (lastEditedValues) {
      const lastEditedName = `card-${lastEdited?.name}`;
      filters.push({
        type: 'post',
        name: lastEditedName,
        title: lastEdited.title,
        icon: lastEdited.icon,
        picker: lastEdited.floating.picker,
        viewDetails: {
          isTwoLine: false,
          isFullDetailLine: true,
          placeholder: 'Last Edited...',
          oneValue: true,
          noCheckbox: true,
          hideOnSelect: true,
          showClearAll: true,
        },
        values: lastEditedValues?.map(
          (value) =>
            <DisplaySearchFilterValue>{
              id: value.id,
              value: value.title,
              title: value.title,
              subtitle: value.subtitle,
              filterName: lastEditedName,
            }
        ),
        disabled: false,
      });
    }

    filters.push(wikiNameFilter, wikiFolderFilter);

    if (tagsFilter) {
      filters.push(tagsFilter);
    }

    filters.push(...this.createStaticFilter());

    this._cardsPageFilters$.next(filters);

    return filters;
  }

  private async createFetcherFilter(
    filterName: string,
    relatedValues = false,
    activeFilters?: Filters.Values,
    filterSettings?: Omit<Filter, 'values' | 'name' | 'type' | 'picker'>
  ): Promise<Filter> {
    const request: Wiki.FilterSearchRequest = {
      filterName,
      fromCache: true,
      activeFilters,
      maxCount: this.LIMIT_CARDS,
    };

    const res = await this.filterSearch(request);
    const values = res?.results as DisplaySearchFilterValue[];

    if (relatedValues && !values?.length) {
      return;
    }

    return {
      ...filterSettings,
      name: filterName,
      type: 'post',
      picker: 'multi-select',
      values: values,
      disabled: false,
      valuesFetcher: async (term: string) => {
        const res = await this.filterSearch({ ...request, query: term });
        const values = res?.results as DisplaySearchFilterValue[];
        return this.setFiltersData(values, filterName);
      },
    };
  }

  private createStaticFilter() {
    const filters: Filter[] = [];

    //verification
    const statusVerificationName = 'verification-status';
    filters.push({
      type: 'post',
      name: statusVerificationName,
      title: 'Verification status',
      icon: { type: 'font-icon', value: 'icon-verify' },
      picker: 'multi-select',
      viewDetails: {
        isTwoLine: false,
        isFullDetailLine: true,
        oneValue: true,
        noCheckbox: true,
        hideOnSelect: true,
        showClearAll: true,
        showItemIcon: true,
      },
      values: STATUSES.map(
        (value) =>
          <DisplaySearchFilterValue>{
            id: value.id,
            value: value.id,
            title: value.title,
            filterName: statusVerificationName,
            icon: value.icon,
          }
      ),
    });

    //isRequested
    const isRequestedName = 'is-requested';
    filters.push({
      type: 'post',
      name: isRequestedName,
      title: 'is: Requested',
      picker: 'toggle',
      icon: { type: 'font-icon', value: 'icon-request' },
      values: [
        <DisplaySearchFilterValue>{
          id: 'isRequested',
          value: true,
          title: 'is: Requested',
          filterName: isRequestedName,
        },
      ],
      disabled: false,
      viewDetails: {
        showSplitLine: true,
      },
    });

    //Has a Draft
    const hasDraft = 'has-draft';
    filters.push({
      type: 'post',
      name: hasDraft,
      title: 'Has a Draft',
      picker: 'toggle',
      icon: { type: 'font-icon', value: 'icon-draft' },
      values: [
        <DisplaySearchFilterValue>{
          id: 'hasDraft',
          value: true,
          title: 'Has a Draft',
          filterName: hasDraft,
        },
      ],
      disabled: false,
      viewDetails: {
        showSplitLine: true,
      },
    });

    return filters;
  }

  private setFiltersData(values: Wiki.FilterCard[], filterName: string): DisplaySearchFilterValue[] {
    if (!values?.length) {
      return [];
    }
    const set = this.getSelectedFiltersSet(filterName);
    return values.map((v) => {
      const filterValue = v.value;
      const selected = set.has(filterValue);
      return {
        ...v,
        selected,
      } as DisplaySearchFilterValue;
    });
  }

  private getSelectedFiltersSet(filterName: string) {
    const list = this.filtersService.allFilters[filterName] || [];
    return new Set<string>(list);
  }

  private async updateFilterTags() {
    const filters = await firstValueFrom(this.cardsPageFilters$.pipe(filter((v) => !!v)));
    const tagsName = 'card-tags';
    const tagFilter = filters.find((f) => f?.name === tagsName);
    if (!tagFilter) {
      return;
    }

    tagFilter.values =
      this._cardsTags$.value?.map(
        (c) =>
          <DisplaySearchFilterValue>{
            id: c.value,
            value: c.value,
            title: c.value,
            subtitle: null,
            filterName: tagsName,
          }
      ) || [];

    this._cardsPageFilters$.next(filters);
  }

  private createCardsTags(res: Wiki.CardTags[]) {
    const tagsMap = new Map<string, number>();
    res.forEach((col) => {
      col?.tags?.forEach((tag) => {
        if (!tagsMap.has(tag)) {
          tagsMap.set(tag, 1);
        } else {
          tagsMap.set(tag, tagsMap.get(tag) + 1);
        }
      });
    });
    const collectionTag: CollectionTag[] = [];
    tagsMap.forEach((value, key) => {
      collectionTag.push({ value: key, amount: value });
    });
    this._cardsTags$.next(collectionTag);
  }

  openVerificationDetails(
    cardId: string,
    collection: Wiki.WikiCollection,
    policy: Verifications.Policy,
    verification?: Verifications.Verification,
    cardTitle?: string
  ) {
    const isEditor = this.collectionsHelperService.canEdit(collection);
    if (!policy) {
      policy = cloneDeep(collection?.policy);
    }
    const verificationModel: VerificationOptionsDetailsModel = {
      item: {
        accountId: collection.accountId,
        collection: collection,
        shareOptions: collection.shareOptions,
        isEditor,
        policy,
        verification,
        cardId,
        cardTitle,
      },
      type: 'card',
      title: 'Verification details',
      displayPermissionOption: false,
      displayWorkspacePermissions: false,
      telemetryName: 'verification',
      descriptionShareGroups: 'Select who will keep this information up-to date',
      displayPermissionAndRemove: true,
    };

    const verificationDetailsPopupRef = this.collectionPopupService.openVerificationDetailsPopup(verificationModel);
    verificationDetailsPopupRef.compInstance.savePopup.pipe(takeUntil(verificationDetailsPopupRef.destroy$)).subscribe((value) => {
      const { interval, verifiers, status } = value.policy;
      if (verifiers || interval) {
        if (!policy?.isOwner) {
          this.service.createPolicy({ ...(value.policy || {}), parentId: policy.id }, cardId);
        } else {
          this.service.updatePolicy({ ...(value.policy || {}), policyId: policy.id }, cardId);
        }
      }
      if (status) {
        this.updateVerificationStatus(status, [cardId], true);
      }
    });
    return verificationDetailsPopupRef;
  }

  changeVerificationStatus(status: 'Verify' | 'Unverify', cardsCount = 1) {
    return this.wikiPopupsService.changeVerificationStatus(status, cardsCount);
  }

  async updateVerificationStatus(
    status: Verifications.VerificationStatus,
    cardIds: string[],
    displaySuccessToaster?: boolean,
    clientPublishTime?: number
  ) {
    return this.service
      .updateVerificationStatus(status, cardIds, clientPublishTime)
      .then(() => {
        if (!displaySuccessToaster) {
          return;
        }
        const title = status === 'Verified' ? 'The card was successfully verified' : 'The card has been marked unverified';
        this.showToasterService.showToaster({
          id: 'verification-status-success',
          title,
          icon: { type: 'font', value: 'icon-check-circle' },
        });
      })
      .catch((err) => {
        const toaster = this.showToasterService.showToaster({
          id: 'verification-status-failed',
          title: 'Something went wrong Try again later',
          intent: 'primary',
          buttonText: 'Got it',
        });
        toaster.compInstance.invoke.subscribe(() => {
          toaster.destroy();
        });
        throw err;
      });
  }

  async openAllCards() {
    const url = '/cards';
    this.hubService.openUrl(url);
  }

  @observable
  listArchiveByCardId$(req: Wiki.CardArchiveRequest): Observable<Wiki.CardArchive[]> {
    return this.service.listArchiveByCardId$(req);
  }

  buildCardBreadcrumbs(path: Wiki.Path[], wikiUrl: string): Breadcrumb[] {
    const breadcrumbsItems: Breadcrumb[] = [];
    path?.forEach((path, index) => {
      breadcrumbsItems.push({
        title: path?.name,
        path: index === 0 ? wikiUrl : `${wikiUrl}?${this.FOLDER_CARD_PARAM}=${path.id}`,
        icon: {
          type: 'font-icon',
          value: index === 0 ? 'icon-wiki' : 'icon-folder',
        },
        notClickable: !wikiUrl,
      });
    });
    return breadcrumbsItems;
  }

  showSavedDraftToaster(cardId: string, title: string, displayOpenButton = true) {
    const toaster = this.showToasterService.showToaster({
      id: 'save-draft',
      content: 'Draft successfully saved',
      icon: { type: 'font', value: 'icon-check-circle' },
      buttonText: displayOpenButton ? 'Open' : '',
      intent: 'primary',
    });
    toaster.compInstance.invoke.subscribe(() => {
      this.previewService.setPreviewState('popup', 0, {
        type: 'result',
        filterType: 'wiki-local',
        id: cardId,
        view: { title: { text: title }, icon: null },
        action: { type: 'wiki card' },
        source: 'wiki-drafts',
      });
    });
  }
}
