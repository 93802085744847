import { Pipe, PipeTransform, OnDestroy, QueryList } from '@angular/core';
import { Observable, of, timer, concat, Subject } from 'rxjs';
import { tap, switchMap, startWith, takeUntil } from 'rxjs/operators';
import { Tooltip } from 'primeng/tooltip';

@Pipe({
  name: 'toggleTooltipText',
})
export class ToggleTooltipText implements PipeTransform, OnDestroy {
  private reset$ = new Subject<void>();
  private destroy$ = new Subject<void>();
  private isFirst: boolean = true;

  transform(
    originalText: string,
    newText: string,
    duration: number,
    tooltipInstance: Tooltip | QueryList<Tooltip>,
    trigger: boolean,
    index?: number
  ): Observable<string> {
    if (!trigger && this.isFirst) return of(originalText);
    this.isFirst = false;

    const selectedTooltip = tooltipInstance instanceof QueryList ? tooltipInstance.toArray()[index] : tooltipInstance;

    this.reset$.next();

    return this.reset$.pipe(
      startWith(0),
      takeUntil(this.destroy$),
      switchMap(() =>
        concat(
          of(newText).pipe(
            tap(() => {
              selectedTooltip.tooltipText = newText;
              Promise.resolve().then(() => selectedTooltip.show());
            })
          ),
          timer(duration).pipe(
            tap(() => {
              selectedTooltip.tooltipText = originalText;
              selectedTooltip.hide();
            }),
            switchMap(() => of(originalText))
          )
        )
      )
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
