import { Injectable } from '@angular/core';
import { observable } from '@local/common';
import {
  DatePickerPopupData,
  DatePickerPopupService,
  PopupRef,
  PopupService,
  UDatePickerComponent,
  DatePickerOption,
} from '@local/ui-infra';
import moment from 'moment';
import { Subject, Subscription, take } from 'rxjs';
import { RRule, Frequency } from 'rrule';
import { cloneDeep } from 'lodash';
import { geDayOfWeek, getDayOfWeekByNumber, getOrdinal, getOrdinalWeek, getWeekInMonth } from '../utils/custom-interval-utils';
import { CustomIntervalComponent, CustomIntervalData } from '../components/verifications/custom-interval/custom-interval.component';
import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { DateValue } from '@local/common-web';

export type IntervalType = 'week' | 'monthDate' | 'monthWeekday' | 'year' | 'day';
export type IntervalModel = { name: IntervalType; interval?: number; weekdays?: number[] };
export const VALID_INTERVAL_TYPES: string[] = ['week', 'monthDate', 'monthWeekday', 'year', 'day'];
export const isIntervalType = (name: string): name is IntervalType => VALID_INTERVAL_TYPES.includes(name);
export type OnSelectIntervalFunc = (titleInterval: string, rruleInterval: string) => void;

@Injectable({
  providedIn: 'root',
})
export class CustomIntervalService {
  private datePickerOptions: DatePickerOption[] = [];
  private _datePickerOptions$: Subject<DatePickerOption[]> = new Subject();
  private repeatDatePickerPopupRef: PopupRef<UDatePickerComponent, DatePickerPopupData>;
  private customIntervalPopupRef: PopupRef<CustomIntervalComponent, CustomIntervalData>;
  private selectedInterval: IntervalModel;
  private currentDate: Date;
  private valueSelectedSub: Subscription;
  private closeDatePickerPopup: Subscription;
  private logger: Logger;

  constructor(
    private datePickerPopupService: DatePickerPopupService,
    private popupService: PopupService,
    logger: LogService
  ) {
    this.logger = logger.scope('CustomIntervalService');
  }

  @observable
  private get datePickerOptions$() {
    return this._datePickerOptions$.asObservable();
  }

  openIntervalDatePickerPopup(onSelectInterval: OnSelectIntervalFunc) {
    if (this.repeatDatePickerPopupRef) {
      this.repeatDatePickerPopupRef.destroy();
    }
    this.currentDate = new Date();
    this.updateDatePickerOptions();
    const data: DatePickerPopupData = {
      filterTitle: 'Verified on',
      styleHeight: 85,
      selectedValue: { type: 'week', start: this.currentDate },
      datePickerOptions: this.datePickerOptions,
      datePickerOptionsSubject$: this.datePickerOptions$,
      defaultDatePickerType: 'week',
      minDate: moment().startOf('day').toDate(),
      optionsDropdownSettings: {
        maxWidth: 130,
        showSelectedItemTooltip: true,
        maxCharacters: 14,
      },
      centerPositionPopup: true,
      enableClosePopup: () => !this.customIntervalPopupRef,
    };
    this.repeatDatePickerPopupRef = this.datePickerPopupService.open('center', data, 'blur-2');
    this.valueSelectedSub = this.datePickerPopupService.valueSelected$.subscribe((value) => {
      if (value) {
        this.onDatePickerValueSelect(value);
      }
    });
    this.closeDatePickerPopup = this.datePickerPopupService.close$.subscribe(() => {
      const rruleSelected: string = this.generateRRule().toString();
      const rruleTitle: string = this.getDescriptionForRRule(rruleSelected);
      onSelectInterval(rruleTitle, rruleSelected);
      this.customIntervalPopupRef = null;
      this.repeatDatePickerPopupRef = null;
      this.selectedInterval = null;
      this.currentDate = null;
      this.valueSelectedSub.unsubscribe();
      this.valueSelectedSub = null;
      this.closeDatePickerPopup.unsubscribe();
      this.closeDatePickerPopup = null;
    });
  }

  getDescriptionForRRule(rruleString: string): string {
    try {
      const rule = RRule.fromString(rruleString);
      const options = rule.options;
      if (options.interval !== 1 && !options?.bysetpos?.[0]) {
        const description = rule.toText();
        return description;
      } else {
        switch (options.freq) {
          case RRule.YEARLY:
            const month = moment()
              .month(options.bymonth[0] - 1)
              .format('MMMM');
            const date = options.bymonthday[0];
            return `Annually on ${month} ${date}st`;
          case RRule.MONTHLY: {
            if (options?.bysetpos?.[0]) {
              const ordinalWeek = getOrdinal(options.bysetpos[0] - 1);
              const day = getDayOfWeekByNumber(options.byweekday[0] + 1);
              if (options.interval === 1) {
                return `Monthly on the ${ordinalWeek} ${day}`;
              }
              return `Every ${options.interval} months on the ${ordinalWeek} ${day}`;
            } else {
              return `Monthly on the ${options.bymonthday[0]}th`;
            }
          }
          case RRule.WEEKLY: {
            const days = options.byweekday.map((d) => getDayOfWeekByNumber(d + 1)).join(', ');
            return `Weekly on ${days}`;
          }
          case RRule.DAILY:
            return 'Daily';
          default: {
            this.logger.error('invalid rrule format', rruleString);
            return 'Invalid rrule';
          }
        }
      }
    } catch (error) {
      this.logger.error('invalid rrule format', error, rruleString);
      return 'Invalid rrule';
    }
  }

  private onDatePickerValueSelect(value: DateValue) {
    if (value.type === 'customSelect') {
      this.openCustomIntervalPopup();
      return;
    }
    if (value.type !== this.selectedInterval?.name && isIntervalType(value.type)) {
      if (!this.currentDate) {
        return;
      }
      const weekdays = [this.currentDate.getDay()];
      this.selectedInterval = { name: value.type, interval: 1, weekdays };
      this.updateDatePickerOptions();
    } else if (value.start && value.start !== this.currentDate) {
      this.currentDate = value.start;
      if (this.currentDate) {
        const weekdays = [this.currentDate.getDay()];
        this.selectedInterval.weekdays = weekdays;
      }
      this.updateDatePickerOptions();
    }
  }

  private updateDatePickerOptions(custom?: boolean, resetSelected?: boolean) {
    const date: Date = this.currentDate;
    if (!date) return;
    this.datePickerOptions = [];
    const weekday: string = geDayOfWeek(date);
    const month: string = moment(date).format('MMMM');
    const dateMonth: string = `${date.getDate()}st`;
    const ordinalWeek = getOrdinalWeek(this.currentDate);
    this.datePickerOptions = [
      { label: `Weekly on ${weekday}`, value: 'week', selected: resetSelected },
      { label: `Monthly on the ${dateMonth}`, value: 'monthDate' },
      { label: `Monthly on the ${ordinalWeek} ${weekday}`, value: 'monthWeekday' },
      { label: `Annually on ${month} ${dateMonth}`, value: 'year' },
    ];
    if (custom) {
      const RRuleCustom = this.generateRRule();
      const customLabel: string = this.getDescriptionForRRule(RRuleCustom.toString());
      this.datePickerOptions.push({ label: customLabel, value: 'custom', selected: true });
    }
    this.datePickerOptions.push({ label: 'Custom...', value: 'customSelect' });
    this._datePickerOptions$.next(cloneDeep(this.datePickerOptions));
  }

  private openCustomIntervalPopup() {
    if (this.customIntervalPopupRef) {
      this.customIntervalPopupRef.destroy();
    }
    if (!this.selectedInterval || !this.currentDate) {
      return;
    }
    const data: CustomIntervalData = {
      selectedDate: this.currentDate,
      optionType: this.selectedInterval.name,
    };
    this.customIntervalPopupRef = this.popupService.open<CustomIntervalComponent, CustomIntervalData>(
      'center',
      CustomIntervalComponent,
      data,
      {
        backdropStyle: 'blur-2',
      }
    );
    this.customIntervalPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.customIntervalPopupRef = null;
      const hasCustomOption = this.datePickerOptions.find((d) => d.value === 'custom');
      if (!hasCustomOption) {
        this.updateDatePickerOptions(false, true);
      }
    });
    this.customIntervalPopupRef.compInstance.selectCustomInterval.subscribe((model) => {
      if (!model.name) {
        return;
      }
      this.selectedInterval = model;
      this.updateDatePickerOptions(true);
      this.customIntervalPopupRef = null;
    });
  }

  private generateRRule(): RRule {
    let rrule: RRule;
    const { name, interval, weekdays } = this.selectedInterval;
    const weekdayValues = weekdays.map((dayIndex) => (dayIndex + 6) % 7);
    switch (name) {
      case 'week':
        rrule = new RRule({
          freq: Frequency.WEEKLY,
          byweekday: weekdayValues,
          interval,
        });
        break;
      case 'monthDate':
        const dayOfMonth = this.currentDate.getDate();
        rrule = new RRule({
          freq: Frequency.MONTHLY,
          bymonthday: dayOfMonth,
          interval,
        });
        break;
      case 'monthWeekday':
        const weekInMonth = getWeekInMonth(this.currentDate);
        const bysetpos = [weekInMonth];
        rrule = new RRule({
          freq: Frequency.MONTHLY,
          byweekday: weekdayValues,
          bysetpos,
          interval,
        });
        break;
      case 'year':
        rrule = new RRule({
          freq: Frequency.YEARLY,
          bymonth: this.currentDate.getMonth() + 1,
          bymonthday: this.currentDate.getDate(),
          interval,
        });
        break;
      case 'day':
        rrule = new RRule({
          freq: Frequency.DAILY,
          interval,
        });
        break;
    }
    return rrule;
  }
}
