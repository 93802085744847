import { Component, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { isEqual } from 'lodash';
import { UiIconModel } from '../../types/ui.icon.model';

@Component({
  selector: 'u-input-list',
  templateUrl: './u-input-list.component.html',
  styleUrls: ['./u-input-list.component.scss'],
})
export class UInputListComponent {
  disableAddBtn = signal(true);
  textList: WritableSignal<string[]> = signal(['']);
  focusLastInput: boolean;
  clearIcon: UiIconModel = {
    type: 'font',
    value: 'icon-times-large',
  };
  @Input() limit = 3;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() limitMsg = '';
  @Input() state: 'disabled' | 'readonly';
  @Output() onUpdatedList = new EventEmitter<string[]>();
  @Input() set initialList(value: string[]) {
    if (!value?.length || isEqual(value, this.textList())) {
      return;
    }
    const validatedList = this.removeEmptyStringFromArr(value);
    this.textList.set(validatedList);
    if (this.textList.length < this.limit) {
      this.disableAddBtn.set(false);
    }
  }
  trackByIndex(index: number): number {
    return index;
  }

  onChangeItem(newValue: string, index: number): void {
    this.textList.update((list) => {
      const updatedList = [...list];
      updatedList[index] = newValue;
      return updatedList;
    });
    this.updateAddButtonState();
    this.onUpdatedList.emit(this.textList());
  }
  updateAddButtonState() {
    if (this.checkArrayHasEmptyString()) {
      this.disableAddBtn.set(true);
    } else {
      this.disableAddBtn.set(false);
    }
  }
  private removeEmptyStringFromArr(arr: string[]) {
    const filteredArr = arr.filter((item: string) => item.trim() !== '');
    return filteredArr.length === 0 ? [''] : filteredArr;
  }
  private checkArrayHasEmptyString(): boolean {
    return this.textList().some((conv) => !conv.trim());
  }

  onAddItem() {
    this.disableAddBtn.set(true);
    const currentList = this.textList();
    if (currentList.length < this.limit) {
      this.textList.update((currentList) => [...currentList, '']);
    }
    this.focusLastInput = true;
    this.onUpdatedList.emit(this.textList());
  }

  onRemoveItem(index: number): void {
    const currentList = this.textList();
    if (currentList.length === 1) {
      this.textList.update(() => ['']);
    } else {
      this.disableAddBtn.set(false);
      this.textList.update((current) => {
        const updated = [...current];
        updated.splice(index, 1);
        return updated;
      });
    }
    this.updateAddButtonState();
    this.onUpdatedList.emit(this.textList());
  }
}
