import { Stats, Verifications, Wiki } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class WikiCardsRpcInvoker implements Wiki.CardService {
  @invoker
  get updated$(): Observable<string> {
    return;
  }

  @invoker
  get tags$(): Observable<Wiki.CardTags[]> {
    return;
  }

  @invoker
  update(req: Wiki.UpsertCardRequest): Promise<string> {
    return;
  }

  @invoker
  list(req: Wiki.ListCardRequest): Promise<Wiki.ListCardResponse> {
    return;
  }

  @invoker
  getCard(cardId: string, useCache: boolean): Promise<Wiki.Card> {
    return;
  }

  @invoker
  getCard$(cardId: string): Observable<Wiki.Card> {
    return;
  }

  @invoker
  listArchiveByCardId$(req: Wiki.CardArchiveRequest): Observable<Wiki.CardArchive[]> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }

  @invoker
  updatePolicy(updatePolicy: Verifications.UpdatePolicy, cardId: string): Promise<void> {
    return;
  }

  @invoker
  createPolicy(createPolicy: Verifications.CreatePolicy, cardId: string): Promise<void> {
    return;
  }

  @invoker
  updateVerificationStatus(status: Verifications.VerificationStatus, cardIds: string[], clientPublishTime?: number): Promise<void> {
    return;
  }

  @invoker
  async createVerificationRequest(request: Verifications.CreateVerificationRequest): Promise<void> {
    return;
  }

  @invoker
  async deleteVerificationRequests(cardId: string): Promise<void> {
    return;
  }

  @invoker
  async upsertStat(stat: Stats.Stat): Promise<void> {
    return;
  }

  @invoker
  get all$(): Observable<Wiki.Card[]> {
    return;
  }

  @invoker
  get cardsCount$(): Observable<number> {
    return;
  }

  @invoker
  get fetchRunning$(): Observable<string> {
    return;
  }

  @invoker
  async search(request: Wiki.SearchRequest): Promise<Wiki.SearchResponse> {
    return;
  }

  @invoker
  async filterSearch(request: Wiki.FilterSearchRequest): Promise<Wiki.FilterSearchResponse> {
    return;
  }

  @invoker
  getVerificationByCardId$(id: string): Observable<Verifications.Verification> {
    return;
  }
}
