import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { Accounts, Collections, Permissions, Verifications, Workspace } from '@local/client-contracts';
import { KeyName, isEnterKey } from '@local/ts-infra';
import { PopupRef, UIconModule } from '@local/ui-infra';
import { DateFormat } from '@shared/consts';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isProdEnv, randomColor } from '@shared/utils';
import { getDefaultProfile } from '@shared/utils/set-icon.util';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { RRule } from 'rrule';
import { takeUntil } from 'rxjs';
import { WorkspaceMemberDetails, WorkspaceMemberDetailsType } from 'src/app/bar/services/avatar-list.service';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { SuggestionsList } from 'src/app/bar/services/identities-suggestions.service';
import { WikiCardsVerificationsService } from 'src/app/bar/services/wikis/wiki-cards-verifications.service';
import { OptionsPopupComponent } from 'src/app/bar/views/hub/components/launcher-avatar-popup/options-popup.component';
import { CustomIntervalService } from '../../../services/custom-interval.service';
import { ScopePermissionsOptions } from '../../share-options-permissions/share-options-permissions.component';

export interface VerificationOptionsDetailsModel {
  item: DetailsModel;
  title: string;
  displayPermissionOption: boolean;
  displayWorkspacePermissions: boolean;
  telemetryName: string;
  descriptionShareGroups: string;
  type: 'card' | 'wiki';
  updateGroupAndMembers?: string;
  scopePermissionOptions?: ScopePermissionsOptions[];
  displayPermissionAndRemove: boolean;
}

export interface DetailsModel {
  accountId: string;
  collection: Collections.Collection;
  shareOptions: Permissions.ShareOptions;
  isEditor: boolean;
  policy: Verifications.Policy;
  verification?: Verifications.Verification;
  cardId?: string;
  cardTitle?: string;
}

export type ViewModelVerifier = {
  invited: WorkspaceMemberDetails;
  type: WorkspaceMemberDetailsType;
  backgroundColor?: string;
  scopePermissions: string;
};

export type intervalModel = {
  name: string;
  frequency: string;
  interval?: number;
  isCustom?: boolean;
};

export interface UpdatedVerificationPolicy {
  verifiers?: Verifications.Verifiers;
  verifiersWithoutPermissions?: Verifications.Verifiers;
  interval?: string;
  status?: Verifications.VerificationStatus;
  disabled?: boolean;
}

@Component({
  selector: 'verification-options-details',
  templateUrl: './verification-options-details.component.html',
  styleUrls: ['./verification-options-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationOptionsDetailsComponent implements OnInit, OnDestroy {
  private readonly MAX_POPUP_HEIGHT = 350;
  private readonly DEFAULT_INTERVAL_OPTION_NAME = 'Every 3 months';
  INTERVAL_OPTIONS: intervalModel[] = [
    { name: 'Every week', frequency: 'WEEKLY', interval: 1 },
    { name: 'Every 2 weeks', frequency: 'WEEKLY', interval: 2 },
    { name: 'Every month', frequency: 'MONTHLY', interval: 1 },
    { name: 'Every 3 months', frequency: 'MONTHLY', interval: 3 },
    { name: 'Every 6 months', frequency: 'MONTHLY', interval: 6 },
    { name: 'Every year', frequency: 'YEARLY', interval: 1 },
    { name: 'Advanced', frequency: null },
  ];
  readonly MEMBER_AVATAR_FALLBACK = getDefaultProfile();
  private initialRRuleString: string;
  private rruleString: string;
  private verificationOn: boolean;
  private keyHandlerId: string;
  private workspace: Workspace.Workspace;
  private initialInvited: ViewModelVerifier[] = [];
  private initialStatus: Verifications.VerificationStatus;
  private isBlurInput: boolean;
  private isAdvancedSelectedInterval: boolean;
  initialInterval: intervalModel;
  suggestions: SuggestionsList = [];
  invitedSuggestions: ViewModelVerifier[] = [];
  model: VerificationOptionsDetailsModel;
  currentStatus: Verifications.VerificationStatus;
  selectedStatusOption: { label: string; icon: UIconModule };
  lastVerifier: Accounts.WorkspaceAccount;
  statusChangedAt: string;
  verifiedUntil: string;
  requestLabel = 'Request verification';

  @Output() savePopup = new EventEmitter<{ policy: UpdatedVerificationPolicy }>();

  @ViewChild('VerificationPopup') VerificationPopupRef: ElementRef;
  @ViewChild('scrollContent') scrollContentRef: ElementRef;

  get heightPopup(): number {
    return this.VerificationPopupRef?.nativeElement?.offsetHeight;
  }

  get verification() {
    return this.model?.item?.verification;
  }

  get policy() {
    return this.model?.item?.policy;
  }

  get isPolicy(): boolean {
    return this.policy?.disabled === false;
  }

  get disabledState() {
    return !this.verificationOn;
  }

  get isEditor() {
    return this.model?.item?.isEditor;
  }

  get itemType() {
    return this.model.item?.collection?.kind === 'Wiki' ? 'Wiki' : 'Collection';
  }

  get toggleBtnText() {
    return `Enable verification process for the ${this.itemType}`;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private collectionsService: CollectionsService,
    private customIntervalService: CustomIntervalService,
    private wikiCardsVerificationsService: WikiCardsVerificationsService,
    @Optional() private ref: PopupRef<OptionsPopupComponent, VerificationOptionsDetailsModel>
  ) {}

  ngOnInit(): void {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 10);
    if (!isProdEnv()) {
      this.INTERVAL_OPTIONS.push(
        { name: 'Every 15 minutes', frequency: 'MINUTELY', interval: 15 },
        { name: 'Every 2 minutes', frequency: 'MINUTELY', interval: 2 }
      );
    }

    this.initData();
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
  }

  private async initData() {
    this.model = this.ref?.data;
    this.verificationOn = this.isPolicy;

    if (this.policy?.interval) {
      this.initialInterval = this.getRRuleDetails(this.policy.interval);
      this.initialRRuleString = this.rruleString = `RRULE:${this.policy.interval}`;
    } else {
      this.initialInterval = this.INTERVAL_OPTIONS.find((option) => option.name === this.DEFAULT_INTERVAL_OPTION_NAME);
      this.initialRRuleString = this.onChangeInterval(this.initialInterval);
    }
    this.initialStatus = this.currentStatus = this.verification?.status;

    this.workspace = this.collectionsService.workspace;

    this.lastVerifier = this.workspace?.accounts.find((a) => {
      return a.id === this.verification?.verifierId;
    });
    this.statusChangedAt = moment(this.verification?.statusChangedAt).format(DateFormat.FULL_DATE);
    this.verifiedUntil = moment(this.verification?.verifiedUntil).format(DateFormat.FULL_DATE);

    this.suggestions = [
      {
        name: 'Members',
        items: this.workspace.accounts.map((a) => {
          if (this.policy?.verifiers?.accountIds.includes(a.id)) {
            return { ...a, disable: true, type: 'account' };
          }
          return { ...a, type: 'account' };
        }),
      },
      {
        name: 'Groups',
        items: this.workspace.groups.map((a) => {
          if (this.policy?.verifiers?.groupIds.includes(a.id)) {
            return { ...a, disable: true, type: 'group' };
          }
          return { ...a, type: 'group' };
        }),
      },
    ];

    const verifiers = this.policy?.verifiers;
    if (!verifiers?.groupIds?.length && !verifiers?.accountIds?.length) {
      const creator = this.workspace.accounts.find((w) => w.id === this.model?.item.accountId);
      this.invitedSuggestions.push({ invited: creator, type: 'account', scopePermissions: `${this.itemType} Owner` });
    } else {
      const accounts = this.workspace.accounts.filter((a) => {
        return this.policy?.verifiers?.accountIds.includes(a.id);
      });
      const ownerAccount = accounts.find((a) => a.id === this.model?.item.accountId);
      const sharedAccounts = accounts.filter((a) => a.id !== this.model?.item.accountId);

      const groups = this.workspace.groups.filter((g) => {
        return this.policy?.verifiers?.groupIds.includes(g.id);
      });

      if (ownerAccount) {
        this.invitedSuggestions.push({
          invited: ownerAccount,
          type: 'account',
        } as ViewModelVerifier);
      }

      this.invitedSuggestions.push(
        ...sharedAccounts.map((value) => {
          return {
            invited: value,
            type: 'account',
            backgroundColor: randomColor(),
          } as ViewModelVerifier;
        }),
        ...groups.map((value) => {
          return {
            invited: value,
            type: 'group',
            backgroundColor: randomColor(),
          } as ViewModelVerifier;
        })
      );
      this.initialInvited = cloneDeep(this.invitedSuggestions);

      const invitedSuggestionWithScopes = [];
      for (const invited of this.invitedSuggestions) {
        let scopePermissions: string;
        if (invited.type === 'account') {
          scopePermissions = await this.getAccountPermission(invited.invited.id);
        } else if (['group', 'external-group'].includes(invited.type)) {
          scopePermissions = this.getGroupPermission(invited.invited.id);
        }
        invitedSuggestionWithScopes.push({ ...invited, scopePermissions });
      }

      this.invitedSuggestions = cloneDeep(invitedSuggestionWithScopes);
    }

    this.initialInvited = cloneDeep(this.invitedSuggestions);
    this.cdr.markForCheck();
  }

  private isModelChanged() {
    return (
      !isEqual(this.invitedSuggestions, this.initialInvited) ||
      this.rruleString !== this.initialRRuleString ||
      this.currentStatus !== this.initialStatus ||
      this.verificationOn !== this.isPolicy
    );
  }

  private async getAccountPermission(id: string): Promise<string> {
    if (this.model.item.accountId === id) {
      return `${this.itemType} Owner`;
    }
    const collectionId = (this.model as VerificationOptionsDetailsModel).item.collection?.id;
    const scopePermissions = await this.collectionsService.getScopes(collectionId, id);
    if (scopePermissions.scopes.includes('write')) {
      return 'Editor';
    } else if (scopePermissions.scopes.includes('read')) {
      return 'Viewer';
    }
    return '';
  }

  private getGroupPermission(id: string) {
    const permissions = this.model.item.shareOptions.permissions;
    if (permissions.find((p) => p.scope === 'write')?.groupIds.includes(id)) {
      return 'Editor';
    } else if (permissions.find((p) => p.scope === 'read')?.groupIds.includes(id)) {
      return 'Viewer';
    }
    return '';
  }

  async updateSelectedSuggestion(event: ViewModelVerifier) {
    if (event.type === 'account') {
      event.scopePermissions = await this.getAccountPermission(event.invited.id);
    } else {
      event.scopePermissions = this.getGroupPermission(event.invited.id);
    }
    this.invitedSuggestions.push(event);
    this.invitedSuggestions = cloneDeep(this.invitedSuggestions);
    this.cdr.markForCheck();
  }

  removeSuggestion(event: ViewModelVerifier[]) {
    this.invitedSuggestions = cloneDeep(event);
    this.cdr.markForCheck();
  }

  onChangeInterval(event: intervalModel): string {
    this.INTERVAL_OPTIONS = this.INTERVAL_OPTIONS.filter((op) => !op.isCustom);
    this.isAdvancedSelectedInterval = event.name === 'Advanced';
    if (this.isAdvancedSelectedInterval) {
      return;
    }
    const selectedInterval = cloneDeep(event);
    const rruleOptions = {
      freq: RRule[selectedInterval.frequency],
      interval: selectedInterval.interval,
    };
    const rrule = new RRule(rruleOptions);

    this.rruleString = rrule.toString();
    this.cdr.markForCheck();
    return this.rruleString;
  }

  private openAdvancedInterval() {
    this.customIntervalService.openIntervalDatePickerPopup((titleInterval, rruleInterval) => {
      if (!titleInterval || !rruleInterval) {
        return;
      }
      this.rruleString = rruleInterval;
      const customOption = this.addCustomOption(titleInterval);
      this.initialInterval = customOption;
      this.cdr.markForCheck();
    });
  }

  private addCustomOption(rruleText: string): intervalModel {
    const customOption: intervalModel = { name: `Repeat: ${rruleText}`, frequency: null, isCustom: true };
    this.INTERVAL_OPTIONS.push(customOption);
    return customOption;
  }

  private getRRuleDetails(rruleString: string) {
    const rrulePattern = /FREQ=([^;]+);INTERVAL=(\d+)/;
    const [, frequency, interval] = rruleString.match(rrulePattern) || [];
    if (!frequency || !interval) {
      return this.generateCustomInterval(rruleString);
    }

    const intervalOption = this.INTERVAL_OPTIONS.find((i) => {
      return i.frequency === frequency && i.interval === parseInt(interval, 10);
    });
    return intervalOption || this.generateCustomInterval(rruleString);
  }

  private generateCustomInterval(rruleString: string): intervalModel {
    if (!rruleString) return;
    const rruleText: string = this.customIntervalService.getDescriptionForRRule(rruleString);
    const customOption = this.addCustomOption(rruleText);
    return customOption;
  }

  onToggleButtonChange(event) {
    this.verificationOn = event.checked;
  }

  onChangeStatus() {
    this.currentStatus = this.currentStatus === 'Verified' ? 'Unverified' : 'Verified';
    this.onSaveClick();
  }

  private buildCreatePolicyModel() {
    const accountIds: string[] = [];
    const groupIds: string[] = [];
    const accountIdsWithoutPermissions: string[] = [];
    const groupIdsWithoutPermissions: string[] = [];
    this.invitedSuggestions?.forEach((invited) => {
      if (['group', 'external-group'].includes(invited.type)) {
        groupIds.push(invited.invited.id);
        if (!invited.scopePermissions) {
          groupIdsWithoutPermissions.push(invited.invited.id);
        }
      } else if (invited.type === 'account') {
        accountIds.push(invited.invited.id);
        if (!invited.scopePermissions) {
          accountIdsWithoutPermissions.push(invited.invited.id);
        }
      }
    });

    const policy: UpdatedVerificationPolicy = {};
    if (!this.policy) {
      return {
        interval: this.rruleString,
        verifiers: { accountIds, groupIds },
        verifiersWithoutPermissions: { accountIds: accountIdsWithoutPermissions, groupIds: groupIdsWithoutPermissions },
        disabled: !this.verificationOn,
      };
    }
    if (!isEqual(this.invitedSuggestions, this.initialInvited)) {
      policy.verifiers = { accountIds, groupIds };
      policy.verifiersWithoutPermissions = { accountIds: accountIdsWithoutPermissions, groupIds: groupIdsWithoutPermissions };
    }
    if (this.rruleString !== this.initialRRuleString) {
      policy.interval = this.rruleString;
    }
    if (this.model?.type === 'card') {
      if (this.currentStatus !== this.initialStatus) {
        policy.status = this.currentStatus;
      }
    } else {
      if (this.verificationOn !== this.isPolicy) {
        policy.disabled = !this.verificationOn;
      }
    }

    return policy;
  }

  onSaveClick() {
    if (!this.isModelChanged()) {
      return;
    }

    const policy = this.buildCreatePolicyModel();
    this.savePopup.emit({ policy });

    this.ref.destroy();
  }

  onIntervalHide() {
    if (this.isAdvancedSelectedInterval) {
      this.openAdvancedInterval();
    }
  }

  onRequestClick() {
    if (this.requestLabel === 'Requested') {
      return;
    }
    const requestVerificationPopupRef = this.wikiCardsVerificationsService.openRequestVerificationPopup();
    requestVerificationPopupRef.compInstance.primaryButton.pipe(takeUntil(requestVerificationPopupRef.destroy$)).subscribe((message) => {
      this.wikiCardsVerificationsService.createVerificationRequest(this.model?.item.cardId, message, this.model.item.cardTitle);
      this.requestLabel = 'Requested';
      this.cdr.markForCheck();
    });
  }

  onClosePopup() {
    if (!this.isModelChanged()) {
      this.ref.destroy();
      return;
    }
    this.onSaveClick();
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];
    if (key === 'escape') {
      if (this.isBlurInput) {
        this.isBlurInput = false;
      } else {
        this.isBlurInput = true;
      }
      event.stopPropagation();
      return;
    }
    if (!isEnterKey(key)) {
      event.stopPropagation();
    }
  }
}
