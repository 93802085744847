import { FilterInvoker, PopupRef, PopupService, INIT_POSITION_POPUP } from '@local/ui-infra';
import { take } from 'rxjs';
import {
  TelemetryEvent,
  SelectedItem,
  SelectedItemPopupComponent,
  SelectedItemModel,
} from '../../components/selected-item-popup/selected-item-popup.component';
import { SelectedItemPopupData } from '../../components/selected-item-popup/models/select-item-base';
import { WorkspacesService } from '../workspaces.service';
import { Injectable } from '@angular/core';

export type SelectItemData = { target: string; selectedItem: any };
export type OpenSelectPopupType =
  | 'add-collection-to-tab'
  | 'add-to-collection-popup'
  | 'move-to-collection-popup'
  | 'move-to-wiki-popup'
  | 'pin-collection-to-tab'
  | 'move-header-widget'
  | 'move-post-widget';

export interface OpenSelectPopupModel {
  id?: string;
  type: OpenSelectPopupType;
  data: SelectedItemPopupData;
  options?: { doSetup?: boolean; data?: any };
}
@Injectable()
export abstract class SelectItemsBasePopupService<T extends SelectedItemPopupData> {
  protected selectedItemPopupRef: PopupRef<SelectedItemPopupComponent, SelectedItemPopupData> = null;
  protected selectedItem: SelectedItemModel;
  protected data: T;
  protected allowOpenCollectionToasterButton = true;
  protected isOwnerOrAdmin: boolean;

  get isPopupActive() {
    return !!this.selectedItemPopupRef;
  }

  constructor(
    protected popupService: PopupService,
    protected workspacesService: WorkspacesService
  ) {
    const windowMode = document.querySelector('body')?.attributes['window-mode']?.value === 'true';
    if (windowMode) {
      this.allowOpenCollectionToasterButton = !windowMode;
    }
    this.workspacesService.ownerOrAdmin$.pipe(take(1)).subscribe((s) => {
      this.isOwnerOrAdmin = s;
    });
  }

  protected abstract init();
  protected abstract onPrimaryButtonClick(selectedItem?: SelectedItemModel);
  protected abstract onTelemetryEvent(event: TelemetryEvent);
  protected filterItems: FilterInvoker;
  protected isInvalidItem = (selectedItem: SelectedItemModel) => false;
  protected getPopupDataOptions(): SelectedItemPopupData {
    return {};
  }

  async openSelectedItemPopup(data: T) {
    if (this.selectedItemPopupRef) {
      this.selectedItemPopupRef.destroy();
    }
    this.data = data;

    // abstract implementation
    await this.init();

    const defaultOptions = this.getPopupDataOptions();
    const autoCompleteSettings = {
      showArrow: true,
      allowIconInViewList: true,
      displayDismissButton: true,
      displayCreateNew: true,
      createNewOption: true,
      ...defaultOptions.autoCompleteSettings,
    };
    this.selectedItemPopupRef = this.popupService.open<SelectedItemPopupComponent, SelectedItemPopupData>(
      'center',
      SelectedItemPopupComponent,
      {
        allowPrimaryClick: true,
        ...data,
        ...defaultOptions,
        autoCompleteSettings,
        filterInvoker: this.filterItems,
        isInvalidItem: (selectedItem?: SelectedItem) => this.isInvalidItem(selectedItem),
      },
      { closeOnClickOut: true, position: INIT_POSITION_POPUP, backdropStyle: 'blur-2' }
    );
    this.selectedItemPopupRef.compInstance.primaryButton.subscribe((value: { selectedItem: SelectedItemModel }) => {
      // abstract implementation
      this.onPrimaryButtonClick(value.selectedItem);
    });
    this.selectedItemPopupRef.compInstance.onSelect.subscribe((selectedItem) => {
      this.selectedItem = selectedItem;
    });
    this.selectedItemPopupRef.compInstance.onTelemetryEvent.subscribe((data) => {
      // abstract implementation
      this.onTelemetryEvent(data);
    });
    this.selectedItemPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.selectedItemPopupRef = null;
    });
    return this.selectedItemPopupRef;
  }
}
