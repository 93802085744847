import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Collections } from '@local/client-contracts';
import { isStaticCollection } from '@local/common-web';
import { DynamicComponentItem } from '@local/ui-infra';
import { LogService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { KeyValueParams, extractParamsFromUrl } from '@shared/utils/url-util';
import { Logger } from '@unleash-tech/js-logger';
import { isEqual, isString } from 'lodash';
import { filter, firstValueFrom } from 'rxjs';
import { AttributesResolver } from 'src/app/routes/attributes-resolver.service';
import { SearchResults } from '../views';
import { CollectionViewComponent } from '../views/collections-page/components/collection-view/collection-view.component';
import { WikiCardPopupComponent } from '../views/collections-page/components/wiki-card-popup/wiki-card-popup.component';
import { BlobPreviewService } from '../views/collections-page/services/blobs-preview.service';
import { WikiCardPreviewService } from '../views/collections-page/services/wiki-card-preview.service';
import { HubComponent } from '../views/hub';
import { FilePreviewPopupComponent } from '../views/preview/file-preview/components/file-preview-popup/file-preview-popup.component';
import { getPreviewTypeByPreviewKey } from '../views/preview/helpers/preview.utils';
import { MailPreviewPopupComponent } from '../views/preview/mail-preview/components/mail-preview-popup/mail-preview-popup.component';
import { PeoplePreviewPopupComponent } from '../views/preview/people-preview/components/people-preview-popup/people-preview-popup.component';
import { PeopleService } from '../views/preview/people-preview/services/people.service';
import { ResourcePreviewData } from '../views/preview/preview-hub/preview-hub.component';
import { PreviewKey, PreviewType } from '../views/results/models/view-filters';
import { CollectionsService } from './collections.service';
import { NEW_CARD_URL_PARAM, PreviewService } from './preview.service';
import { ResultsService } from './results.service';
import { WikiCardSaveFlowService } from '../views/collections-page/services/wiki-card-save-flow.service';
import { WikiItemSelectionPopupService } from '../views/collections-page/services/wiki-item-selection-popup.service';
import { WikiCardPopupModel } from '../views/collections-page/models/wiki-card-model';

@Injectable({
  providedIn: 'root',
})
export class UrlPreviewService {
  private logger: Logger;
  private parsedValues: KeyValueParams = null;
  private collections: Collections.Collection[] = [];
  readonly PATH_PREVIEW_URL = ':param/:id/:link/:resource';

  constructor(
    private routerService: RouterService,
    private documentService: AttributesResolver,
    private wikiCardPreviewService: WikiCardPreviewService,
    private collectionsService: CollectionsService,
    private blobPreviewService: BlobPreviewService,
    private resultsService: ResultsService,
    private logService: LogService,
    private previewService: PreviewService,
    private peopleService: PeopleService,
    private wikiItemSelectionPopupService: WikiItemSelectionPopupService,
    private wikiCardSaveFlowHandler: WikiCardSaveFlowService
  ) {
    this.logger = this.logService.scope('UrlPreviewService');
    this.routerService.queryParams$.subscribe(async (params) => {
      if (params.purl) {
        const purl = isString(params.purl) ? params.purl : params.purl[0];
        const parse = extractParamsFromUrl(this.PATH_PREVIEW_URL, purl);
        if (isEqual(parse, this.parsedValues)) {
          return;
        }
        if (this.parsedValues) {
          const previewType = getPreviewTypeByPreviewKey(this.parsedValues['param'] as PreviewKey);
          this.onClearUrl(previewType);
        }
        this.parsedValues = parse;
        await this.loadPreview(this.parsedValues);
      } else if (this.parsedValues) {
        const previewType = getPreviewTypeByPreviewKey(this.parsedValues['param'] as PreviewKey);
        this.onClearUrl(previewType);
        this.parsedValues = null;
      }
    });
    this.collectionsService.all$.subscribe((collections) => {
      this.collections = collections;
    });
  }

  async loadPreview(params: Params, windowMode?: boolean): Promise<{ dynamicItem: DynamicComponentItem<any>; url?: string }> {
    if (!params) {
      return { dynamicItem: new DynamicComponentItem(HubComponent, null), url: '/' };
    }
    const op = params['param'];
    const id = params['id'];
    const linkId = params['link'];
    const resourceId = params['resource'];
    const query = this.routerService.queryParams?.q;
    const queryParams = this.routerService.queryParams;

    if (!id || !op || (!['a', 'b'].includes(op) && (!linkId || !resourceId))) {
      return { dynamicItem: new DynamicComponentItem(HubComponent, null), url: '/' };
    }

    if (windowMode) {
      this.documentService.addAttribute('windowMode', 'true');
    }

    switch (op) {
      case 'a':
        return this.handleCard(id, queryParams, windowMode);
      case 'b':
        return this.handleBlobFile(id, windowMode);
      case 'f':
      case 'p':
      case 'm':
        return this.handleResource(op, linkId, resourceId, windowMode);
      case 'rp':
        return this.handleRelevantPeople(op, linkId, resourceId, query, windowMode);
      default:
        return { dynamicItem: new DynamicComponentItem(HubComponent, null), url: '/' };
    }
  }

  async handleCard(id: string, queryParams?: Params, windowMode?: boolean) {
    let cardId = id.split('-').slice(-1)[0];
    if (windowMode || !queryParams?.purl) {
      return { dynamicItem: new DynamicComponentItem(WikiCardPopupComponent, { cardId }) };
    } else if (queryParams?.purl) {
      const collectionId = this.collectionsService.currentCollection?.id;
      let parentId: string = collectionId;
      if (id === 'new') {
        //from folder kebab
        if (queryParams?.parentId) {
          parentId = queryParams?.parentId;
          this.routerService.removeQueryParam('parentId');
        }
        //from wikis/cards view
        else if (!collectionId) {
          this.wikiItemSelectionPopupService.openWikiItemSelectionPopup('create-card');
          return;
        }
        //from specific wiki view
        const res = await this.wikiCardSaveFlowHandler.saveCardNewMode({ collectionId }, parentId);
        cardId = res.id;
        this.previewService.setPreviewState(
          'popup',
          0,
          {
            type: 'result',
            filterType: 'wiki-local',
            id: cardId,
            view: { title: { text: res.title }, icon: null },
            action: { type: 'wiki card' },
            source: 'wiki-drafts',
          },
          { [NEW_CARD_URL_PARAM]: true }
        );
        return;
      }
      const popupModel: WikiCardPopupModel = { cardId };
      this.wikiCardPreviewService.openWikiCardPopup(popupModel);
      return { dynamicItem: new DynamicComponentItem(CollectionViewComponent, { cardId }) };
    }
  }

  async handleBlobFile(id: string, windowMode?: boolean) {
    const collection = this.collections.find((c) => isStaticCollection(c) && c.items?.find((item) => item.id === id));
    if (!collection) {
      this.handlePreviewError('b', id);
      return;
    }
    const item = await this.blobPreviewService.convertToResultItem(collection as Collections.StaticCollection, id);
    if (!windowMode) {
      this.blobPreviewService.openFilePopUp(item);
    }
    return {
      dynamicItem: new DynamicComponentItem(FilePreviewPopupComponent, { item: this.blobPreviewService.getBlobPreviewItem(item) }),
    };
  }
  private handlePreviewError(previewType: string, id: string) {
    this.routerService.removeQueryParam('purl', true);
    this.logger.error(`Error in load preview. preview type: ${previewType}, id: ${id}`);
  }

  async handleResource(op: PreviewKey, linkId: string, resourceId: string, windowMode?: boolean) {
    const previewData = this.getResourcePreviewDynamicData(op);
    try {
      let item: SearchResults;

      const items = await firstValueFrom(this.resultsService.getItems$({ resources: [{ linkId, resourceId }], cache: 'first' }));
      item = items?.length ? (items[0] as SearchResults) : null;

      if (!item) {
        for (const collection of this.collections) {
          if (!isStaticCollection(collection)) {
            continue;
          }
          const found = collection.items?.find((item) => item.id === resourceId);
          if (found) {
            item = await this.collectionsService.buildResourceStaticItem(found as Collections.LinkResourceItem, collection.id);
            break;
          }
        }
      }

      if (!item) {
        this.handlePreviewError(op, resourceId);
        throw 'No items';
      }

      item.action = await this.resultsService.getResultAction(item);

      //In the case of items in static collection (ex: people)
      if (!item.action) {
        item.action = { type: getPreviewTypeByPreviewKey(op), click: { primary: { type: 'preview' } } };
      }

      if (windowMode) {
        return {
          dynamicItem: new DynamicComponentItem(
            previewData.component,
            ['p', 'm'].includes(op) ? this.previewService.getPopupData('refresh', item, 0, 'refresh') : { item }
          ),
        };
      } else {
        this.previewService.openPreviewPopup(item, false, 'refresh', 0, 'refresh');
        return { dynamicItem: new DynamicComponentItem(HubComponent, null), url: previewData.url };
      }
    } catch (e) {
      return { dynamicItem: new DynamicComponentItem(HubComponent, null), url: previewData.url };
    }
  }

  async handleRelevantPeople(op: PreviewKey, link: string, resource: string, query: string, windowMode?: boolean) {
    const previewData = this.getResourcePreviewDynamicData(op);
    try {
      const items = await firstValueFrom(
        this.resultsService.getItems$({ resources: [{ linkId: link, resourceId: resource }], cache: 'first' })
      );
      if (items.length !== 1) {
        this.handlePreviewError(op, resource);
        throw 'No items';
      }
      const expertObserver = await this.peopleService.getExperts({ query, timestamp: Date.now() });
      const res = await firstValueFrom(expertObserver.pipe(filter((x) => !!x)));
      const experts = res.items;
      let currentExpert;
      for (const ex of experts) {
        if (ex.id === items[0].id) {
          currentExpert = ex;
          break;
        }
      }

      const previewType = getPreviewTypeByPreviewKey(op);
      const item: any = { ...currentExpert, action: { type: previewType }, query };
      if (windowMode) {
        return {
          dynamicItem: new DynamicComponentItem(previewData.component, this.previewService.getPopupData('refresh', item, 0, 'refresh')),
        };
      } else {
        this.previewService.openPreviewPopup(item, false, 'refresh', 0, 'refresh');
        return { dynamicItem: new DynamicComponentItem(HubComponent, null), url: previewData.url };
      }
    } catch (e) {
      return { dynamicItem: new DynamicComponentItem(HubComponent, null), url: previewData.url };
    }
  }

  getResourcePreviewDynamicData(op: PreviewKey): ResourcePreviewData {
    switch (op) {
      case 'f':
        return { component: FilePreviewPopupComponent, url: '/search?if-t-type=File' };
      case 'p':
      case 'rp':
        return { component: PeoplePreviewPopupComponent, url: '/people' };
      case 'm':
        return { component: MailPreviewPopupComponent, url: '/search?ap=mail' };
      default:
        return null;
    }
  }

  onClearUrl(previewType: PreviewType) {
    this.previewService.closePreviewPopup(previewType);
  }
}
