import { Accounts, Commands, GoLinks, Results } from '@local/client-contracts';
import { DateFormat } from '@shared/consts';
import { SessionService } from '@shared/services/session.service';
import { getProfileIcon } from '@shared/utils/set-icon.util';
import moment from 'moment';
import { GoLinksService, hasVariable } from '../../../go-links.service';

export type BulletType = 'Created' | 'Managed' | 'Last updated';

export type GoLinksView = Results.View & { badges?: Results.Badge[] };

export class GoLinksBuildResultView {
  private user;

  constructor(
    private goLinkService: GoLinksService,
    private session: SessionService
  ) {
    this.session.user$.subscribe((user) => {
      this.user = user;
    });
  }

  buildResultView(item: GoLinks.SearchItem): GoLinksView {
    const openUrlCommand: Commands.OpenUrl = {
      type: 'open-url',
      url: item.url,
    };
    const resultView: GoLinksView = {
      title: { text: `go/${item.name}`, onClick: openUrlCommand },
      subtitle: {
        text: item.description,
      },
      icon: { lightUrl: item.iconLink },
      bullets: this.buildResultBullet(item),
      tagGroups: [{ tags: this.buildResultTags(item) }],
    };
    resultView.badges = [];
    if (!item.listed) {
      resultView.badges.push({
        text: 'unlisted',
        tooltip: 'Unlisted Go Links are hidden from users without Edit permissions but remain accessible to anyone who knows the link.',
        textColor: { lightTheme: 'var(--light-blue-500)' },
        border: { lightTheme: 'var(--light-blue-500)' },
      });
    }
    if (hasVariable(item.url)) {
      resultView.badges.push({
        text: 'variable',
        tooltip: 'Variable allow you to customize destination URL dynamically',
        border: { lightTheme: 'var(--color-success-light)' },
        textColor: { lightTheme: 'var(--color-success-light)' },
      });
    }
    return resultView;
  }

  private buildResultTags(item: GoLinks.SearchItem): Results.Tag[] {
    return item.tags?.map((tag) => {
      return { text: tag };
    });
  }

  private buildResultBullet(item: GoLinks.SearchItem): Results.Bullet[] {
    const bullets: Results.Bullet[] = [];
    if (item.url) {
      bullets.push({
        parts: [
          {
            text: item.url,
            icon: { name: 'icon-go-links' },
            tooltip: item.url,
          },
        ],
      });
    }

    const account = this.getAccount(item);
    const detailsAccount = this.getDetailsAccount(item, account);
    const time: number = item.createTime !== item.modifiedTime ? item.modifiedTime : item.createTime;
    if (detailsAccount) {
      bullets.push({
        parts: [
          {
            text: this.getDetailsAccount(item, account),
            icon: getProfileIcon(account?.picture),
            tooltip: moment(time).format(DateFormat.FULL_DATE),
          },
        ],
      });
    }
    return bullets;
  }

  private getAccount(item: GoLinks.SearchItem): Accounts.WorkspaceAccount {
    const accountId: string = item.createdBy ? (item.modifiedBy ? item.modifiedBy : item.createdBy) : item.managedBy;
    return this.goLinkService.workspaceAccounts?.find((account) => account.id === accountId);
  }

  private getDetailsAccount(item: GoLinks.SearchItem, account: Accounts.WorkspaceAccount): string {
    if (!item.createdBy) {
      return this.buildDetailsAccount('Managed', account);
    }

    if (item.createTime !== item.modifiedTime) {
      return this.buildDetailsAccount('Last updated', account, item.modifiedTime);
    }

    return this.buildDetailsAccount('Created', account, item.createTime);
  }

  private buildDetailsAccount(type: BulletType, account: Accounts.WorkspaceAccount, time?: number): string {
    const isMe: boolean = account?.email === this.user?.email;
    const userName: string = isMe
      ? 'you'
      : `${account?.firstName ? account?.firstName : ''} ${account?.lastName ? account?.lastName : ''} `;
    return `${type} by ${userName} ${time ? moment(time).fromNow() : ''}`;
  }
}
