import { ConnectedPosition } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Applications, Links, Preferences, User } from '@local/client-contracts';
import { Values } from '@local/client-contracts/src/filters';
import { PopupRef, PopupService, STYLE_SERVICE, INIT_POSITION_POPUP } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LinkSettingsFrameComponent } from '@shared/components/link-settings/link-settings-frame.component';
import { DuplicateLinkError, ExistingLinkInfo, LinkResult, WrongAccountLinkError } from '@shared/components/link-settings/models';
import { ApplicationsService } from '@shared/services/applications.service';
import { OAuthWindow } from '@shared/services/oauth-windows/oauth-window';
import { OAuthWindowCollection } from '@shared/services/oauth-windows/oauth-windows-collection';
import { RouterService } from '@shared/services/router.service';
import { componentServicesRpcProvider } from '@shared/services/rpc.service';
import { SessionService } from '@shared/services/session.service';
import { StyleService } from '@shared/services/style.service';
import { EventsService } from '@shared/services/telemetry';
import { currentTheme, getTheme } from '@shared/utils/theme.util';
import { BehaviorSubject, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppPopupComponent, AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';
import { ResourceAccessType } from 'src/app/bar/components/rlp/choose-resource-access-type/choose-resource-access-type.component';
import { WorkspacesService } from 'src/app/bar/services';
import { FyiService } from 'src/app/bar/services/fyi.service';
import { HubService } from 'src/app/bar/services/hub.service';

export type LinkingStage =
  | 'LinkType'
  | 'ResourceAccessType'
  | 'SharingOptions'
  | 'AuthFrame'
  | 'WaitingForAuth'
  | 'ShowFrame'
  | 'PickRelativeTime'
  | 'SaveLink'
  | 'Done';
@UntilDestroy()
@Component({
  templateUrl: './new-link.component.html',
  providers: [componentServicesRpcProvider],
  styleUrls: ['./new-link.component.scss'],
})
export class NewLinkComponent implements OnInit, OnDestroy, AfterViewInit {
  resourceAccessType: ResourceAccessType;
  app: Applications.DisplayItem;
  showTitleBar$ = new BehaviorSubject<boolean>(true);
  suppressSyncMessage: boolean;
  linkResult$ = new BehaviorSubject<LinkResult>(undefined);
  theme: Preferences.Theme;
  focusedButton: number;
  user: User.Info;
  tempSharedLinkOption: Links.ShareOptions;

  @ViewChild('frame', { static: false, read: ViewContainerRef }) frameContainer!: ViewContainerRef;

  private appId: string;
  private source: string;
  private linkInfo?: ExistingLinkInfo;
  private oauthSessionId: string;
  private oauthWindow: OAuthWindow;
  private frame: LinkSettingsFrameComponent;
  private destroy$ = new Subject();
  private sharedLinkOption: Links.ShareOptions;
  private isCreating$ = new BehaviorSubject<boolean>(false);
  private isPreCreating$ = new BehaviorSubject<boolean>(false);
  private isLoading$ = new BehaviorSubject<boolean>(false);
  private reload$ = new BehaviorSubject<boolean>(false);
  private sessionCompleted$ = new BehaviorSubject<boolean>(false);
  private showOverlay$ = new BehaviorSubject<boolean>(false);
  private showFrame$ = new BehaviorSubject<boolean>(false);
  private predicateId: string;
  private compPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  private stage$ = new BehaviorSubject<LinkingStage>(null);
  private readonly suppressSyncMessageKey = 'showSyncMessage';

  get stage(): LinkingStage {
    return this.stage$.value;
  }

  private set stage(s: LinkingStage) {
    if (this.stage === s) {
      return;
    }
    this.stage$.next(s);
  }

  constructor(
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private applications: ApplicationsService,
    private fyiService: FyiService,
    private routerService: RouterService,
    private oauthWindows: OAuthWindowCollection,
    private hubService: HubService,
    protected workspacesService: WorkspacesService,
    private popupService: PopupService,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    @Inject(STYLE_SERVICE) public styleService: StyleService
  ) {
    this.sessionService.user$.pipe(untilDestroyed(this)).subscribe((user) => (this.user = user));
  }

  async ngAfterViewInit(): Promise<void> {
    const apps = await this.applications.all();
    this.app = apps.find((elm) => elm.id === this.appId);
    const isOwnerOrAdmin = await firstValueFrom(this.workspacesService.ownerOrAdmin$);
    if (!isOwnerOrAdmin || !this.app.shareable || this.linkInfo) {
      this.initDefaultShareOptions();
    } else {
      this.stage = 'LinkType';
    }
    this.stage$.pipe(untilDestroyed(this)).subscribe((stage) => {
      if (stage === 'AuthFrame') {
        if (this.app.authenticationType != 'mixed') {
          if (!this.oauthSessionId) {
            const { id, window } = this.oauthWindows.create(this.app.id, this.resourceAccessType);
            this.oauthSessionId = id;
            this.oauthWindow = window;
          }
          this.showOverlay$.next(true);
          this.oauthWindow.open(true);
          this.oauthWindow.userClosed$.pipe(takeUntil(this.destroy$)).subscribe((r) => this.frameResult(r));
        }
        this.initFrame();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.hubService.readOnly = true;

    this.resolveQueryParams();
    this.suppressSyncMessage = !!localStorage.getItem(this.suppressSyncMessageKey);
    this.subscribeToLinkResult();
    this.theme = getTheme(currentTheme());
    this.reload$.pipe(untilDestroyed(this)).subscribe((reload) => {
      if (reload) {
        this.stage = 'AuthFrame';
      }
    });
    combineLatest([this.showFrame$, this.showOverlay$, this.isCreating$, this.linkResult$, this.isPreCreating$])
      .pipe(untilDestroyed(this))
      .subscribe(([frame, overlay, creating, linkResult, preCreating]) => {
        if (frame) {
          this.stage = 'ShowFrame';
        } else if (overlay) {
          if (!creating) {
            this.stage = 'WaitingForAuth';
          } else if (this.sharedLinkOption) {
            this.stage = 'SaveLink';
          }
        }
        if (preCreating) {
          this.stage = 'PickRelativeTime';
          this.cdr.markForCheck();
        } else if (linkResult) {
          this.stage = 'Done';
        }
      });
  }

  isDuplicateError(result: LinkResult) {
    return result.error && result.error instanceof DuplicateLinkError;
  }

  isWrongAccountError(result: LinkResult) {
    return result.error && result.error instanceof WrongAccountLinkError;
  }

  onLinkLevelChosen(linkLevel: Links.LinkLevel) {
    if (linkLevel === 'Private') {
      this.initDefaultShareOptions();
      return;
    }
    this.stage = this.app.resourcePermissions ? 'ResourceAccessType' : 'SharingOptions';
  }

  onResourceAccessTypeChosen(type: ResourceAccessType) {
    this.resourceAccessType = type;
    this.stage = 'SharingOptions';
  }

  private subscribeToLinkResult() {
    this.linkResult$.pipe(takeUntil(this.destroy$)).subscribe(async (result: LinkResult) => {
      this.showOverlay$.next(false);

      if (!result || result.status !== 'success') {
        if (result === null) {
          this.routerService.back();
        }
        return;
      }

      if (this.linkInfo) {
        //success stale
        this.fyiService.dismissLinkStaled(this.linkInfo.linkId);
        return;
      }

      if (this.source === 'calendar') {
        this.routerService.navigate(['calendar']);
        return;
      }
      this.suppressSyncMessage && this.routerService.back();
    });
  }

  private initDefaultShareOptions() {
    this.sharedLinkOption = { level: 'Private' };
    this.stage = 'AuthFrame';
  }

  ngOnDestroy(): void {
    if (this.compPopupRef) {
      this.compPopupRef.destroy();
    }
    if (this.oauthWindow) {
      this.oauthWindows.remove(this.oauthSessionId);
    }
    if (this.frameContainer) this.frameContainer.clear();
    this.routerService.removePredicateNavigation(this.predicateId);

    this.destroy$.next(null);
  }

  private resolveQueryParams() {
    const { queryParamMap, paramMap } = this.route.snapshot;
    const appId = paramMap.get('appId');
    const source = queryParamMap.get('source');
    const oauthSessionId = queryParamMap.get('oauthSessionId');
    const rawSharedLinkOption = queryParamMap.get('sharedLinkOption');
    const stage = queryParamMap.get('stage') as LinkingStage;

    let linkInfo: ExistingLinkInfo;

    if (queryParamMap.has('linkId') && queryParamMap.has('key')) {
      const isRlp = queryParamMap.get('isRlp')?.toLowerCase() === 'true';
      if (isRlp) {
        this.resourceAccessType = isRlp ? 'Permissions' : 'Discovery';
      }
      linkInfo = {
        linkId: queryParamMap.get('linkId'),
        key: queryParamMap.get('key'),
        isRlp,
      };
    }

    this.appId = appId;
    this.source = source;
    this.linkInfo = linkInfo;
    this.oauthSessionId = oauthSessionId;
    if (stage) {
      this.stage = stage;
    }
    if (rawSharedLinkOption) {
      this.sharedLinkOption = JSON.parse(atob(rawSharedLinkOption)) as Links.ShareOptions;
    }
  }

  connectAnother(): void {
    const appId = this.appId;
    this.eventsService.event(`links.connect_start`, { location: { title: `${appId}/links` }, label: appId });

    this.routerService.navigateByUrl('/connect', { skipLocationChange: true }).then(() =>
      this.routerService.navigate(['connect', this.app.id, 'new'], {
        queryParams: {
          source: this.source,
          linkId: this.linkInfo?.linkId,
          key: this.linkInfo?.key,
          stage: 'AuthFrame',
          sharedLinkOption: btoa(JSON.stringify(this.sharedLinkOption)),
          isRlp: this.linkInfo?.isRlp,
        },
      })
    );
  }

  closeSyncMessage(): void {
    if (this.suppressSyncMessage) {
      localStorage.setItem(this.suppressSyncMessageKey, '1');
    }
    this.linkResult$.next(null);
    this.close();
  }

  toggleSuppressSyncMessage(): void {
    this.suppressSyncMessage = !this.suppressSyncMessage;
  }

  sentActionEvent(target: string) {
    if (this.sharedLinkOption === undefined) return;
    this.eventsService.event('links.link_access', {
      location: { title: `/connect_apps/${this.appId}/link_access` },
      label: this.sharedLinkOption.level === 'Protected' ? 'members' : this.sharedLinkOption.level === 'Public' ? 'worksapce' : 'private',
      target,
    });
  }

  close() {
    this.routerService.back();
    setTimeout(() => {
      this.cdr.markForCheck();
    }, 0);
  }

  abort() {
    this.sendCancelEvent();
    this.routerService.back();
  }

  onShareDoneClick(sharedLinkOption: Links.ShareOptions) {
    this.sharedLinkOption = sharedLinkOption;
    if (!this.sharedLinkOption) return;
    if (
      this.sharedLinkOption?.level === 'Protected' &&
      !this.sharedLinkOption?.accountIds?.length &&
      !this.sharedLinkOption?.groupIds?.length
    ) {
      this.openAnyMembersPopup();
      this.sentActionEvent('done');
    }
    this.stage = 'AuthFrame';
  }

  optionChecked(event: Links.ShareOptions) {
    if (!event) {
      this.tempSharedLinkOption = undefined;
      return;
    }
    this.tempSharedLinkOption = event;
  }

  private async initFrame() {
    if (this.frame) {
      return;
    }
    const compRef = this.frameContainer.createComponent(LinkSettingsFrameComponent);
    this.frame = compRef.instance;
    this.frame.appId = this.app.id;
    this.frame.oauthSessionId = this.oauthSessionId;
    this.frame.source = this.source;
    this.frame.linkInfo = this.linkInfo;
    this.frame.resourceAccessType = this.resourceAccessType;
    this.frame.linkCreationOptions = this.sharedLinkOption;
    const subs = [
      this.frame.isLoading.subscribe((b) => this.frameLoading(b)),
      this.frame.linkResult.subscribe((r) => this.frameResult(r)),
      this.frame.isCreating.subscribe((r) => this.frameCreating(r)),
      this.frame.isPreCreating.subscribe((r) => this.framePreCreating(r)),
      this.frame.isReload.subscribe((r) => this.reload$.next(r)),
      this.frame.isShow.subscribe((s) => this.frameShow(s)),
      this.frame.sessionCompleted$.pipe(untilDestroyed(this)).subscribe((s) => {
        this.sessionCompleted$.next(s);
        if (this.showFrame$.getValue()) {
          this.showFrame$.next(false);
          this.showOverlay$.next(true);
        }

        this.cdr.markForCheck();
      }),
    ];
    this.predicateId = this.routerService.addPredicateNavigation(async (url) => await this.shouldNavigate(url));

    compRef.onDestroy(() => {
      subs.forEach((s) => {
        s.unsubscribe();
      });
      if (!this.frame.sessionCompleted$.getValue()) {
        this.sendCancelEvent();
      }
    });
    compRef.changeDetectorRef.markForCheck();
  }

  private sendCancelEvent() {
    this.eventsService.event('links.cancel', {
      target: this.appId,
      location: { title: this.source },
    });
    this.eventsService.event('auth_note.cancel');
  }

  private frameCreating(creating: boolean) {
    if (creating || this.app.authenticationType != 'mixed') {
      this.showOverlay$.next(true);
      this.showFrame$.next(false);
      this.isCreating$.next(creating);
    }
  }

  private framePreCreating(creating: boolean) {
    this.isPreCreating$.next(creating);
  }

  private frameShow(show: boolean) {
    this.showFrame$.next(show);
    this.showOverlay$.next(false);
    this.cdr.markForCheck();
  }

  private frameLoading(loading: boolean) {
    this.isLoading$.next(loading);
  }

  private async frameResult(result: LinkResult) {
    this.linkResult$.next(result);
    this.showFrame$.next(false);
    this.cdr.markForCheck();
  }

  private async shouldNavigate(url: string): Promise<boolean> {
    if (url?.includes(`/connect/${this.appId}`)) {
      return true;
    } else {
      const isCreating: boolean = await firstValueFrom(this.isCreating$);
      const linkResult = await firstValueFrom(this.linkResult$);
      const showExitPopup: boolean = !this.sharedLinkOption && isCreating && linkResult === undefined;
      if (showExitPopup) {
        return new Promise<boolean>((res, rej) => {
          this.openPopup(res);
        });
      } else {
        return true;
      }
    }
  }

  private async openPopup(res: (value: boolean | PromiseLike<boolean>) => void) {
    const position: ConnectedPosition[] = [{ ...INIT_POSITION_POPUP[0], offsetY: -40 }];
    this.compPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: 'This link has not been connected. <br> Are you sure you want to leave this page?',
        showButtons: true,
        content: {
          secondaryButton: 'Cancel',
          primaryButton: 'Yes',
        },
      },
      { position }
    );
    this.compPopupRef.compInstance.primaryButton.pipe(takeUntil(this.compPopupRef.destroy$)).subscribe(() => {
      res(true);
      this.sentPopupEvent('cancel_link_prompt', 'yes');
    });
    this.compPopupRef.compInstance.secondaryButton.pipe(takeUntil(this.compPopupRef.destroy$)).subscribe(() => {
      res(false);
      this.sentPopupEvent('cancel_link_prompt', 'no');
    });
  }

  private openAnyMembersPopup() {
    this.compPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message:
          'You have not selected any group / member to share this link with. Once created, you can choose to share this link with others.',
        showButtons: true,
        rightButtonStyle: { type: 'primary', size: 124 },
        leftButtonStyle: { size: 124 },
        content: {
          secondaryButton: 'Edit link',
          primaryButton: 'Continue',
          title: 'This link will be shared with you only',
        },
        messageStyle: { fontSize: '14px' },
      },
      { position: INIT_POSITION_POPUP }
    );
    this.compPopupRef.compInstance.primaryButton.pipe(takeUntil(this.compPopupRef.destroy$)).subscribe(() => {
      this.sentPopupEvent('no_member_selected', 'continue');
    });
    this.compPopupRef.compInstance.secondaryButton.pipe(takeUntil(this.compPopupRef.destroy$)).subscribe(() => {
      this.compPopupRef.close();
      this.sentPopupEvent('no_member_selected', 'back');
    });
  }

  private sentPopupEvent(keyPart: string, target: string) {
    this.eventsService.event(`link_action.${keyPart}`, { target, location: { title: `/connect_apps/${this.appId}/link_access` } });
  }

  onPickRelativeTimeAction($event: Values) {
    this.frame.complete($event);
  }
}
