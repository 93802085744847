import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  model,
  NgZone,
  output,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Listbox, ListboxChangeEvent } from 'primeng/listbox';

@Component({
  selector: 'u-list-box',
  templateUrl: './u-list-box.component.html',
  styleUrl: './u-list-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UListBoxComponent<T> {
  private currentIndex = signal(-1);

  items = input<T[]>([]);
  labelField = input('name');
  filter = input(true);
  itemTemplate = input<TemplateRef<any> | undefined>(undefined);
  listStyle = input<Record<string, string>>({ 'max-height': '100%' });
  selectedItem = model<T | null>(null);

  selectedChange = output<{ item: T | null; index: number }>();
  @ViewChild('listbox', { static: false }) listbox!: Listbox;

  constructor(private ngZone: NgZone) {
    effect(
      () => {
        const selected = this.selectedItem();
        const items = this.items();

        if (selected && items.length) {
          const index = items.findIndex((item) => item === selected);
          if (index !== -1) {
            this.currentIndex.set(index);
          }
        }
      },
      { allowSignalWrites: true }
    );

    afterNextRender(() => {
      this.focusListbox();
    });
  }

  private focusListbox() {
    if (this.listbox) {
      this.ngZone.runOutsideAngular(() => {
        this.listbox.el.nativeElement.focus();
      });
    }
  }

  onChangeEvent(event: ListboxChangeEvent): void {
    const selectedIndex = this.items().findIndex((item: T) => item === event.value);
    if (selectedIndex !== -1) {
      this.updateSelectedItem(selectedIndex, event.value);
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    let newIndex = this.currentIndex();

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        newIndex = Math.min(this.currentIndex() + 1, this.items().length - 1);
        this.setSelection(newIndex);
        break;

      case 'ArrowUp':
        event.preventDefault();
        newIndex = Math.max(this.currentIndex() - 1, 0);
        this.setSelection(newIndex);
        break;

      case 'Enter':
        event.preventDefault();
        if (this.currentIndex() >= 0) {
          this.setSelection(this.currentIndex());
        }
        break;

      case 'Home':
        event.preventDefault();
        this.setSelection(0);
        break;

      case 'End':
        event.preventDefault();
        this.setSelection(this.items().length - 1);
        break;
    }
  }

  private setSelection(index: number): void {
    if (index >= 0 && index < this.items().length) {
      this.updateSelectedItem(index, this.items()[index]);
    }
  }

  private updateSelectedItem(index: number, item: T | null): void {
    this.currentIndex.set(index);
    this.selectedItem.set(item);
    this.selectedChange.emit({ item, index });
    this.focusItem(index);
  }

  private focusItem(index: number): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.listbox) {
        const items = this.listbox.el.nativeElement.querySelectorAll('.p-listbox-item');
        if (items[index]) {
          requestAnimationFrame(() => {
            items[index].focus();
            items[index].scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          });
        }
      }
    });
  }
}
