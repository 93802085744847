import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UInputListComponent } from './u-input-list.component';
import { InputTextModule } from 'primeng/inputtext';
import { UIconModule } from '../u-icon/u-icon.module';
import { UInputModule } from '../u-input/u-input.module';
import { UButtonModule } from '../u-button/u-button.module';

@NgModule({
  declarations: [UInputListComponent],
  imports: [CommonModule, InputTextModule, UIconModule, UInputModule, UButtonModule],
  exports: [UInputListComponent],
})
export class UInputListModule {}
