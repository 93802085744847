<div class="share-options-popup-wrapper" (click)="onClickClosePopup($event, 'background')">
  <div class="share-options-popup-container" #shareOptionsPermissionsPopup (click)="$event.stopPropagation()">
    @if (model) {
      <div class="share-options-popup-header">
        <div class="share-options-popup-header-content">
          <div class="share-options-title">
            <span>{{ model.title }}</span>
            @if (model.subtitle) {
              <span class="share-options-subtitle">{{ model.subtitle }}</span>
            }
          </div>
          <u-icon
            class="close-icon"
            (click)="onClickClosePopup($event, 'close')"
            [model]="{ type: 'font', value: 'icon-Windows-close' }"
            [styles]="{ fontSize: '16px', color: 'var(--gray-250)' }"
          ></u-icon>
        </div>
        @if (model.title) {
          <div class="divider"></div>
        }
      </div>

      <members-selector
        [model]="model"
        [suggestions]="suggestions"
        [selectedSuggestions]="selectedSuggestions"
        [creator]="creator"
        [owOrAdmins]="owOrAdmins"
        [workspacePermission]="workspacePermission"
        [invitedSuggestions]="invitedSuggestions"
        [workspace]="workspace"
        [heightPopup]="heightPopup"
        (inviteChanged)="updateInvitedSuggestions($event)"
        (permissionChanged)="updateWorkspacePermission($event)"
        [heightScroll]="345"
        [viewType]="'share'"
      ></members-selector>
    } @else {
      <loader-overlay class="popup-loading"> </loader-overlay>
    }
  </div>
</div>
