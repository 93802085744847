interface ResourceLookupResource {
  iconUrl?: string;
  subtitle?: string;
  link: string;
  title: string;
}

interface ResourceLookupAnswer {
  answer: string;
  resources: ResourceLookupResource[];
}

const parseResourceLookup = (jsonString: string): ResourceLookupAnswer => {
  let obj: ResourceLookupAnswer;
  try {
    obj = JSON.parse(jsonString);
  } catch {}

  return obj;
};

export const lookUpQuesionTitle = 'We found the following resources matching your query -  ';

export const buildResourceLookupFromJson = (resources, jsonString?: string): string => {
  if (resources?.length) {
    const mainHtml = `<p class="resource-lookup">${lookUpQuesionTitle}</p>`;

    const resourceItemsHtml = resources.map((r, index) => {
      return `<p data-resource="${index}" class="resource-item"></p>`;
    });

    return `${mainHtml}
    ${resourceItemsHtml.join('')}`;
  } else {
    // Note: This code is necessary because loading a chat from old history does not save the resources.
    const answer = parseResourceLookup(jsonString);
    if (!answer) {
      return '';
    }
    const mainHtml = `<p class="resource-lookup">${answer.answer.replace('**"', '<strong>"').replace('"**', '"</strong>')}</p> `;

    const resourceItemsHtml = answer.resources.map((r: { iconUrl: string; subtitle: string; link: string; title: string }) => {
      const iconHtml = r.iconUrl ? `<img style="width:24px;height:24px!important" src="${r.iconUrl}" alt="resource icon"/>` : '';
      const linkHtml = `<a ${r.subtitle ? 'class="with-sub-title"' : ''} target="_blank" href="${r.link}">${r.title}</a>`;
      const subtitleHtml = r.subtitle ? `<span>${r.subtitle}</span>` : '';
      return `<p class="resource-item">
        ${iconHtml}
        ${linkHtml}
        ${subtitleHtml}</p> `;
    });

    return `${mainHtml}
    ${resourceItemsHtml.join('')}`;
  }
};
