import { Injectable } from '@angular/core';
import { PopupRef, PopupService, INIT_POSITION_POPUP } from '@local/ui-infra';
import { take, takeUntil } from 'rxjs';
import { AppPopupComponent, AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';
import {
  SpecificResourcePopupComponent,
  SpecificResourcePopupModel,
} from '../components/specific-resource-popup/specific-resource-popup.component';

@Injectable()
export class AssistantPopupService {
  specificResourcePopupPopupRef: PopupRef<SpecificResourcePopupComponent, SpecificResourcePopupModel> = null;

  constructor(public popupService: PopupService) {}

  openAreYouSureLeavePage(onPrimary: Function, onSecondary: Function) {
    const areYouSure = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: 'Do you want to save your changes <br> before you go?',
        showButtons: true,
        rightButtonStyle: { size: 80, type: 'primary' },
        leftButtonStyle: { size: 80 },
        content: {
          secondaryButton: 'No',
          primaryButton: 'Yes',
        },
        messageStyle: { fontSize: '14px' },
        popupStyle: { paddingTop: '32px' },
      },
      { position: INIT_POSITION_POPUP }
    );
    areYouSure.compInstance.primaryButton.subscribe(() => {
      onPrimary();
    });
    areYouSure.compInstance.secondaryButton.subscribe(() => {
      onSecondary();
    });
  }

  openSpecificResourcePopup(model: SpecificResourcePopupModel, savePopup: Function) {
    if (this.specificResourcePopupPopupRef) {
      this.specificResourcePopupPopupRef.destroy();
    }
    this.specificResourcePopupPopupRef = this.popupService.open<SpecificResourcePopupComponent, SpecificResourcePopupModel>(
      'center',
      SpecificResourcePopupComponent,
      model,
      { closeOnClickOut: true, backdropStyle: 'blur-2', panelClass: 'specific-resource-popup' }
    );

    this.specificResourcePopupPopupRef.compInstance.savePopup
      .pipe(takeUntil(this.specificResourcePopupPopupRef.destroy$))
      .subscribe((value) => {
        savePopup(value);
      });
    this.specificResourcePopupPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.specificResourcePopupPopupRef = null;
    });
    return this.specificResourcePopupPopupRef;
  }
}
