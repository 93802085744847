<div *ngIf="showArrows" class="navigator" data-cy="breadcrumbs-navigator">
  <button
    (click)="onNavClick('back')"
    [escape]="false"
    [pTooltip]="(canGoBack$ | async) && (back$ | async) ? (back$ | async) : null"
    tooltipPosition="bottom"
    tooltipStyleClass="u-tooltip"
    [disabled]="!(canGoBack$ | async)"
    class="button"
    data-cy="breadcrumbs-back"
  >
    <u-icon [model]="{ type: 'font', value: 'icon-chevron-left' }"></u-icon>
  </button>
  <button
    (click)="onNavClick('forward')"
    [escape]="false"
    [pTooltip]="(canGoForward$ | async) && (forward$ | async) ? (forward$ | async) : null"
    tooltipPosition="bottom"
    tooltipStyleClass="u-tooltip"
    [disabled]="!(canGoForward$ | async)"
    class="button"
    data-cy="breadcrumbs-forward"
  >
    <u-icon [model]="{ type: 'font', value: 'icon-chevron-right' }"></u-icon>
  </button>
</div>
<ng-container *ngIf="showBreadcrumbsItems">
  <div *ngIf="showGhost(); else breadcrumbsItems" [class.breadcrumb-ghost]="showGhost()"></div>
</ng-container>

<ng-template #breadcrumbsItems>
  <ng-container *ngFor="let item of items; let last = last; let first = first; let index = index">
    <li class="breadcrumb-item" [class.hidden]="!showPath() && !alwaysShowPath">
      <div class="icon" *ngIf="item.icon">
        <u-emoji *ngIf="!!item?.emoji" class="emoji" [spriteEmojiSize]="12" [unicode]="item?.emoji"> </u-emoji>
        <entity-icon *ngIf="!item?.emoji" [model]="item.icon" [size]="'14px'" [styles]="styles"></entity-icon>
      </div>
      <ng-container *ngIf="popupMode || !last; else lastItem">
        <div
          class="title"
          [class.no-click]="item.notClickable"
          [pTooltip]="item.tooltip"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
        >
          <a
            #breadcrumbElement
            data-cy="breadcrumbs-item"
            (click)="!item.notClickable ? navigate(item.path) : null"
            [ngClass]="{ clicked: !item.notClickable }"
            [pTooltip]="ellipsisStatus()[index] ? item.title : null"
            tooltipPosition="bottom"
            tooltipStyleClass="u-tooltip"
          >
            {{ item.title }}
          </a>
        </div>
        <span *ngIf="!last" class="seperator">/</span>
      </ng-container>
      <ng-template #lastItem>
        <div class="title">
          <a
            #breadcrumbElement
            data-cy="breadcrumbs-last-item"
            [pTooltip]="ellipsisStatus()[index] ? item.title : null"
            tooltipPosition="bottom"
            tooltipStyleClass="u-tooltip"
          >
            {{ item.title }}
          </a>
        </div>
      </ng-template>
    </li>
  </ng-container>
</ng-template>
